import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { DeleteModal } from "@/components/modal-delete"
import { menuBtnRight } from "@/components/reputation/styles"
import { FormModelData } from "@/hooks/campaign/form-model/useLogic"
import useFormater from "@/hooks/useFormater"
import { IconButton, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FormModel } from "../form-model"

interface PropsViewModel {
    item: FormModelData
    loading: boolean
    handleDelete: (id: string) => void
}

export const ViewModel = ({ item, loading, handleDelete }: PropsViewModel) => {
    const { t } = useTranslation()
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false)
    const [editedModel, setEditedModel] = useState<FormModelData | null>(null)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const openEditBtn = useMemo(() => Boolean(anchorEl), [anchorEl])
    const { parseMentionPreferLang } = useFormater()

    const handleClose = useCallback(() => {
        setAnchorEl(null)
        setEditedModel(null)
    }, [setAnchorEl])

    const toggleMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(!openEditBtn ? event.currentTarget : null)
        },
        [setAnchorEl, openEditBtn]
    )

    const handleModify = (value: boolean) => {
        if (!value) {
            handleClose()
        } else {
            setEditedModel(item)
        }
    }

    const handleModalDelete = (value: boolean) => {
        handleModify(false)
        setOpenModalDelete(value)
        if (!value) {
            handleClose()
        }
    }

    useEffect(() => {
        if (openModalDelete && !loading) {
            setOpenModalDelete(false)
        }
    }, [loading])

    return (
        <>
            {!editedModel && (
                <Paper>
                    <Stack>
                        <Stack flexDirection={"row"} justifyContent={"space-between"} className="w-100">
                            <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                                <Typography className="name" variant="h2" fontSize={"24px"} fontWeight="500">
                                    {item.title}
                                </Typography>
                            </Stack>
                            <Stack className="menu-right">
                                <CustomTooltip title={t("CAMPAIGN.MODEL.MODIFY_OR_DELETE")}>
                                    <IconButton onClick={toggleMenu}>
                                        <Icon name="dots-vertical" />
                                    </IconButton>
                                </CustomTooltip>
                                <Menu
                                    elevation={6}
                                    id={"basic-menu"}
                                    open={openEditBtn}
                                    onClose={handleClose}
                                    anchorEl={anchorEl}
                                    sx={menuBtnRight}
                                >
                                    <MenuItem onClick={() => handleModify(true)} className="clickable">
                                        <Icon name="edit" sx={{ color: "primary.main" }} />
                                        <Typography fontSize={"16px"} color={"primary.main"}>
                                            {t("REPUTATION.MODIFY")}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleModalDelete(true)} className="remove clickable">
                                        <Icon name="delete-1" />
                                        <Typography fontSize={"16px"} color={"error.main"}>
                                            {t("REPUTATION.REMOVE")}
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack>
                        <div
                            className="ellipse-three-line mention-blue"
                            dangerouslySetInnerHTML={{
                                __html: parseMentionPreferLang(
                                    item.content.replaceAll("</p><p><br></p><p>", " ").replaceAll("</p><p>", " ")
                                ),
                            }}
                        />
                    </Stack>
                    <DeleteModal
                        open={openModalDelete}
                        onClose={() => handleModalDelete(false)}
                        title={t("CAMPAIGN.MODEL.REMOVE_TITLE")}
                        description={t("CAMPAIGN.MODEL.ASK_TO_REMOVE")}
                        loading={loading}
                        onDelete={() => {
                            handleDelete(item.id)
                        }}
                    />
                </Paper>
            )}
            {editedModel && <FormModel onCancel={handleClose} defaultFormValue={editedModel} />}
        </>
    )
}
