import { Skeleton, ListItem } from "@mui/material"

export const SkeletonListingTopNumber = () => {
    return (
        <ListItem className="listing-item">
            <Skeleton variant="rectangular" width={63} height={63} sx={{ minWidth: "63px" }} />
            <Skeleton variant="rectangular" width={"50%"} height={24} />
        </ListItem>
    )
}
