import { OptionType } from "@/types/option"
import { useEffect, useState } from "react"
import { UseFormSetValue, UseFormTrigger, useWatch } from "react-hook-form"
import BaseAutocomplete from "../../base/autocomplete"

export interface CheckBoxAutocompletePropsType {
    options: OptionType[]
    setValue: UseFormSetValue<any>
    trigger: UseFormTrigger<any>
    name: string
    label: string
    control: any
    required?: boolean
    disabled?: boolean
    id?: string
    error?: boolean
}

const CheckBoxAutocomplete = ({
    id,
    options,
    required,
    setValue,
    trigger,
    name,
    label,
    control,
    disabled,
    error,
}: CheckBoxAutocompletePropsType) => {
    const [autocompleteValue, setAutocompleteValue] = useState([])
    const field: string[] = useWatch({ control, name })
    useEffect(() => {
        if (field) {
            setAutocompleteValue(options.filter((option) => field.indexOf(option.value) > -1))
        }
    }, [field, options])

    return (
        <BaseAutocomplete
            control={control}
            name={name}
            required={required}
            label={label}
            field={field}
            disabled={disabled}
            id={id}
            autocompleteValue={autocompleteValue}
            options={options}
            onChange={(_, value: any) => {
                const valueFromMap = value.map((el) => el.value)
                setValue(name, valueFromMap)
                trigger(name)
            }}
            type="checkbox"
            setValue={setValue}
            error={error}
        />
    )
}

export default CheckBoxAutocomplete
