import { UserApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { UserType } from "@/types/user"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const useLogic = () => {
    const { updateState: setStateUiPageSession, items, refreshItems } = UiPageSessionStore()
    const { notif } = useNotification()
    const [userToDelete, setUserToDelete] = useState<UserType>()
    const [loading, setLoading] = useState<boolean>(false)
    const { isConnected } = UserSessionStore()
    const [metadata, setMetadata] = useState({
        page_size: 10,
        page_number: 0,
        total_count: 0,
    })
    const { profile } = UserSessionStore()
    const { t } = useTranslation()

    const handleChangePage = useCallback((_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setMetadata((prev) => ({
            ...prev,
            page_number: newPage,
        }))
        setStateUiPageSession({ refreshItems: true })
    }, [])

    const handleCloseModal = () => setUserToDelete(undefined)
    const handleDeleteUser = async () => {
        if (isConnected) {
            setLoading(true)
            const user_session_uid = profile.uid
            const response = await UserApi.deleteUser(userToDelete, user_session_uid)
            if (response?.error) {
                setUserToDelete(undefined)
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else {
                const previousListLength = items?.length || 0
                const page_number = previousListLength === 1 ? metadata.page_number - 1 : metadata.page_number
                setMetadata((prev) => ({
                    ...prev,
                    page_number,
                }))
                setStateUiPageSession({ refreshItems: true })
                setUserToDelete(undefined)
                notif({ message: t("USERS.DELETE.SUCCESS"), type: "SUCCESS" })
            }
            setLoading(false)
        } else {
            setUserToDelete(undefined)
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
    }
    const refreshListUsers = useCallback(async () => {
        const response = await UserApi.fetchListUser({
            page_number: metadata.page_number,
            page_size: metadata.page_size,
            user_uid: profile.uid,
        })
        if (response?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else if (response?.metadata?.total_count) {
            setMetadata((prev) => ({
                ...prev,
                total_count: response.metadata.total_count,
                page_size: response.metadata.page_size,
            }))
            setStateUiPageSession({ items: [...response.data] })
        }
    }, [metadata, profile])

    const handleDelete = useCallback(
        (index: number) => {
            if (items[index]) {
                setUserToDelete(items[index])
            }
        },
        [items]
    )

    const handleEdit = useCallback(
        (index: number) => {
            if (items[index]) {
                setStateUiPageSession({
                    selectedItem: items[index],
                    showPage: "edit",
                })
            }
        },
        [items]
    )

    useEffect(() => {
        setStateUiPageSession({ refreshItems: true })
    }, [])

    useEffect(() => {
        if (profile.uid && refreshItems) {
            refreshListUsers()
            setStateUiPageSession({ refreshItems: false })
        }
    }, [refreshItems])

    useEffect(() => {
        return () => {
            setStateUiPageSession({
                refreshItems: false,
                items: [],
            })
        }
    }, [])

    return {
        handleChangePage,
        handleDelete,
        handleEdit,
        handleCloseModal,
        handleDeleteUser,
        t,
        setStateUiPageSession,
        items,
        metadata,
        userToDelete,
        loading,
    }
}

export default useLogic
