import Icon from "@/components/base/Icon"
import { PageProps } from "@/types/page"
import { Box, Button, Drawer, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import useStyles from "../styles"

const SuccessPage = ({ onClose, open }: PageProps) => {
    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <Drawer
            disableScrollLock
            hideBackdrop
            anchor={"right"}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            disableEscapeKeyDown
            PaperProps={{
                className: classes.containerSuccess,
            }}
            sx={{ zIndex: 1 }}
        >
            <Box className="children-wrapper" data-e2e="success-campaign">
                <Stack className={classes.stepBloc} gap={2}>
                    <Stack gap={2}>
                        <Stack textAlign={"center"} gap={1}>
                            <Icon name="success" sx={{ width: 240 }} />
                            <Typography variant="h5" sx={{ fontWeight: 500 }}>
                                {t("CAMPAIGN.SEND_SUCCESS")}
                            </Typography>
                        </Stack>
                        <Stack alignItems={"center"} gap={2}>
                            <Button sx={{ gap: 1, px: 2 }} className="primary" onClick={onClose}>
                                <Icon name="plus" />
                                {t("CAMPAIGN.SEND_NEW_REQUEST")}
                            </Button>
                            <Link to={"/reputation"}>
                                <Typography component={"span"} className="primary" sx={{ fontWeight: 500 }}>
                                    {t("CAMPAIGN.CHECK_REVIEWS")}
                                </Typography>
                            </Link>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </Drawer>
    )
}

export default SuccessPage
