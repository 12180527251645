import BaseInputPhone from "@/components/base/BaseInputPhone"
import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import RadioTabButton, { DataItem } from "@/components/base/radio-tab-buttons"
import BaseSelect from "@/components/base/select"
import UploadFile from "@/components/base/upload-file/UploadFile"
import ListTable from "@/components/list-table"
import SelectLang, { FlagOptionType } from "@/components/select/select-lang"
import useFirstStepLogic from "@/hooks/campaign/first-step/useLogic"
import usePhoneInputLogic from "@/hooks/phone-input/useLogic"
import useFormater from "@/hooks/useFormater"
import CampaignStore from "@/store/campaign"
import UiStore from "@/store/ui"
import { isValidEmail } from "@/utils"
import { Button, Paper, Stack, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import { CountryCode } from "libphonenumber-js"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import useStyles, { radioTabButtonSx } from "../styles"
import UploadMessage from "./upload-message/UploadMessage"

const FirstStepCampaign = ({
    control,
    setValue,
    formValue,
    errors,
    defaultFlag = "CA",
    defaultType = 0,
    openUpload,
    showUpload,
    updateFile,
    closeUpload,
    sendRequest,
}: {
    control: any
    setValue: (key: string, value: any) => void
    formValue: any
    errors: any
    defaultFlag: CountryCode
    defaultType: number
    openUpload: boolean
    showUpload: () => void
    updateFile: (newFile: File) => void
    closeUpload: () => void
    sendRequest: () => void
}) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const { pagination, update, contacts, creditFetched } = CampaignStore()
    const { isPhoneValid } = usePhoneInputLogic()
    const { internationalPhone } = useFormater()
    const { preferredLanguage } = UiStore()
    const langOfCountryName = useMemo(() => {
        return preferredLanguage?.toLowerCase() || "fr-ca"
    }, [preferredLanguage])

    const radioTabButtons = useMemo<DataItem[]>(() => {
        return [{ label: t("CAMPAIGN.SMS") }, { label: t("CAMPAIGN.EMAIL") }]
    }, [t])

    const {
        options,
        editItem,
        creditEmail,
        creditSms,
        invalidPhone,
        currentItem,
        setCurrentItem,
        setEditItem,
        handleChangePage,
        handleViewChange,
    } = useFirstStepLogic({
        setValue,
    })

    const typeLabel = useMemo(() => {
        return formValue?.type === "sms" ? t("CAMPAIGN.PHONE_NUMBER") : t("LABEL.EMAIL")
    }, [formValue])

    const generateIndex = useCallback(
        (index: number) => {
            return pagination.page_number > 0 ? pagination.page_size * pagination.page_number + index : index
        },
        [pagination]
    )

    const handleSaveItem = useCallback(
        (value: any, index: number) => {
            const items = contacts
            const itemIndx = generateIndex(index)
            value[typeLabel] =
                formValue?.type === "sms"
                    ? internationalPhone(value[t("CAMPAIGN.PHONE_NUMBER")], value.code || formValue?.country)
                    : value[t("LABEL.EMAIL")]
            delete value.code
            items[itemIndx] = value
            update({
                contacts: items,
                pagination: {
                    ...pagination,
                    rows: items.slice(
                        pagination.page_number * pagination.page_size,
                        pagination.page_number * pagination.page_size + pagination.page_size
                    ),
                },
            })
            setEditItem(null)
        },
        [contacts, typeLabel, formValue, pagination]
    )

    const handleEdit = useCallback(
        (index: number) => {
            const item = pagination.rows[index]
            setEditItem(item)
            setCurrentIndex(generateIndex(index))
        },
        [contacts, pagination]
    )

    const handleDelete = useCallback(
        (index: number) => {
            const items = contacts
            const itemIndx = generateIndex(index)
            items.splice(itemIndx, 1)
            const pageNumber = items.length === pagination.page_size ? 0 : pagination.page_number
            update({
                contacts: items,
                pagination: {
                    total_count: items.length,
                    rows: items.slice(
                        pageNumber * pagination.page_size,
                        pageNumber * pagination.page_size + pagination.page_size
                    ),
                    page_size: 10,
                    page_number: pageNumber,
                },
            })
        },
        [contacts, pagination]
    )

    const handleAddContacts = useCallback(() => {
        const newContacts = [
            ...contacts,
            {
                uid: uuidv4(),
                [t("CAMPAIGN.FIRSTNAME")]: formValue?.firstname,
                [t("CAMPAIGN.LASTNAME")]: formValue?.lastname,
                [typeLabel]:
                    formValue?.type === "sms"
                        ? internationalPhone(formValue?.phone, formValue?.country || "CA")
                        : formValue?.email,
            },
        ]
        update({
            contacts: newContacts,
            pagination: {
                ...pagination,
                rows: newContacts,
                total_count: newContacts.length,
            },
        })

        setValue("firstname", "")
        setValue("lastname", "")
        setValue("email", "")
        setValue("phone", "")
    }, [contacts, typeLabel, formValue, pagination])

    const checkContactsIfExists = useCallback(
        (contacts: any, value: string) => {
            return (
                contacts.filter((item) => {
                    if (formValue?.type === "sms") {
                        return item[typeLabel] === internationalPhone(value, formValue?.country || "CA")
                    }
                    return item[typeLabel] === value
                })?.length !== 0
            )
        },
        [formValue]
    )

    const canAdd = useMemo(() => {
        if (checkContactsIfExists(contacts, formValue?.type === "sms" ? formValue?.phone : formValue?.email))
            return false
        if (formValue?.type === "sms") {
            return !!formValue?.phone && isPhoneValid(formValue?.phone) && !/^\+\d{1,4}$/.test(formValue?.phone)
        }
        return isValidEmail(formValue?.email) && formValue?.email
    }, [formValue, contacts])

    const emailError = useMemo(() => {
        return errors?.email?.message || (!isValidEmail(formValue?.email) && t("USERS.INVALID_FORMAT_EMAIL"))
    }, [formValue?.email, errors])

    const canUpdate = useMemo(() => {
        if (!currentItem) return true
        // Remove the current item from the contact list to allow the row to be editable
        const filteredContacts = contacts.filter((_, index) => ![currentIndex].includes(index))
        // The user cannot enter an email or phone number that already exists
        if (
            checkContactsIfExists(
                filteredContacts,
                formValue?.type === "sms" ? currentItem[t("CAMPAIGN.PHONE_NUMBER")] : currentItem[t("LABEL.EMAIL")]
            )
        )
            return false
        if (formValue?.type === "sms") return !invalidPhone
        return currentItem[t("LABEL.EMAIL")] && isValidEmail(currentItem[t("LABEL.EMAIL")])
    }, [formValue, currentItem, contacts, currentIndex])

    const disabled = useMemo(() => {
        return !formValue?.business || (creditFetched && !formValue?.hasCredit)
        // || formValue?.remainingCreditLessThanContacts
    }, [formValue, creditFetched])

    useEffect(() => {
        if (contacts?.length === 0) setEditItem(null)
    }, [contacts])

    const handleCancel = useCallback(() => {
        setEditItem(null)
        setCurrentItem(null)
        setCurrentIndex(0)
    }, [])

    return (
        <Stack className={classes.stepBloc}>
            <Paper className={classes.wrapperBloc}>
                <Stack className="input-business" data-e2e="select-business">
                    <BaseSelect
                        required
                        options={options || []}
                        controlName="business"
                        label={t("CAMPAIGN.BUSINESS")}
                        control={control}
                    />
                </Stack>
                {formValue?.type === "email" && (
                    <Stack className="select-lang">
                        <SelectLang
                            defaultValue={formValue?.lang}
                            options={[
                                {
                                    label: t("CAMPAIGN.LANGS.FR"),
                                    value: "fr-FR",
                                    // flag: "flag-fr",
                                },
                                {
                                    label: t("CAMPAIGN.LANGS.EN"),
                                    value: "en",
                                    // flag: "flag-us",
                                },
                            ]}
                            label={t("CAMPAIGN.LABEL_LANG")}
                            onItemClicked={(option: FlagOptionType) => setValue("lang", option?.value)}
                        ></SelectLang>
                    </Stack>
                )}
                <Stack flexDirection={"row"} gap={2} data-testid="radio-choice" data-e2e="select-campaign">
                    <RadioTabButton
                        data={radioTabButtons}
                        defaultActive={defaultType}
                        sx={radioTabButtonSx}
                        onChange={handleViewChange}
                    />
                    <Stack flexDirection={"row"} gap={2} justifyContent={"center"} alignItems={"center"}>
                        <Stack flexDirection={"row"} alignItems="center" gap={0.5} data-testid="sms">
                            <Icon name="messageText" />
                            <Typography component={"span"}>{creditSms}</Typography>
                        </Stack>
                        <Stack flexDirection={"row"} alignItems="center" gap={0.5} data-testid="email">
                            <Icon name="mail" />
                            <Typography component={"span"}>{creditEmail}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
            {formValue?.business && creditFetched && !formValue?.hasCredit && (
                <Paper className={classes.noMoreCredit}>
                    <Stack className={"content"} gap={1}>
                        <Stack flexDirection={"column"} gap={"4px"}>
                            <Typography
                                variant="h1"
                                component={"span"}
                                data-e2e="popup-need-reco"
                                sx={{ fontSize: 24, textAlign: "left", width: "100%" }}
                            >
                                {t("CAMPAIGN.MISSING_CREDITS")}
                            </Typography>
                            <Typography sx={{ color: "action.active", fontSize: 14 }}>
                                {t("CAMPAIGN.ZERO_CREDIT")}
                            </Typography>
                        </Stack>
                        <Button onClick={sendRequest} color="primary" variant="contained">
                            {t("CAMPAIGN.ASK_CREDITS")}
                        </Button>
                    </Stack>
                </Paper>
            )}
            <Paper>
                <Stack>
                    <Button
                        className="btn-import btn-mobile"
                        variant={"contained"}
                        onClick={showUpload}
                        color="primary"
                        disabled={disabled}
                    >
                        <Icon name="upload" sx={{ color: "white" }} />
                        <Typography component={"span"}>{t("CAMPAIGN.IMPORT")}</Typography>
                    </Button>
                </Stack>
                <Stack className={classes.inputBloc}>
                    <Controller
                        render={({ field }) => (
                            <BaseInput label={t("CAMPAIGN.FIRSTNAME")} id="firstname" disabled={disabled} {...field} />
                        )}
                        name="firstname"
                        control={control}
                    />
                    <Controller
                        render={({ field }) => (
                            <BaseInput label={t("CAMPAIGN.LASTNAME")} id="lastname" disabled={disabled} {...field} />
                        )}
                        name="lastname"
                        control={control}
                    />

                    {defaultType === 0 && (
                        <BaseInputPhone
                            control={control}
                            name="phone"
                            required
                            label={t("CAMPAIGN.PHONE_NUMBER")}
                            onlyCountries={["CA", "US", "FR"]}
                            lang={langOfCountryName}
                            defaultCountry={defaultFlag}
                            onChange={(info) => {
                                // setValue("phone", info?.numberValue)
                                setValue("country", info?.countryCode)
                            }}
                            error={errors?.phone || !isPhoneValid(formValue.phone)}
                            errorLabel={t("BUSINESSES.EDIT_MAIN_INFO.PHONE_INVALID")}
                            disabled={disabled}
                        />
                    )}
                    {defaultType === 1 && (
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    type="email"
                                    label={t("LABEL.EMAIL")}
                                    required
                                    showLabelStar
                                    autoComplete="on"
                                    id="email"
                                    {...field}
                                    error={errors?.email || !isValidEmail(formValue?.email)}
                                    helperText={emailError}
                                    disabled={disabled}
                                />
                            )}
                            control={control}
                            name="email"
                        />
                    )}
                    <Button
                        id="add-contact"
                        className="btn-add btn-mobile"
                        variant="text"
                        disabled={!canAdd}
                        onClick={handleAddContacts}
                    >
                        <Icon name="plus" />
                        {t("BUSINESSES.EDIT_ATTRIBUTES.ADD")}
                    </Button>
                </Stack>
            </Paper>
            {contacts && contacts.length > 0 && (
                <Paper>
                    <ListTable
                        className="table-contact"
                        multipleCol={true}
                        handleChangePage={handleChangePage}
                        rows={pagination.rows ?? []}
                        headCells={[
                            { name: t("CAMPAIGN.FIRSTNAME") },
                            { name: t("CAMPAIGN.LASTNAME") },
                            { name: typeLabel },
                            { name: "", width: "115px" },
                        ]}
                        generateLabels={(row) => [
                            row[t("CAMPAIGN.FIRSTNAME")] || "",
                            row[t("CAMPAIGN.LASTNAME")] || "",
                            row[typeLabel] || "",
                        ]}
                        editItem={editItem}
                        metadata={pagination}
                        disableButton={!canUpdate}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        handleCancel={handleCancel}
                        handleSave={handleSaveItem}
                        handleDataChange={setCurrentItem}
                        toolTipDeleteText={t("CAMPAIGN.REMOVE")}
                        toolTipEditText={t("CAMPAIGN.MODIFY")}
                    />
                </Paper>
            )}

            <Paper className={classes.wrapperBloc}>
                <Controller
                    name="confirmation"
                    control={control}
                    defaultValue={formValue?.confirmation ?? false}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value ?? false}
                                    onChange={(e) => onChange(e.target.checked)}
                                    disabled={disabled}
                                />
                            }
                            label={
                                <Stack direction="row" alignItems="center" spacing={1} data-e2e="confirm">
                                    <Typography color="action.active">
                                        {t("CAMPAIGN.CONFIRMATION")}
                                        <span className="MuiFormControlLabel-asterisk">*</span>
                                    </Typography>
                                </Stack>
                            }
                        />
                    )}
                />
            </Paper>

            <UploadFile
                open={openUpload}
                close={closeUpload}
                setFile={updateFile}
                title={t("BUSINESSES.EDIT_LOGO.ADD_FILE")}
                accept=".csv,application/vnd.ms-excel,.xlt,application/vnd.ms-excel,.xla,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xltx,application/vnd.openxmlformats-officedocument.spreadsheetml.template,.xlsm,application/vnd.ms-excel.sheet.macroEnabled.12,.xltm,application/vnd.ms-excel.template.macroEnabled.12,.xlam,application/vnd.ms-excel.addin.macroEnabled.12,.xlsb,application/vnd.ms-excel.sheet.binary.macroEnabled.12,text/csv"
                replace=".csv"
                subTitle={
                    <>
                        <Typography
                            component="span"
                            className="image-type"
                            variant="caption"
                            sx={{
                                display: "block",
                                color: "#68738D",
                                lineHeight: "24px",
                                fontSize: "16px",
                            }}
                        >
                            {t("CAMPAIGN.UPLOAD.COLUMNS")}
                        </Typography>
                        <Typography
                            component="span"
                            className="image-type"
                            variant="caption"
                            sx={{
                                display: "block",
                                color: "#68738D",
                                lineHeight: "24px",
                                fontSize: "16px",
                            }}
                        >
                            <strong>{t("CAMPAIGN.UPLOAD.INFOS")}</strong>
                        </Typography>
                        <Typography
                            component="span"
                            className="image-type"
                            variant="caption"
                            sx={{
                                display: "block",
                                color: "#68738D",
                                lineHeight: "24px",
                                fontSize: "16px",
                            }}
                        >
                            {t("CAMPAIGN.UPLOAD.CONTACT")}
                        </Typography>
                    </>
                }
            >
                <UploadMessage />
            </UploadFile>
        </Stack>
    )
}

export default FirstStepCampaign
