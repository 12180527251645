export interface MediaObject {
    file: File
    type: string
    googleChecked: boolean
    fbChecked: boolean
}

export const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob
    b.lastModifiedDate = new Date()
    b.name = fileName
    Object.setPrototypeOf(b, File.prototype)
    return theBlob as File
}

const imgListExtension = ["jpg", "jpeg", "png"]
const videoListExtension = ["mp4", "mov", "m4v"]
const headerListType = ["image/png", "image/jpeg", "image/jpg", "video/mp4", "video/mov", "video/m4v"]

export const getProvider = (googleChecked, fbChecked): any[] => {
    const provider = []
    if (googleChecked && fbChecked) {
        provider.push("google", "facebook")
    }
    if (!googleChecked && fbChecked) {
        provider.push("facebook")
    }
    if (googleChecked && !fbChecked) {
        provider.push("google")
    }
    return provider
}

const mediaUploadList = (
    mediasLocal: any,
    mediaFiles: FileList,
    googleChecked: boolean,
    fbChecked: boolean
): MediaObject[] => {
    const preFileList = []
    Array.from(mediaFiles)?.map((mediaFile: File) => {
        const mediaExtension = mediaFile?.name?.split(".").pop().toLowerCase()
        if (mediasLocal?.filter((media) => media.file.name === mediaFile.name)?.length > 0) {
            return []
        }
        if (
            headerListType.includes(mediaFile?.type) &&
            ((imgListExtension.includes(mediaExtension) && mediaFile.size <= 5 * 1024 * 1024) ||
                (videoListExtension.includes(mediaExtension) && mediaFile.size <= 50 * 1024 * 1024)) &&
            mediaFile.size >= 10 * 1024
        ) {
            const mediaObject = {
                file: mediaFile,
                type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
                googleChecked,
                fbChecked,
            }
            preFileList.push(mediaObject)
        }
    })
    return preFileList.length === mediaFiles.length ? preFileList : []
}

export default mediaUploadList
