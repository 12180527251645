import { EntitiesApi } from "@/api"

class CampaignService {
    fetchBusinessEntities = async (user_uid: string, entity_type: string) => {
        return await EntitiesApi.fetchListsEntities({
            page_number: 0,
            page_size: 0,
            user_uid,
            entity_type,
        })
    }
}

export default new CampaignService()
