import useUiPageSession from "@/hooks/useUiPageSession"
import LocationService from "@/services/location"
import UiStore from "@/store/ui"
import { Fragment, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import CreateBusiness from "./create-business"
import ListBusiness from "./list-business"
import BusinessInfo from "./overview-business"
const BusinessesPage = () => {
    const { t } = useTranslation()
    const { updateState } = UiStore.getState()
    const { handleDrawerClose, showPage, open } = useUiPageSession({
        title: "BUSINESSES.TITLE_HEADER",
        page: "businesses",
    })
    const pageTitle = useMemo(() => {
        let header_title = t("BUSINESSES.TITLE_HEADER")
        if (open) {
            switch (showPage) {
                case "create":
                    header_title = t("BUSINESSES.CREATE.TITLE")
                    break
                case "edit-main-info":
                    header_title = t("BUSINESSES.EDIT_MAIN_INFO.TITLE")
                    break
                case "edit-category":
                    header_title = t("BUSINESSES.EDIT_CATEGORIES.TITLE")
                    break
                case "edit-link":
                    header_title = t("BUSINESSES.EDIT_LINKS.TITLE")
                    break
                case "edit-description":
                    header_title = t("BUSINESSES.EDIT_CONTENTS.TITLE")
                    break
            }
        }
        return header_title
    }, [t, showPage, open])

    useEffect(() => {
        return () => {
            LocationService.clearLocation()
        }
    }, [])

    useEffect(() => {
        updateState({ title: pageTitle })
    }, [pageTitle])

    return (
        <Fragment>
            <ListBusiness />
            <CreateBusiness open={showPage === "create" && open} onClose={handleDrawerClose} />
        </Fragment>
    )
}

export default BusinessesPage
