import Notification from "@/components/notification"
import { CookiesService, ResellerService } from "@/services"
import LangService from "@/services/lang"
import NotificationStore from "@/store/notification"
import ResellerStore from "@/store/reseller"
import UiStore from "@/store/ui"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation } from "react-router-dom"
const UNPROTECTED_ROUTE = ["/login"]

const GlobalOutlet = () => {
    const { resellerName, resellerFetched } = ResellerStore()
    const { title, favicon, preferredLanguage, updateState } = UiStore()
    const { i18n, t } = useTranslation()
    const { update: updateNotifState } = NotificationStore()
    const { pathname } = useLocation()

    useEffect(() => {
        document.title = title ? `${resellerName ?? ""} - ${t(title)}` : resellerName
    }, [resellerName, t, title])

    useEffect(() => {
        if (favicon) {
            const link = document.querySelector("link#favicon")
            if (link) link.setAttribute("href", favicon + "?v=2")
        }
    }, [favicon])

    useEffect(() => {
        function handleLanguageChange() {
            const language = LangService.preloadLanguage(navigator.languages)
            updateState({ preferredLanguage: language })
        }
        window.addEventListener("languagechange", handleLanguageChange)
        return () => {
            window.removeEventListener("languagechange", handleLanguageChange)
        }
    }, [])

    useEffect(() => {
        const language = LangService.preloadLanguage(navigator.languages)
        document.documentElement.lang = language
        CookiesService.set({ lang: language })
        updateState({ preferredLanguage: language })
    }, [])

    useEffect(() => {
        const unprotectedRoute = UNPROTECTED_ROUTE.includes(window.location.pathname)
        if (!resellerFetched || (unprotectedRoute && !resellerFetched)) {
            const getReseller = async () => {
                await ResellerService.getResellerInfos()
            }
            getReseller()
        }
        updateNotifState({ show: false })
    }, [pathname, resellerFetched, updateNotifState])

    useEffect(() => {
        i18n.changeLanguage(preferredLanguage)
    }, [preferredLanguage, i18n])

    return (
        <React.Fragment>
            <Notification />
            <Outlet />
        </React.Fragment>
    )
}

export default GlobalOutlet
