import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    overviewContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        height: "auto",
        width: "100%",
        "@media (min-width: 768px)": {
            padding: 0,
            paddingBottom: 16,
            gap: 24,
        },
        "& .social-wrapper": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            "& .social-btn": {
                display: "flex",
                gap: 8,
            },
        },
        "& .container": {
            display: "flex",
            flexDirection: "column",
            gap: 16,
            "@media (min-width: 768px)": {
                gap: 24,
            },
            "& .clickable": {
                "&:hover .title": {
                    "& > .edit-btn": {
                        color: theme.palette.primary.main,
                        "@media (min-width: 768px)": {
                            display: "inline-flex",
                        },
                    },
                },
                "& .title": {
                    minHeight: "24px",
                    "@media (min-width: 768px)": {
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                    },
                    "& > .edit-btn": {
                        display: "none",
                        "@media (min-width: 768px)": {
                            padding: 0,
                            display: "none",
                        },
                    },
                },
            },
            "& .section": {
                "body & .schedules": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    "& > .MuiPaper-root ": {
                        justifyContent: "flex-start",
                        height: "auto",
                    },
                    "@media (min-width: 768px)": {
                        gap: 16,
                    },
                    "@media (min-width: 1024px)": {
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                    },
                },
                "& .section-content": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    "&.reverse": {
                        flexDirection: "column-reverse",
                    },
                    "@media (min-width: 1024px)": {
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                    },
                    "@media (min-width: 768px)": {
                        gap: 16,
                    },
                },

                "& .paper-wrapper": {
                    display: "flex",
                    gap: 8,
                    flexDirection: "column",
                    "@media (min-width: 768px)": {
                        gap: 16,
                    },
                    "& .line-wrapper": {
                        justifyContent: "flex-start",

                        "& .item-wrapper": {
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        },
                    },
                },

                "& .title-wrapper": {
                    display: "flex",
                    alignItems: "center",
                    gap: 16,
                    backgroundColor: "transparent",
                    margin: 0,
                    "& h2": {
                        fontSize: 24,
                        fontWeight: 500,
                        color: theme.palette.common.black,
                        fontStyle: "normal",
                        lineHeight: "133.4%",
                    },
                    "& svg": {
                        backgroundColor: "initial",
                        width: 24,
                        height: 24,
                        padding: 0,
                        color: theme.palette.action.active,
                        borderRadius: 0,
                        minWidth: 24,
                    },
                },
                "& .contact-wrapper": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    // "@media (min-width:768px)": {
                    //     padding: "0 16px",
                    // },
                    "& .title-wrapper": {
                        padding: 0,
                        gap: 8,
                        "& h3": {
                            fontSize: 24,
                            fontWeight: 500,
                        },
                        "& svg": {
                            borderRadius: 0,
                            color: theme.palette.action.active,
                        },
                    },
                },
                "& .second-title": {
                    display: "flex",
                    gap: 16,
                    alignItems: "center",
                    "& h3": {
                        fontSize: 20,
                        fontWeight: "500",
                        color: theme.palette.common.black,
                    },
                    "& svg": {
                        minWidth: 24,
                    },
                },
                "&.description": {
                    "& h2": {
                        color: theme.palette.text.primary,
                    },
                },
                "& .MuiFormLabel-root": {
                    fontSize: 12,
                    color: theme.palette.action.active,
                },
                "& .chip-wrapper": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                },
                "& .line-wrapper": {
                    display: "flex",
                    gap: 8,
                    width: "fit-content",
                    "& .MuiTypography-root": {
                        color: theme.palette.action.active,
                        "&.link-url": {
                            wordBreak: "break-word",
                        },
                    },
                    "& svg, .div-img": {
                        color: theme.palette.action.active,
                        width: 24,
                        height: 24,
                        minWidth: 24,
                    },
                    "& .div-img": {
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                    "&.address": {
                        alignItems: "flex-start",
                        "& > .MuiBox-root": {
                            display: "flex",
                            gap: 8,
                        },
                        "& .MuiIconButton-root": {
                            padding: 0,
                        },
                    },
                },
                "& .schedule-wrapper": {
                    display: "flex",
                    alignItems: "start",
                    padding: "6px 16px",
                    width: "100%",
                    color: theme.palette.action.active,
                    "&.number-other-items": {
                        color: theme.palette.primary.main,
                        fontSize: "20px",
                        fontWeight: 500,
                        border: "none",
                    },
                },
                "& .hours-wrapper": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                    padding: 0,
                    "& span": {
                        whiteSpace: "nowrap",
                    },
                },

                "& .w-fr": {
                    minWidth: "130px",
                },

                "& .w-en": {
                    minWidth: "170px",
                },
            },
        },
        "& .status-schedule-container": {
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            "@media (min-width: 768px)": {
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            },
        },

        "& .text-count": {
            fontWeight: 500,
            color: "#0180FF",
        },
    },
}))

export const disconnectModal = {
    "& .action-wrapper": {
        flexDirection: "row",
        "@media (min-width: 768px)": {
            gap: 2,
        },
        "& button:first-of-type": {
            width: "fit-content",
            "@media (min-width: 768px)": {
                width: "100%",
            },
        },
        "& .disconnect": {
            display: "flex",
            gap: 1,
        },
    },
    "& p": {
        color: "action.active",
    },
    "& .title-wrapper": {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        "& button": {
            position: "initial",
        },
    },
}

export default useStyles
