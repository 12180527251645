import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import useLocationHours from "@/hooks/useLocationHours"
import useLocationStore from "@/store/location"
import useBusinessRegularHoursStore from "@/store/overview/regular-hours"
import useBusinessSpecialHoursStore from "@/store/overview/special-hours"
import { Alert, AlertTitle, Paper } from "@mui/material"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import BusinessScheduleTable from "../../schedule/table"
import OverviewTitle from "../title"

const BusinessExceptionalSchedule = ({ specialHoursRef }) => {
    const { t } = useTranslation()
    const { navigateTo } = useBusinessLogic()
    const { location } = useLocationStore()
    const { updateSpecialHours } = useBusinessSpecialHoursStore()
    const { status } = useBusinessRegularHoursStore()
    const { specialHoursList, regularHourList } = useLocationHours()
    const hasRegularHours = useMemo(() => {
        return regularHourList?.length <= 0
    }, [regularHourList])
    const regularHourListCheck = useMemo(() => (status === "OPEN" ? hasRegularHours : false), [status, hasRegularHours])

    useEffect(() => {
        if (location?.specialHours) {
            updateSpecialHours({
                specialHours: location?.specialHours,
            })
        }
    }, [location?.specialHours])
    return (
        <Paper
            elevation={2}
            id="special-hours"
            className="clickable"
            ref={specialHoursRef}
            onClick={() => navigateTo("edit-exceptional-schedule")}
        >
            <OverviewTitle
                title={t("BUSINESSES.SCHEDULE.EXCEPTIONAL_OPENING")}
                // icon="calendar"
                isSecondTitle
                tooltipText={t("BUSINESSES.SCHEDULE.EDIT_EXCEPTIONAL_OPENING_TOOLTIP")}
            />
            {specialHoursList.length > 0 && !regularHourListCheck && (
                <BusinessScheduleTable t={t} list={specialHoursList} type="exceptional" />
            )}
            {regularHourListCheck && (
                <Alert severity="info" className="alert-info">
                    <AlertTitle>{t("BUSINESSES.SCHEDULE.HIDDEN_GOOGLE_TITLE")}</AlertTitle>
                    {t("BUSINESSES.SCHEDULE.HIDDEN_GOOGLE_CONTENT")}
                </Alert>
            )}
        </Paper>
    )
}
export default BusinessExceptionalSchedule
