import { ApiService, SendrService } from ".."
import { ErrorResponse } from "../base"

export interface GetCreditProps {
    company_uid: string
}

interface OptionalLocationCampaignProps {
    address: string
}

interface LocationCampaignEmail {
    smsSender: string
    lang: string
    subject: string
    from: string
}

interface OptionalCampaignProps {
    longLink: string
}

interface CampaignContentProps {
    to: string
    body: string
}

interface LocationCampaign extends Partial<OptionalLocationCampaignProps> {
    id: string
    name: string
    logo: string
    zip: string
    province_code: string
    country: string
    city: string
}

export interface SendCampaignProps extends Partial<LocationCampaignEmail>, Partial<OptionalCampaignProps> {
    type: string
    companyId: string
    location: LocationCampaign
    feedback: boolean
    content: CampaignContentProps[]
}

export interface RequestServiceProps {
    company_uid: string
    user_uid: string
    type: string
    lang: string
}

export interface CreditType {
    type: string
    quantity: number
    used: number
    remaining: number
}

export interface SendCampaignResponse {
    data: string
}

class CampaignApi {
    getCredit = async ({ company_uid }: GetCreditProps): Promise<CreditType[] & Partial<ErrorResponse>> => {
        return await ApiService.get(`/credit/${company_uid}`)
    }

    send = async ({ body }: { body: SendCampaignProps }): Promise<SendCampaignResponse & Partial<ErrorResponse>> => {
        return await SendrService.post("/send", body)
    }

    requestCredit = async ({
        body,
    }: {
        body: RequestServiceProps
    }): Promise<SendCampaignResponse & Partial<ErrorResponse>> => {
        return await ApiService.post(`/credit-request`, body)
    }
}

export default new CampaignApi()
