import { FetchKpiInput } from "@/api/statistics"
import { LocationService, StatisticReputationService } from "@/services"

import KpiReputationStore from "@/store/kpi-reputation"
import UserSessionStore from "@/store/user-session"
import { useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import useUiPageSession from "../useUiPageSession"

const useLogic = () => {
    const {
        graph,
        kpi,
        payloadFilter,
        update,
        selectedBusinesses,
        selectedPeriod,
        loading,
        updatePayload,
        updatePeriod,
    } = KpiReputationStore()
    const { profile } = UserSessionStore()

    useUiPageSession({
        title: "REPUTATION.TITLE_PERFORMANCE",
        page: "local_presence",
    })
    const { t } = useTranslation()

    const handleSelectionChange = useCallback((items) => {
        const filteredLocationIds = items?.map((item) => item.value) || []
        if (filteredLocationIds?.length > 0) {
            update({
                selectedBusinesses: items,
            })
            updatePayload({ locationsId: filteredLocationIds })
        } else {
            updatePayload({ locationsId: [] })
            update({ selectedBusinesses: [] })
        }
    }, [])

    const handleSelectionPeriodChange = useCallback((item) => {
        let type = "year"
        if (item.value === 1) {
            type = "month"
        } else if (item.value === 2) {
            type = "quarter"
        }
        updatePayload({ type: type })
        updatePeriod(item)
    }, [])

    const defaultIds = useMemo(async () => {
        if (profile?.uid) {
            return await LocationService.fetchLocationIdsByUserUid(profile?.uid)
        }
        return []
    }, [profile?.uid])

    const fetchKpi = useCallback(
        async (filterParams: Partial<FetchKpiInput>) => {
            update({ loading: true })

            const ids = payloadFilter.locationsId.length > 0 ? payloadFilter.locationsId : await defaultIds
            if (ids) {
                const locationsId = filterParams?.locationsId?.length > 0 ? filterParams.locationsId : ids
                const response = await StatisticReputationService.fetchKpiReputation({
                    ...payloadFilter,
                    ...filterParams,
                    locationsId,
                })
                update({ graph: response.graph, kpi: response.kpi })
            }

            update({ loading: false })
        },
        [payloadFilter, defaultIds]
    )

    const testKpi = () => {
        if (
            kpi?.period?.review_rating_average == 0 &&
            kpi?.period?.review_count == 0 &&
            kpi?.period?.review_treated_rate == 0
        ) {
            return false
        }
        return true
    }

    const testKpiPrevious = () => {
        if (
            kpi?.previous_period?.review_count_variation == 0 &&
            kpi?.previous_period?.review_rating_average_variation == 0 &&
            kpi?.previous_period?.review_treated_rate_variation == 0
        ) {
            return false
        }
        return true
    }

    const graphs = useMemo(() => {
        if (graph)
            return {
                average_grade: StatisticReputationService.resolveGraphsAverageGrade(graph, payloadFilter.type),
                review_treated: StatisticReputationService.resolveGraphsAReviewTreated(graph, payloadFilter.type),
                review_count: StatisticReputationService.resolveGraphsReviewCount(graph, payloadFilter.type),
                feedback: StatisticReputationService.resolveGraphsFeedback(graph, payloadFilter.type),
            }
        return {
            average_grade: {},
            review_treated: {},
            review_count: {},
            feedback: {},
        }
    }, [graph])

    useEffect(() => {
        fetchKpi({})
    }, [fetchKpi])

    return {
        t,
        handleSelectionChange,
        handleSelectionPeriodChange,
        kpi,
        profile,
        graph,
        payloadFilter,
        testKpi,
        testKpiPrevious,
        graphs,
        loading,
        selectedBusinesses,
        selectedPeriod,
    }
}

export default useLogic
