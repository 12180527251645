import { LocationType } from "@/store/location"
import { SxProps, Theme } from "@mui/material"
import dayjs from "dayjs"
import "dayjs/locale/en"
import "dayjs/locale/fr"
import parseRegularHours from "./parseRegularHours"
import validateHours from "./validateHours"
import validateTimes from "./validateTimes"
import { CookiesService } from "@/services"
export type ColorType = "primary" | "success" | "warning" | "error"

export const decode64AndParse = (base64: string) => {
    return JSON.parse(window.atob(base64))
}

export const calculateHeight = (list: NodeListOf<HTMLDivElement>, isTransparent: boolean, width: number) => {
    list.forEach((parent) => {
        const element = parent.querySelector(".title-wrapper")
        const elementHeight = element.clientHeight
        const formElement = element.nextElementSibling as HTMLFormElement
        if (width >= 768) {
            formElement.style.height = `calc(100% - 48px ${!isTransparent ? "- 32px" : ""} - 24px - ${elementHeight}px)`
        } else {
            formElement.style.cssText = `height: calc(100% - 72px - 8px ${
                !isTransparent ? "- 16px" : ""
            } - 16px - ${elementHeight}px);`
        }
    })
}

export const isValidURL = (url: string) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?([A-Za-z0-9-]+\\.)+[A-Za-z]{2,6}(:\\d{1,5})?(\\/.*)?$", // fragment locator
        "i"
    )
    return pattern.test(url)
}

export const isValidEmail = (email: string) => {
    if (!email || email === undefined) return true
    // const pattern = new RegExp("[a-z0-9]+@[a-z]+\\.[a-z]{2,3}")
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return pattern.test(email)
}

export const isValidURLSocialNetwork = (url: string, types: string) => {
    for (const type of types) {
        if (url.includes(type)) return true
    }

    return false
}

export const formatZipByCountry = (value: string, countryCode: string) => {
    if (countryCode === "CA") {
        if (/^[a-zA-Z][0-9][a-zA-Z][0-9][a-zA-Z][0-9]$/.test(value)) {
            return `${value.substring(0, 3)} ${value.substring(3)}`.toUpperCase()
        }
        if (
            value === "" ||
            // length === 1
            /^[a-zA-Z]$/.test(value) ||
            // length === 2
            /^[a-zA-Z][0-9]$/.test(value) ||
            // length === 3
            /^[a-zA-Z][0-9][a-zA-Z]$/.test(value) ||
            // length === 4
            /^[a-zA-Z][0-9][a-zA-Z]\s$/.test(value) ||
            /^[a-zA-Z]\w[a-zA-Z]\w$/.test(value) ||
            // length === 5
            /^[a-zA-Z][0-9][a-zA-Z]\s[0-9]$/.test(value) ||
            /^[a-zA-Z]\w[a-zA-Z]\w[a-zA-Z]$/.test(value) ||
            // length === 6
            /^[a-zA-Z][0-9][a-zA-Z]\s[0-9][a-zA-Z]$/.test(value) ||
            /^[a-zA-Z]\w[a-zA-Z]\w[a-zA-Z]\w]$/.test(value) ||
            // length === 7
            /^[a-zA-Z][0-9][a-zA-Z]\s[0-9][a-zA-Z][0-9]$/.test(value)
        ) {
            return value.toUpperCase()
        }
    } else {
        if (value === "") return ""
        if (/^(?:\d{1,5})?$/.test(value)) {
            return value
        }
    }
}

export const withSxProp = (...sxProps: SxProps<Theme>[]): SxProps<Theme> =>
    sxProps.reduce(
        (prev, currentValue) => [
            ...(Array.isArray(prev) ? prev : [prev]),
            ...(Array.isArray(currentValue) ? currentValue : [currentValue]),
        ],

        [] as SxProps<Theme>
    )

const checkDisplayOfDate = (initDate, lang) => {
    const locale = lang === "en" ? "en" : "fr"
    const formatType = lang === "en" ? "MMM DD, YYYY" : "DD MMM YYYY"
    return `${dayjs.utc(initDate).locale(locale).format(formatType)}`
}

const sortDateException = (list: any) => {
    return list.sort((a, b) => (dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1))
}

const getColor = (color: ColorType) => {
    switch (color) {
        case "primary":
            return "text.primary"
        case "success":
            return "success.main"
        case "warning":
            return "warning.main"
        case "error":
            return "error.main"
    }
}

const getScoreColor = (score: number) => {
    if (score <= 33) {
        return getColor("error")
    } else if (score > 33 && score <= 66) {
        return getColor("warning")
    } else {
        return getColor("success")
    }
}

const hasOwn = (data: object, key: string): boolean => {
    return key in data
}

const isGoogleConnected = ({ locationState }: LocationType): boolean => {
    if (!locationState) {
        return false
    }

    // ET locationState.isVerified == true || !isset(locationState.isVerified)
    const isVerified = locationState.isVerified || !hasOwn(locationState, "isVerified")

    // ET locationState.locationNotFound == false
    const hasLocationFound = !locationState.locationNotFound || !hasOwn(locationState, "locationNotFound")

    // ET locationState.isSuspended == false || !isset(locationState.isSuspended)
    const isNotSuspended = !locationState.isSuspended || !hasOwn(locationState, "isSuspended")

    // ET locationState.isDisabled == false || !isset(locationState.isDisabled)
    const isEnabled = !locationState.isDisabled || !hasOwn(locationState, "isDisabled")

    // ET locationState.isDuplicate == false || !isset(locationState.isDuplicate)
    const isUniq = !locationState.isDuplicate || !hasOwn(locationState, "isDuplicate")

    // ET locationState.needsReconnection == false || !isset(locationState.needsReconnection)
    const notNeedsReconnection = !locationState.needsReconnection || !hasOwn(locationState, "needsReconnection")

    // ET locationState.resolveOwnershipConflict == [] || !isset(locationState.resolveOwnershipConflict)
    const hasPendingVerification =
        !locationState.hasPendingVerification || !hasOwn(locationState, "hasPendingVerification")

    // ET locationState.hasPendingVerification == false || !isset(locationState.hasPendingVerification)
    const hasResolveOwnershipConflict =
        locationState.resolveOwnershipConflict?.length === 0 || !hasOwn(locationState, "resolveOwnershipConflict")

    return (
        isVerified &&
        hasLocationFound &&
        isNotSuspended &&
        isEnabled &&
        isUniq &&
        notNeedsReconnection &&
        hasResolveOwnershipConflict &&
        hasPendingVerification
    )
}

export const urlify = (text: string) => {
    const generatedUrl = CookiesService.get("feedbackUrl") + "/3MvoFeY"

    return text.replace(/@Url|@url/, generatedUrl).replace(/@Url|@url/gi, "")
}

export const removeDuplicateUrl = (text: string) => {
    const generatedUrl = CookiesService.get("feedbackUrl") + "/3MvoFeY"

    return text
        .replace(/@Url|@url/, generatedUrl)
        .replace(/@Url|@url/gi, "")
        .replace(generatedUrl, "@url")
}

export const objectToQueryString = (obj) => {
    const params = new URLSearchParams()
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            params.append(key, obj[key])
        }
    }
    return params.toString()
}

export const encodeBase64 = (base64: string) => {
    return window.btoa(base64)
}

const isObject = (object) => {
    return object != null && typeof object === "object"
}

const isDeepEqual = (object1, object2) => {
    const objKeys1 = Object.keys(object1)
    const objKeys2 = Object.keys(object2)

    if (objKeys1.length !== objKeys2.length) return false

    for (const key of objKeys1) {
        const value1 = object1[key]
        const value2 = object2[key]

        const isObjects = isObject(value1) && isObject(value2)

        if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
            return false
        }
    }
    return true
}

const convertHtml = (html) => {
    let plainText = html.replace(/<p><\/p>/g, "")

    // Remove <p> and </p> tags but keep their content
    plainText = plainText.replace(/<\/p><p>/g, "\n")

    // Remove any remaining HTML tags
    plainText = plainText.replace(/<[^>]*>/g, "")

    return plainText
}

// const convertHtml = (text: string) => {
//     return convert(text.replace(/<p><\/p>/g, ""))
//         .trim()
//         .replace(/\\n(.*?)\\n/, "")
//         .replace(/(\w)\n\n(\w)/g, (match, p1, p2) => `${p1}\n${p2}`)
//         .replace(/\n{3,8}/g, (match) => {
//             const nombreOccurrences = match.length
//             const nouveauNombreSautsDeLigne = Math.ceil(nombreOccurrences / 3)
//             return "\n".repeat(nouveauNombreSautsDeLigne)
//         })
// }

const isDecimalNumber = (value) => {
    return value % 1 > 0
}

const convertBigNumber = (value: number) => {
    let val: string | number = Math.abs(value)
    if (val >= 10 ** 3 && val < 10 ** 6) {
        val = (val / 10 ** 3).toFixed(1) + " K"
    } else if (val >= 10 ** 6) {
        val = (val / 10 ** 6).toFixed(1) + " M"
    } else {
        val = isDecimalNumber(val) ? val.toFixed(1).toString() : val.toString()
    }

    return val
}

const convertBigNumberSpace = (value: number, defaultText, unitText = "") => {
    const val: string | number = Math.abs(value)

    if (!val && val != 0) {
        return defaultText
    }
    if (val >= 10 ** 6) {
        return `${(value / 10 ** 6).toFixed(1)} M${unitText}`
    }
    return `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${unitText}`
}

const convertPourcentageString = (value: number, testKpi = true, noPourcent = false, roundTest = true) => {
    let val = value
    if (roundTest) {
        val = Math.round(value)
    }

    if (!testKpi || !value) {
        return null
    } else {
        return val > 0 ? `+${val}%` : `${val} ${noPourcent ? "" : "%"}`
    }
}

const convertStyleString = (value: number) => {
    return value > 0 ? "superior" : "inferior"
}

const getIconStar = (index: number) => {
    if (index != 0 && index % 1 !== 0) {
        return "starHalfFilled"
    }
    if (index != 0 && index % 1 === 0) {
        return "starFilled"
    }
    return "starOutlineFilled"
}

const convertToStarArray = (number: number) => {
    return Array.from({ length: 5 }, (v, index) => {
        if (index < Math.floor(number)) {
            return 1
        } else if (index === Math.floor(number)) {
            return number % 1
        } else {
            return 0
        }
    })
}

const checkHavelink = (value: string) => {
    if (value.includes('data-id="url"')) {
        return true
    }
    return false
}

export {
    checkDisplayOfDate,
    convertBigNumber,
    convertBigNumberSpace,
    convertHtml,
    convertPourcentageString,
    convertStyleString,
    convertToStarArray,
    getColor,
    getIconStar,
    getScoreColor,
    isDeepEqual,
    isGoogleConnected,
    parseRegularHours,
    sortDateException,
    validateHours,
    validateTimes,
    checkHavelink,
}
