import { useState } from "react"

const useZipInputLogic = () => {
    const [isZipValid, setIsZipValid] = useState(true)
    const [zipInputValue, setZipInputValue] = useState<string>("")
    const [zipIsRequired, setZipIsRequired] = useState<boolean>(false)

    const checkZipIsValid = (zipInput: string, countryCode: string) => {
        if (!zipInput) {
            return true
        }
        if (countryCode === "CA") {
            return zipInput?.replace(/\s/g, "").length === 6
        } else {
            return zipInput?.replace(/\s/g, "").length === 5 && !isNaN(Number(zipInput?.replace(/\s/g, "")))
        }
    }

    return {
        isZipValid,
        zipInputValue,
        zipIsRequired,
        setZipInputValue,
        checkZipIsValid,
        setIsZipValid,
        setZipIsRequired,
    }
}

export default useZipInputLogic
