import { Paper, Skeleton, Stack } from "@mui/material"
import { useStyles } from "./style"

export const FormCampaign = () => {
    const { classes } = useStyles()
    return (
        <Paper className={classes.formBloc}>
            <Skeleton variant="rectangular" className="import-bloc" height={40} />
            <Stack className="bloc-inputs">
                <Skeleton variant="rectangular" width="100%" height={56} />
                <Skeleton variant="rectangular" width="100%" height={56} />
                <Skeleton variant="rectangular" width="100%" height={56} />
                <Skeleton variant="rectangular" width={80} sx={{ minWidth: 80, margin: "auto" }} height={56} />
            </Stack>
        </Paper>
    )
}
