import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()((theme) => ({
    statistic: {
        "& .bloc-number-stat": {
            display: "flex",
            flexDirection: "column",
            gap: 16,
            "@media (min-width: 1024px)": {
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
            },
        },
    },
}))
