import { ChangeEvent, useCallback, useState } from "react"

const useLogicLogo = ({ setLogo, close }) => {
    const [error, setError] = useState(false)
    const [isThereStaticLogo, setIsThereStaticLogo] = useState(false)
    const [imageFile, setImageFile] = useState<File>()
    const [label, setLabel] = useState<HTMLLabelElement>()

    const labelRefHandler = useCallback((ref: HTMLLabelElement) => {
        if (ref) setLabel(ref)
    }, [])

    const closeModal = useCallback(() => {
        setError(false)
        close()
        setIsThereStaticLogo(false)
    }, [])

    const onAdd = useCallback(() => {
        setLogo(imageFile)
        closeModal()
    }, [imageFile])

    const inputChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setIsThereStaticLogo(false)
        setImageFile(null)
        const imageFile = e.target.files["0"]
        handleImage(imageFile)
    }, [])

    const handleImage = useCallback((imageFile: File) => {
        if (imageFile) {
            setError(false)
            const imageExtension = imageFile?.name?.split(".")[1].toLowerCase()
            const img = new Image()
            img.src = URL.createObjectURL(imageFile)

            if (
                (imageFile?.type === "image/png" || imageFile?.type === "image/jpeg") &&
                (imageExtension === "png" || imageExtension === "jpg" || imageExtension === "jpeg") &&
                imageFile.size <= 10 * 1024 * 1024
            ) {
                img.onload = () => {
                    let ratio = 1
                    const thresHoldVal = 0.1 // 10% out.
                    if (img.height > img.width) {
                        ratio = img.height / img.width
                    } else {
                        ratio = img.width / img.height
                    }

                    if (ratio - 1 > thresHoldVal) {
                        setError(true)
                    } else {
                        setImageFile(imageFile)
                        setIsThereStaticLogo(true)
                    }
                }
            } else {
                setError(true)
            }
        }
    }, [])

    return {
        inputChangeHandler,
        handleImage,
        imageFile,
        error,
        closeModal,
        isThereStaticLogo,
        onAdd,
        labelRefHandler,
        label,
    }
}

export default useLogicLogo
