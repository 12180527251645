import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { modalStyle } from "@/pages/users/styles"
import { withSxProp } from "@/utils"
import { Box, Button, IconButton, Modal, Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"

interface NoCreditProps {
    modalContainer?: any
    onClose: () => void
    sendRequest: () => void
    open: boolean
    dataSent?: boolean
    zeroCredit?: boolean
}

const NoCreditModal = ({
    modalContainer,
    onClose,
    sendRequest,
    open = false,
    dataSent = true,
    zeroCredit = false,
}: NoCreditProps) => {
    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <Modal className={classes.container} open={open} container={() => modalContainer?.current}>
            <Paper
                sx={withSxProp(modalStyle, {
                    maxWidth: 490,
                    boxSizing: "border-box",
                    alignItems: "flex-start",
                })}
            >
                <Stack flexDirection={"row"} gap={2} justifyContent={"space-between"} sx={{ width: "100%" }}>
                    <Typography
                        variant="h1"
                        component={"span"}
                        data-e2e="popup-need-reco"
                        sx={{ fontSize: 24, textAlign: "left", width: "100%" }}
                    >
                        {!dataSent && t("CAMPAIGN.MISSING_CREDITS")}
                        {dataSent && t("CAMPAIGN.CREDITS_REQUEST_TITLE")}
                    </Typography>
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton onClick={onClose} className="btn-x" id="close">
                            <Icon name="close" />
                        </IconButton>
                    </CustomTooltip>
                </Stack>
                {!dataSent && (
                    <>
                        <Typography sx={{ color: "action.active", fontSize: 14 }}>
                            {t("CAMPAIGN.MISSING_DESC")}
                        </Typography>
                        <Box className="action-wrapper">
                            <Button onClick={onClose}>{t("USERS.CLOSE")}</Button>
                            <Button
                                onClick={sendRequest}
                                variant="contained"
                                size="large"
                                sx={{ gap: 1, width: "100%" }}
                            >
                                {t("CAMPAIGN.ASK_CREDITS")}
                            </Button>
                        </Box>
                    </>
                )}
                {dataSent && (
                    <>
                        <Typography sx={{ color: "action.active", fontSize: 14, textAlign: "left" }}>
                            {t("CAMPAIGN.CREDITS_REQUEST_DESC")}
                        </Typography>
                        {!zeroCredit && (
                            <Typography sx={{ color: "action.active", fontSize: 14, textAlign: "left" }}>
                                {t("CAMPAIGN.CREDITS_REQUEST_DESC_2")}
                            </Typography>
                        )}
                        {zeroCredit && (
                            <Typography sx={{ color: "action.active", fontSize: 14, textAlign: "left" }}>
                                {t("CAMPAIGN.CREDITS_ZERO_DESC")}
                            </Typography>
                        )}
                        <Box className="action-wrapper">
                            <Button onClick={onClose}>{t("USERS.CLOSE")}</Button>
                        </Box>
                    </>
                )}
            </Paper>
        </Modal>
    )
}

export default NoCreditModal
