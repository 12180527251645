import { Paper, Skeleton, Stack } from "@mui/material"
import { v4 as uuidv4 } from "uuid"

export const SkeletonBusinessItemMedia = () => {
    return (
        <Paper
            elevation={2}
            sx={{
                display: "flex",
                gap: 2,
            }}
        >
            <Skeleton variant="rectangular" width={100} height={20} />
            <Stack flexDirection={"row"} gap={2} alignItems={"center"} width="100%" height="100%" overflow={"hidden"}>
                {[...Array(6)].map((key) => (
                    <Skeleton key={uuidv4()} variant="rectangular" width={124} height={124} sx={{ minWidth: 124 }} />
                ))}
            </Stack>
        </Paper>
    )
}
