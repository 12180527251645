import { TitleWrapper } from "@/components/base/title-wrapper"
import AttributeAccordion from "@/components/business/accordion"
import EditAttributeContainer from "@/components/business/form-container/edit/edit-attribute"
import FormAction from "@/components/business/form-container/edit/form-action"
import useLogic from "@/hooks/businesses/edit-attribute/useLogic"
import { PageProps } from "@/types/page"
import { Box } from "@mui/material"

const EditAttribute = ({ onClose, open }: PageProps) => {
    const { handlePreviousButton, t, handleSubmit, handleCancelButton, onSubmit, onErrors, loading, listAttributes } =
        useLogic()

    return (
        <EditAttributeContainer open={open} onClose={onClose}>
            <TitleWrapper
                data-testid="title"
                handlePreviousButton={handlePreviousButton}
                title={t("BUSINESSES.EDIT_ATTRIBUTES.TITLE")}
            />
            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                <Box className="inputs-container">
                    {listAttributes.map((item, key) => (
                        <AttributeAccordion item={item} key={item?.title} itemKey={key} t={t} />
                    ))}
                </Box>
                <FormAction handleCancel={handleCancelButton} isLoading={loading} isDisabled={loading} isUpdating />
            </form>
        </EditAttributeContainer>
    )
}

export default EditAttribute
