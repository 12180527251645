import Icon from "@/components/base/Icon"
import useClipboard from "@/hooks/useClipboard"
import { getScoreColor } from "@/utils"
import { Box, ListItemButton, Stack, Typography } from "@mui/material"
import { useMemo, useState } from "react"
import BusinessAddress from "../address"
import BusinessLogo from "../logo"
import env from "@/config/env"

const itemSx = (showError?: boolean) => ({
    alignItems: "center",
    ...(showError && { borderColor: "error.main", borderWidth: 2, borderStyle: "solid" }),
})
const errorSx = { display: "flex", gap: 1 }
const headSx = { display: "flex", justifyContent: "space-between", alignItems: "center" }

const ErrorMessage = ({ t }) => (
    <Box sx={errorSx} data-e2e="missing-mandatory">
        <Icon name="alert-triangle" sx={{ color: "error.main" }} />
        <Typography component={"span"} className="error">
            {t("BUSINESSES.SCORE.MISSING_INFO")}
        </Typography>
    </Box>
)

const BusinessListItem = ({ item, handleItemClick, isLocationNew, address, isGoogleConnected, t }) => {
    const [showCopyId, setShowCopyId] = useState(false)
    const { ButtonClipboard } = useClipboard()

    const { score, hasMissingMandatory } = useMemo(() => {
        const score = item?.locationState?.completionScore || 0
        const missingItems = Object.values(item?.locationState?.missingInformation || [])

        const mandatoryList = ["name", "country", "primary_category", "description", "address", "logo"]
        if (missingItems.includes("phone") && missingItems.includes("websiteUrl")) {
            mandatoryList.push("websiteUrl")
            mandatoryList.push("phone")
        }
        const hasMissingMandatory = missingItems.filter((item: string) => mandatoryList.includes(item)).length > 0
        return { mandatoryList, hasMissingMandatory, score }
    }, [item])

    return (
        <div
            style={{ zIndex: 0, position: "relative" }}
            onMouseOver={() => setShowCopyId(true)}
            onFocus={() => setShowCopyId(true)}
            onMouseLeave={() => setShowCopyId(false)}
            role="business-item-card"
        >
            <ListItemButton onClick={() => handleItemClick(item)} sx={itemSx(hasMissingMandatory)}>
                <BusinessLogo uid={item.uid} logo={item.logo} isNew={isLocationNew} />
                <Box className="content" data-e2e={`location-list-content`} id={item.uid} sx={{ width: "100%" }}>
                    <Box sx={headSx}>
                        <Typography component={"span"} variant="h2" className="name text-ellipsis">
                            {item.name}
                        </Typography>
                        <Typography
                            component={"span"}
                            sx={{ color: getScoreColor(score), fontSize: 20 }}
                            data-testid="score"
                            data-class={getScoreColor(score)}
                        >
                            {score}%
                        </Typography>
                    </Box>
                    <Stack flexDirection={"row"}>
                        {!hasMissingMandatory && (
                            <BusinessAddress item={item} address={address} isGoogleConnected={isGoogleConnected} />
                        )}
                        {hasMissingMandatory && <ErrorMessage t={t} />}
                    </Stack>
                </Box>
            </ListItemButton>
            {showCopyId && !env.IS_PROD && ButtonClipboard({ label: "Copy ID", value: item.uid })}
        </div>
    )
}
export default BusinessListItem
