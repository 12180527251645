import { EntitiesApi } from "@/api"
import LocationStore, { ILocationSetter, ILocationStore } from "@/store/location"
class LocationService {
    getState = (): ILocationStore & ILocationSetter => {
        return LocationStore.getState()
    }

    setState = (up: Partial<ILocationStore>): void => {
        const { update } = this.getState()
        update(up)
    }

    clearLocation = () => {
        this.setState({
            location: null,
            currentLocationId: "",
            primaryCategory: {},
            secondaryCategory: [],
        })
    }

    fetchLocationIdsByUserUid = async (uid: string) => {
        const response = await EntitiesApi.fetchListsEntities({
            page_number: 0,
            page_size: 1000,
            user_uid: uid,
            entity_type: "location",
            fields: "uid",
        })
        if (response?.data && response?.data.length) {
            const ids = response?.data.map((item: any) => {
                return item?.uid
            })
            return ids
        }
        return []
    }
}

export default new LocationService()
