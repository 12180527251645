import ListContainer from "@/components/base/page-container/list-container"
import TitleWrapper from "@/components/base/page-container/list-container/title-wrapper"
import { AlertModalCreationBusiness } from "@/components/business/alert-creation"
import BusinessList from "@/components/business/list"
import { SkeletonBusinessList } from "@/components/skeleton/business/list"
import useLogic from "@/hooks/businesses/list/useLogic"

const ListBusiness = () => {
    const {
        t,
        isAdminCompany,
        handleChangePage,
        metadata,
        setUiPageSession,
        paginatedItems,
        searchProps,
        setSearchProps,
        alert,
        isLoading,
        openAlert,
        hanldeModalAlert,
        handleCheckLicence,
        loadingCheckLicence,
    } = useLogic()

    return (
        <ListContainer>
            <TitleWrapper
                buttonText={t("BUSINESSES.CREATE_BUTTON")}
                onClickButton={() => {
                    handleCheckLicence()
                }}
                pageIcon="profile"
                title={t("BUSINESSES.TITLE")}
                showActionButton={isAdminCompany}
                alert={alert}
                withSearch={searchProps}
                setWithSearch={setSearchProps}
                loading={isLoading}
                loadingCheckLicence={loadingCheckLicence}
            />

            {isLoading && <SkeletonBusinessList />}
            {!isLoading && (
                <BusinessList list={paginatedItems} t={t} metadata={metadata} handleChangePage={handleChangePage} />
            )}
            <AlertModalCreationBusiness
                open={openAlert}
                onClose={() => hanldeModalAlert(false)}
                onNext={() => setUiPageSession({ showPage: "create" })}
            />
        </ListContainer>
    )
}

export default ListBusiness
