import useUiPageSession from "@/hooks/useUiPageSession"
import { Fragment } from "react"
import EditCompany from "./edit-company"
import ListCompany from "./list-company"
import { CompanyOverview } from "./overview-company"

const CompaniesPage = () => {
    const { handleDrawerClose, showPage, open } = useUiPageSession({
        title: "COMPANIES.TITLE_HEADER",
        page: "companies",
    })
    return (
        <Fragment>
            <ListCompany />
            {/* <CreateCompany open={showPage === "create" && open} onClose={handleDrawerClose} /> */}
            <EditCompany open={showPage === "edit" && open} onClose={handleDrawerClose} />
            <CompanyOverview open={showPage === "detail" && open} onClose={handleDrawerClose} />
        </Fragment>
    )
}

export default CompaniesPage
