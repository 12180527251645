import { TitleWrapper } from "@/components/base/title-wrapper"
import { OverviewContainer } from "@/components/base/page-container/overview-container"
import { PageProps } from "@/types/page"
import { CssTextField } from "@/components/base/CssTextField"
import useLogic from "@/hooks/users/overview/useLogic"
import { Paper, Stack } from "@mui/material"

const ShowDetail = ({ onClose, open }: PageProps) => {
    const { setStateUiPageSession, user, t, typeUser } = useLogic()

    return (
        <OverviewContainer open={open} onClose={onClose}>
            <TitleWrapper
                title={`${user?.firstname || ""} ${user?.lastname || ""}`}
                tooltipText={t("USERS.BACK_TOOLTIP")}
                handlePreviousButton={() => {
                    setStateUiPageSession({ showPage: "list", selectedItem: null })
                }}
            />
            <form>
                <Paper className="overview-user">
                    {user && (
                        <Stack gap={1}>
                            <CssTextField
                                data-testid="label-email"
                                label={t("LABEL.EMAIL")}
                                variant="standard"
                                defaultValue={user?.email || ""}
                                inputProps={{ readOnly: true }}
                                multiline
                            />
                            <CssTextField
                                data-testid="label-role"
                                label={t("USERS.ROLE_TITLE_CREATE")}
                                variant="standard"
                                multiline
                                defaultValue={`${typeUser} (${user?.entity_managed_name || ""})`}
                                inputProps={{ readOnly: true }}
                            />
                        </Stack>
                    )}
                </Paper>
            </form>
        </OverviewContainer>
    )
}

export default ShowDetail
