import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import InputEmojiCustom from "@/components/input-emoji"
import { DeleteModal } from "@/components/modal-delete"
import env from "@/config/env"
import useLogic from "@/hooks/reputation/item/useLogic"
import useWindowSize from "@/hooks/useWindowSize"
import UiStore from "@/store/ui"
import { checkDisplayOfDate, convertToStarArray, getIconStar } from "@/utils"
import { Chip, Divider, IconButton, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import useStyles, { menuBtnRight } from "../styles"

const ItemReputation = ({ data, handleDeleteChange }) => {
    const { classes } = useStyles()
    const { preferredLanguage } = UiStore()
    const [width] = useWindowSize()
    const {
        t,
        text,
        handleText,
        handleOnEnter,
        toggleMenu,
        open,
        handleClose,
        anchorEl,
        modify,
        handleModify,
        loading,
        maxLength,
        handleModalDelete,
        openModalDelete,
        openModalDeleteAvis,
        handleModalDeleteAvis,
        handleDeleteAvis,
        openDelete,
        toggleDeleteMenu,
        handleCloseDelete,
        anchorElDelete,
    } = useLogic(data?.id, data?.reply?.content || "", handleDeleteChange)

    const classAnswered = useMemo(() => {
        return data?.reply?.content ? "success.main" : "text.disabled"
    }, [data])

    const isMobile = useMemo(() => {
        return width < 786
    }, [width])

    const stars = useMemo(() => {
        const score = data?.rating || 0
        const scoreArray = convertToStarArray(score)
        return scoreArray
    }, [data?.rating])

    return (
        <Paper className="bloc" data-testid="reviews">
            <Stack className="section">
                <Stack className="section-header">
                    <Stack className="row-item fixed-left">
                        {/* <Icon name="google" data-e2e="google-icon" /> */}
                        {data?.platformLogo && <img width={24} src={data?.platformLogo} alt="logo platform" />}
                        <Typography fontSize={"14px"} color={"action.active"}>
                            {data?.locationName}
                        </Typography>
                    </Stack>
                    <Stack className="row-item">
                        {!isMobile && (
                            <Typography fontSize={"16px"} color={classAnswered} data-e2e="review-status">
                                {data?.reply?.content ? t("REPUTATION.ANSWERED") : t("REPUTATION.NOT_ANSWERED")}
                            </Typography>
                        )}
                        <Icon name="checkCircle" color={classAnswered} />
                        {data?.meta?.dilypse_id && (
                            // <CustomTooltip title={t("REPUTATION.REMOVE")}>
                            //     <IconButton className="trash" onClick={() => handleModalDeleteAvis(true)}>
                            //         <Icon name="trash" />
                            //     </IconButton>
                            // </CustomTooltip>
                            <Stack className="menu-right">
                                <IconButton onClick={toggleDeleteMenu} sx={{ padding: 0 }}>
                                    <Icon name="three-dots-horizontal" />
                                </IconButton>
                                <Menu
                                    elevation={6}
                                    id={"basic-menu"}
                                    open={openDelete}
                                    onClose={handleCloseDelete}
                                    anchorEl={anchorElDelete}
                                    sx={menuBtnRight}
                                >
                                    <MenuItem onClick={() => handleModalDeleteAvis(true)} className="remove clickable">
                                        <Icon name="delete-1" />
                                        <Typography fontSize={"16px"} color={"error.main"}>
                                            {t("REPUTATION.REMOVE")}
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Stack>
                        )}
                    </Stack>
                    <DeleteModal
                        open={openModalDeleteAvis}
                        onClose={() => handleModalDeleteAvis(false)}
                        title={t("REPUTATION.DELETE")}
                        description={t("REPUTATION.DELETE_ASK")}
                        loading={loading}
                        onDelete={() => handleDeleteAvis()}
                    />
                </Stack>
                <Stack className="section-content" gap={2}>
                    <Stack className="section-user">
                        <Stack className="section-name">
                            {data?.author?.name && !data?.author?.isAnonymous && (
                                <Typography className="name" variant="h2" fontSize={"20px"}>
                                    {data?.author?.name}
                                </Typography>
                            )}
                            {data?.author?.isAnonymous && (
                                <Typography className="name" variant="h2" fontSize={"20px"}>
                                    {t("REPUTATION.ANONYMOUS")}
                                </Typography>
                            )}
                            <Typography
                                fontSize={"12px"}
                                color={"action.active"}
                                sx={{ width: "50%" }}
                                textAlign="right"
                            >
                                {checkDisplayOfDate(data?.createdAt, preferredLanguage)}
                            </Typography>
                        </Stack>
                        {data?.meta?.dilypse_id && data.tag === "NEGATIVE" ? (
                            <Stack>
                                <Typography variant="caption" color="action.active" fontFamily={"Poppins Medium"}>
                                    {data.email}
                                </Typography>
                                <Typography variant="caption" color="info.main" fontFamily={"Poppins Medium"}>
                                    {t("CAMPAIGN.ONLY_VISIBLE_BY_YOU")}
                                </Typography>
                            </Stack>
                        ) : (
                            <Stack className="stars" data-e2e="review-stars">
                                {stars.map((i, k) => (
                                    <Icon key={k} name={getIconStar(i)} sx={{ color: "#FFC431" }} />
                                ))}
                            </Stack>
                        )}
                    </Stack>
                    <Stack>
                        <Typography sx={{ wordBreak: "break-word" }} fontSize={"16px"} data-e2e="review-content">
                            {data?.content}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack className="section-response">
                    {data?.reply?.content && !modify && (
                        <Stack className={classes.blocResponse}>
                            <Divider className="divider-left" orientation="vertical" flexItem />
                            <Stack width="100%" gap={1}>
                                <Stack width={"100%"}>
                                    <Stack className="section-title">
                                        <Stack flexDirection={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                            <Icon name="send" sx={{ color: "primary.main" }} />
                                            <Stack className="response-title">
                                                <Typography
                                                    className="name"
                                                    variant="caption"
                                                    fontSize={"16px"}
                                                    fontWeight="600"
                                                    fontFamily={"Poppins Medium"}
                                                >
                                                    {t("REPUTATION.ANSWER")}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        {data.tag !== "NEGATIVE" && (
                                            <Stack
                                                flexDirection={"row"}
                                                gap={1}
                                                alignItems={"center"}
                                                textAlign="right"
                                            >
                                                {data?.reply?.updatedAt && (
                                                    <Typography fontSize={"12px"} color={"action.active"} width="120px">
                                                        {checkDisplayOfDate(data?.reply?.updatedAt, preferredLanguage)}
                                                    </Typography>
                                                )}
                                                <Stack className="menu-right">
                                                    <IconButton onClick={toggleMenu}>
                                                        <Icon name="three-dots-horizontal" />
                                                    </IconButton>
                                                    <Menu
                                                        elevation={6}
                                                        id={"basic-menu"}
                                                        open={open}
                                                        onClose={handleClose}
                                                        anchorEl={anchorEl}
                                                        sx={menuBtnRight}
                                                    >
                                                        <MenuItem
                                                            onClick={() => handleModify(true)}
                                                            className="clickable"
                                                        >
                                                            <Icon
                                                                name="edit"
                                                                color="action.active"
                                                                className="edit-icon"
                                                            />
                                                            <Typography fontSize={"16px"} color={"action.active"}>
                                                                {t("REPUTATION.MODIFY")}
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => handleModalDelete(true)}
                                                            className="remove clickable"
                                                        >
                                                            <Icon name="delete-1" />
                                                            <Typography fontSize={"16px"} color={"error.main"}>
                                                                {t("REPUTATION.REMOVE")}
                                                            </Typography>
                                                        </MenuItem>
                                                    </Menu>
                                                </Stack>
                                            </Stack>
                                        )}
                                    </Stack>
                                </Stack>
                                <Stack>
                                    <Typography
                                        sx={{ wordBreak: "break-word", whiteSpace: "pre-line" }}
                                        fontSize={"16px"}
                                    >
                                        {data?.reply?.content}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                    {(modify || !data?.reply?.content) && (
                        <InputEmojiCustom
                            text={text}
                            setText={handleText}
                            handleSave={handleOnEnter}
                            handleCancel={() => handleModify(false)}
                            isModify={modify}
                            isLoading={loading}
                            maxLength={maxLength}
                        />
                    )}
                </Stack>
            </Stack>
            <DeleteModal
                open={openModalDelete}
                onClose={() => handleModalDelete(false)}
                title={t("REPUTATION.REMOVE_ANSWER")}
                description={t("REPUTATION.ASK")}
                loading={loading}
                onDelete={() => handleOnEnter(null)}
            />
        </Paper>
    )
}

export default ItemReputation
