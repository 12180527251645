import { Box, Button, Chip } from "@mui/material"
import { TFunction } from "i18next"
import { MouseEventHandler, useMemo } from "react"

interface AttributeChipsProps {
    expanded: boolean
    selected: any
    t: TFunction<"translation", undefined>
    onClick?: MouseEventHandler
}

const AttributeChips = ({ expanded, selected, t, onClick }: AttributeChipsProps) => {
    const show = useMemo(() => !expanded && selected.length > 0, [expanded, selected])

    return (
        <Box className="chips-wrapper" onClick={onClick}>
            {show && selected.map((selected, key) => <Chip color="primary" key={key} label={selected.display} />)}
            {show && <Button variant="text">{t("BUSINESSES.EDIT_ATTRIBUTES.EDIT")}</Button>}
        </Box>
    )
}

export default AttributeChips
