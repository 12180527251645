import { Skeleton } from "@mui/material"
import { CustomBlocSkeleton } from "./styles"

export default function BlocSkeleton({ totalSkeleton, width }) {
    return (
        <CustomBlocSkeleton
            inputCssProps={{
                $width: width,
            }}
        >
            {[...Array(totalSkeleton).keys()].map((x) => (
                <Skeleton key={x} animation={false} />
            ))}
        </CustomBlocSkeleton>
    )
}
