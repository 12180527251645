import { makeStyles } from "tss-react/mui"

const useStyles = ({ withSearch }) =>
    makeStyles()((theme) => ({
        wrapper: {
            display: "flex",
            gap: 16,
            flexDirection: "column",
            "@media (min-width: 768px)": {
                gap: 24,
                justifyContent: "space-between",
                "& button": {
                    margin: "auto",
                    marginRight: 0,
                },
            },
            "& .search-wrapper": {
                padding: "8px",
                "@media (min-width: 1024px)": {
                    padding: "8px 16px",
                },
            },
            "& .MuiListItem-root": {
                boxShadow: "none",
            },
            "& .action-btn": {
                padding: "8px 22px",
                width: withSearch ? "100%" : "fit-content",
                display: "flex",
                gap: 8,
                height: "fit-content",
                margin: "auto",
                marginRight: 0,
                "& span": {
                    letterSpacing: 0.46,
                    fontSize: 15,
                },
            },
            "& .title": {
                display: "flex",
                gap: 8,
                alignItems: "center",
                backgroundColor: theme.palette.background.default,
                "& h1": {
                    fontSize: 34,
                    wordBreak: "break-word",
                },

                "& > svg": {
                    backgroundColor: "rgba(1, 128, 255, 0.24)",
                    padding: 8,
                    borderRadius: 16,
                    color: theme.palette.primary.main,
                    width: 32,
                    height: 32,
                },
            },
        },
        MuiAccordion: {
            margin: "0px",
            padding: "0px",
            borderRadius: "16px",
            gap: "0px",
            backgroundColor: "#FDEDED",
            color: "#5F2120",
            position: "unset",
            paddingBottom: "15px",
            "& .title-accordion": {
                fontSize: "1rem",
                lineHeight: "1.5",
                textTransform: "initial",
                fontWeight: "500",
                color: "#5F2120",
                fontFamily: "Poppins Medium",
            },
            "& .subtitle-accordion": {
                fontSize: 14,
                color: "#5F2120",
                marginLeft: "40px",
            },
            "& .summary ": {
                height: "30px",
                marginTop: "20px",
                minHeight: "15px !important",
                "@media (min-width: 768px)": {
                    height: "15px",
                },
            },
            "& .detail": {
                paddingBottom: 0,
            },
            "&.Mui-expanded": {
                margin: 0,
            },
        },
    }))

export default useStyles

export const stackSx = {
    gap: 1,
    bgcolor: "common.white",
    p: 1,
    flexDirection: { xs: "column", sm: "row-reverse" },
}

export const actionBtnSx = {
    maxWidth: { sm: "fit-content" },
    width: { xs: "fit-content", sm: "100%" },
}

export const btnTextSx = { whiteSpace: "nowrap", color: "common.white" }

export const baseInputSx = {
    width: "100%",
    "@media (min-width: 1024px)": {
        maxWidth: 320,
    },
}

export const endAdornmentSx = ({ showLength }) => ({
    display: "flex",
    gap: 0.25,
    height: "56px",
    alignItems: "center",
    "& .filter-btn-group": {
        display: "flex",
        alignItems: "center",
        gap: "2px",
        ...(showLength && {
            bgcolor: "primary.light",
            color: "primary.main",
            p: "2px 8px 2px 8px",
            borderRadius: "14px",
        }),
    },
    "& svg": {
        color: showLength ? "primary.main" : "action.active",
    },
})

export const iconBtnSx = { p: 0, color: "inherit" }
