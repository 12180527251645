class BusinessService {
    resolveAttributes = ({ listAttributes, locationAttrSelect, attributIds }) => {
        let attributesTxt = ""
        let attributeUsers = {}

        if (listAttributes?.length > 0) {
            for (const attribute of listAttributes) {
                if (attribute && attribute.type !== "USER" && locationAttrSelect?.includes(attribute?.id)) {
                    let txt = ""
                    if (attribute.type === "RADIO" && attribute?.values) {
                        txt = (attribute.values || []).find((el) => el.value === attributIds[attribute.id])?.displayName
                    }

                    if (attribute.type === "CHECKBOX") {
                        const locationListAttr: any = attributIds[attribute.id]
                        locationListAttr.map((attr, index) => {
                            const displayName = attribute?.values?.find((el) => el.value === attr)?.displayName
                            if (displayName) txt += `${index > 0 ? "," : ""} ${displayName}`
                        })
                    }

                    if (attributesTxt === "") {
                        attributesTxt = txt
                    } else {
                        attributesTxt = attributesTxt + ", " + txt
                    }
                }
                if (attribute && attribute.type === "USER") {
                    let label = attribute?.label
                    let icon = attribute?.label?.toLowerCase()
                    if (attribute?.meta?.gmb_id === "attributes/url_appointment") {
                        label = "appointment"
                        icon = "calendarCheck"
                    } else {
                        label = attribute?.meta?.gmb_id?.replace("attributes/url_", "")
                    }

                    attributeUsers = {
                        ...attributeUsers,
                        [label.toLowerCase()]: {
                            value: locationAttrSelect?.includes(attribute?.id) ? attributIds[attribute?.id] : "",
                            icon: icon,
                            id: attribute?.id,
                        },
                    }
                }
            }
        }

        return { attributesTxt, attributeUsers }
    }
}

export default new BusinessService()
