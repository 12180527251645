import { DayItem } from "@/types/businesses"
import dayjs from "dayjs"

const parseRegularHours = (regularHours, showAa: boolean) => {
    const regularHourArray: DayItem[] = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
    ].map((name) => ({
        name,
        isOpen: false,
        isAvailableAllDay: false,
        times: [],
    }))
    let previousTime = {} as any
    for (const value of (regularHours || []) as any) {
        const regularHourIndex = regularHourArray.findIndex(
            (regularHour) => regularHour.name === value.openDay.toUpperCase()
        )
        if (regularHourIndex > -1) {
            const openTime = dayjs(value.openTime, "HH:mm")
            const closeTime = dayjs(value.closeTime, "HH:mm")
            const isTomorrow = openTime.isAfter(closeTime)
            // const shouldMergeTime = false

            // DATE 1: openTime1 01:00 - 02:00 closeTime1
            // DATE 2: openTime2 01:00 - 02:00 closeTime2

            // if openTime2 <= closeTime1 && openTime1 > closeTime1
            //===> ShouldMerge

            // else !shouldMerge

            // const compare = {
            //     openTime1: previousTime?.lastTime?.start,
            //     closeTime1: previousTime?.lastTime?.end,
            //     openTime2: openTime,
            //     closeTime2: closeTime,
            // }

            // const shouldMergeTime =
            //     (compare?.openTime2?.isBefore(compare?.closeTime1) ||
            //         compare?.openTime2?.isSame(compare?.closeTime1)) &&
            //     compare?.openTime1?.isAfter(compare?.closeTime1)

            const shouldMergeTime =
                previousTime?.isLastTimeTomorrow &&
                (previousTime?.lastTime?.end?.isAfter(openTime) || previousTime?.lastTime?.end?.isSame(openTime)) &&
                previousTime?.isOpen &&
                !previousTime?.isAvailableAllDay &&
                previousTime?.previousDay === value.openDay?.toUpperCase() &&
                !(
                    previousTime?.lastTime?.start?.isAfter(openTime) &&
                    previousTime?.lastTime?.start?.isBefore(closeTime)
                ) &&
                !isTomorrow

            if (previousTime && shouldMergeTime) {
                const newCloseTime = closeTime.isBefore(previousTime?.lastTime?.end)
                    ? previousTime?.lastTime?.end
                    : closeTime
                regularHourArray[previousTime.dayIndex].times[previousTime.previousLastTimeIndex] = {
                    ...previousTime.lastTime,
                    end: newCloseTime,
                    endTxt: newCloseTime.format(showAa ? "h:mm A" : "HH:mm"),
                }
            } else {
                regularHourArray[regularHourIndex].times.push({
                    start: openTime,
                    startTxt: openTime.format(showAa ? "h:mm A" : "HH:mm"),
                    end: closeTime,
                    endTxt: closeTime.format(showAa ? "h:mm A" : "HH:mm"),
                    tomorrow: openTime.isBefore(closeTime),
                    startValid: true,
                    endValid: true,
                })
            }

            regularHourArray[regularHourIndex].isAvailableAllDay =
                value.openTime === "00:00" && value.closeTime === "24:00"
            regularHourArray[regularHourIndex].isOpen =
                regularHourArray[regularHourIndex].times.length > 0 ||
                regularHourArray[regularHourIndex].isAvailableAllDay
        }
        const previousIndex = regularHourArray.findIndex(
            (regularHour) => regularHour?.name === value.openDay?.toUpperCase()
        )
        const lastTime = regularHourArray[regularHourIndex]?.times?.at(-1)
        previousTime = {
            lastTime,
            isLastTimeTomorrow: lastTime?.start.isAfter(lastTime?.end),
            previousDay: regularHourArray[previousIndex + 1]?.name,
            dayIndex: previousIndex,
            previousLastTimeIndex: regularHourArray[regularHourIndex]?.times?.length - 1,
            isOpen: regularHourArray[regularHourIndex].isOpen,
            isAvailableAllDay: regularHourArray[regularHourIndex].isAvailableAllDay,
        }
    }

    return [...regularHourArray]
}

export default parseRegularHours
