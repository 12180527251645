import { ResellerApi } from "@/api"
import { chartData } from "@/data/pie"
import useAuthFacebook from "@/hooks/oauth02/useAuthFacebook"
import useLocation from "@/hooks/useLocation"
import { BusinessService } from "@/services"
import useOverviewStore from "@/store/overview"
import useContactsStore from "@/store/overview/contacts"
import useLinksStore from "@/store/overview/links"
import useDescriptionStore from "@/store/overview/long-description"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { openGoogleAccount } from "@/utils/auth"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useBusinessLogic from "../useBusinessLogic"
dayjs.extend(customParseFormat)
interface ScrollToRef {
    ref: MutableRefObject<HTMLElement | null>
}
const useLogic = ({ businessId }) => {
    const navigate = useNavigate()
    const { navigateTo } = useBusinessLogic()
    const { profile } = UserSessionStore()
    const { t } = useTranslation()
    // const { regularHours, status } = useBusinessRegularHoursStore()
    // const { specialHours } = useBusinessSpecialHoursStore()

    const [attributeListTxt, setAttributeListTxt] = useState("")
    // const [regularHourList, setRegularHourList] = useState([])
    // const [specialHoursList, setSpecialHoursList] = useState([])
    const [mediasList, setMediasList] = useState([])
    const [totalMediaCount, setTotalMediaCount] = useState(0)
    const [openDisconnectFacebookModal, setOpenDisconnectFacebookModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const openFacebookMenu = Boolean(anchorEl)
    const mainInfoRef = useRef(null)
    const descriptionRef = useRef(null)
    const attributRef = useRef(null)
    const categoriesRef = useRef(null)
    const servicesRef = useRef(null)
    const serviceAreaRef = useRef(null)
    const regularHoursRef = useRef(null)
    const specialHoursRef = useRef(null)
    const linksRef = useRef(null)
    const contactRef = useRef(null)
    const mediaRef = useRef(null)
    const {
        location,
        authUrl,
        needReconnexion,
        isLoading,
        updateLocation,
        fetchAttribute,
        resetLocation,
        setCategories,
    } = useLocation("location-detail")
    const { websiteUrl, linksNetwork, update: updateLinks } = useLinksStore()
    const { update: updateContacts } = useContactsStore()
    const { update: updateDescription } = useDescriptionStore()
    const [series, setSeries] = useState([0, 0])
    const [missingItem, setMissingItem] = useState([])
    const [missings, setMissings] = useState([])
    const [chartDonutData, setChartDonutData] = useState(chartData)
    const scrollTo = (missing: ScrollToRef) => {
        if (missing?.ref?.current) {
            const isMobile = window.innerWidth < 768
            missing.ref.current.style.scrollMarginTop = isMobile ? "70px" : "20px"
            missing.ref.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    const { setLoading, loading } = useOverviewStore()

    const sections = useMemo(() => {
        return [
            {
                icon: "annotationInfo",
                id: "mainInfo",
                ref: mainInfoRef,
                items: ["name", "country", "address", "phone", "logo"],
                missing: 0,
                label: "USERS.MAIN_INFO",
            },
            {
                icon: "profile",
                id: "description",
                ref: descriptionRef,
                items: ["description"],
                missing: 0,
                label: "BUSINESSES.DESCRIPTION",
            },
            {
                icon: "bulletList",
                id: "attributs",
                ref: attributRef,
                items: ["attributeIds"],
                missing: 0,
                label: "BUSINESSES.ATTRIBUTE_TITLE",
            },
            {
                icon: "category",
                id: "categories",
                ref: categoriesRef,
                items: ["primary_category", "secondary_category"],
                missing: 0,
                label: "BUSINESSES.CATEGORIES",
            },
            {
                icon: "coins-hand",
                id: "services",
                ref: servicesRef,
                items: ["service"],
                missing: 0,
                label: "BUSINESSES.SERVICES",
            },
            {
                icon: "tag",
                id: "services-area",
                ref: serviceAreaRef,
                items: ["serviceArea"],
                missing: 0,
                label: "BUSINESSES.AREAS_SERVED",
            },
            {
                icon: "calendar",
                id: "regular-hours",
                ref: regularHoursRef,
                items: ["regularHours"],
                missing: 0,
                label: "BUSINESSES.SCHEDULE.STANDARD_OPENING",
            },
            {
                icon: "calendar",
                id: "special-hours",
                ref: specialHoursRef,
                items: ["specialHours"],
                missing: 0,
                label: "BUSINESSES.SCHEDULE.EXCEPTIONAL_OPENING",
            },
            {
                icon: "link",
                id: "links",
                ref: linksRef,
                items: ["websiteUrl", "urlAppointment", "socialNetwork"],
                missing: 0,
                label: "BUSINESSES.LINK",
            },
            {
                icon: "link",
                id: "contacts",
                ref: contactRef,
                items: ["additionalPhone", "email"],
                missing: 0,
                label: "BUSINESSES.EDIT_CONTACT.TITLE.OVERVIEW",
            },
            {
                icon: "image-placeholder",
                id: "medias",
                ref: mediaRef,
                items: ["media"],
                missing: 0,
                label: "MEDIA.TITLE",
            },
        ]
    }, [])

    const scoreMapping = useCallback(
        (missingItems = []) => {
            if (missingItems.includes) {
                return sections.map((section) => {
                    section.missing = section.items.filter((element) => missingItems.includes(element)).length
                    return section
                })
            }
        },
        [sections]
    )

    const { updateState: setUiPageSession, menuPage, showPage, refreshItems, updatePreviousItem } = UiPageSessionStore()
    const { openFacebookAccount, isLoggedFacebook } = useAuthFacebook({ location })

    // const showAa = useMemo(() => preferredLanguage?.toLowerCase() === "en", [preferredLanguage])

    const faceboookMenuHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseFacebookMenu = () => {
        setAnchorEl(null)
    }

    const disconnectFacebook = useCallback(() => {
        setOpenDisconnectFacebookModal(true)
    }, [])

    const onCloseDisconnectFacebook = useCallback(() => setOpenDisconnectFacebookModal(false), [])

    // useEffect(() => {
    //     if (regularHours?.length === 0) {
    //         setRegularHourList([])
    //         updatePreviousItem({ days: null, regularHours: null, isOpenWithoutHour: true })
    //     } else if (regularHours?.length > 0) {
    //         const newRegularHourArray = parseRegularHours(regularHours, showAa)
    //         updatePreviousItem({ days: cloneDeep(newRegularHourArray), regularHours: cloneDeep(newRegularHourArray) })
    //         setRegularHourList(cloneDeep(newRegularHourArray))
    //     }

    //     if (location && Object.keys(location).length > 0 && (!specialHours || specialHours?.length === 0)) {
    //         setSpecialHoursList([])
    //     }
    //     if (location && Object.keys(location)?.length > 0 && specialHours) {
    //         const newSpecialHourArray = parseSpecialHours(specialHours, showAa)
    //         updatePreviousItem({
    //             specialDays: sortDateException(cloneDeep(newSpecialHourArray)),
    //         })
    //         setSpecialHoursList(cloneDeep(newSpecialHourArray))
    //     }
    // }, [status, regularHours, specialHours, showAa])

    const getAttributes = useCallback(async () => {
        let listAttributes = []
        if (businessId && location?.companyId) {
            setLoading("attribute", true)
            listAttributes = (await fetchAttribute(businessId)) || []
            setLoading("attribute", false)
        }
        const locationAttrSelect = location?.attributIds ? Object.keys(location?.attributIds) : []
        return {
            listAttributes,
            locationAttrSelect,
        }
    }, [fetchAttribute, location?.attributIds, businessId, location?.companyId, setLoading])

    const processAttributes = useCallback(async () => {
        const { listAttributes, locationAttrSelect } = await getAttributes()

        if (location?.attributIds) {
            const { attributeUsers, attributesTxt } = BusinessService.resolveAttributes({
                listAttributes,
                locationAttrSelect,
                attributIds: location.attributIds,
            })

            updateLinks({ linksNetwork: attributeUsers })

            setAttributeListTxt(attributesTxt)
        }
    }, [location?.attributIds])

    const fetchLocationInfo = useCallback(
        async (uid = "", user_uid = "") => {
            setLoading("locationInfo", true)
            const response = await ResellerApi.fetchLocationInfo({ uid, user_uid })
            if (!response?.error) {
                fetchMedias(uid)
                updateLocation(response)
                updateLinks({ websiteUrl: response.websiteUrl })
                updateContacts({ email: response.email, additionalPhone: response.additionalPhone })
                updateDescription({ description: response.description })
                setCategories(response.categories)
                updatePreviousItem({ days: null })
            }
            setLoading("locationInfo", false)
        },
        [updateLocation]
    )

    const fetchMedias = useCallback(async (uid = "") => {
        setLoading("media", true)
        const response = await ResellerApi.getListMedia({ locationId: uid, perpage: 10 })
        if (!response?.error) {
            setMediasList([
                ...response.data.filter(
                    (media) => media.category === "" || ["ADDITIONAL", "INTERIOR", "EXTERIOR"].includes(media.category)
                ),
            ])
            setTotalMediaCount(response.metadata.total)
        }
        setLoading("media", false)
    }, [])

    const handlePreviousButton = useCallback(() => {
        navigateTo("list")
        setUiPageSession({ previousItem: null, refreshItems: true })
        setSeries([0, 0])
        setMissingItem([])

        const newData = {
            ...chartData,
            ...{
                fill: {
                    colors: ["fff", "#fff"],
                },
            },
        }
        setMediasList([])
        setMissings([])
        setChartDonutData(newData)
        resetLocation()
        navigate("/businesses")
    }, [])

    const handeleConnect = async (type) => {
        if (type === "facebook")
            openFacebookAccount({
                companyId: location.companyId,
                user_id: profile.uid,
                location_id: location.id,
            })
    }

    const handleConnectGoogle = () => {
        openGoogleAccount({
            companyId: location.companyId,
            user_id: profile.uid,
            gmb_access_id: location.gmbAccessId,
        })
    }

    useEffect(() => {
        processAttributes()
    }, [processAttributes])

    useEffect(() => {
        if (profile.uid && businessId && menuPage === "businesses") {
            if (refreshItems) {
                fetchLocationInfo(businessId, profile.uid)
                setUiPageSession({ refreshItems: false })
            }
        }
    }, [showPage, refreshItems, businessId, location])

    useEffect(() => {
        if (businessId) {
            setUiPageSession({ refreshItems: true })
        }
    }, [businessId, setUiPageSession])

    useEffect(() => {
        if (location?.locationState?.completionScore) {
            setMissingItem(location?.locationState?.missingInformation || [])
            if (location?.locationState?.missingInformation) {
                const missingScore = scoreMapping(Object.values(location?.locationState?.missingInformation) || [])
                setMissings(missingScore)
            }
            const completionScore = location?.locationState?.completionScore || 0
            setSeries([completionScore, 100 - completionScore])
            let color = ""
            if (completionScore <= 33) {
                color = "#F46259"
            } else if (completionScore > 33 && completionScore <= 66) {
                color = "#FFC431"
            } else {
                color = "#03CC6C"
            }
            const newData = {
                ...chartData,
                ...{
                    fill: {
                        colors: [color, "#fff"],
                    },
                },
            }
            setChartDonutData(newData)
        }
    }, [location?.locationState?.completionScore, location?.locationState?.missingInformation, scoreMapping])

    return {
        location,
        linksNetwork,
        authUrl,
        isLoggedFacebook,
        // regularHourList,
        // specialHoursList,
        websiteUrl,
        needReconnexion,
        isLoading,
        navigateTo,
        handlePreviousButton,
        t,
        handeleConnect,
        disconnectFacebook,
        openDisconnectFacebookModal,
        setOpenDisconnectFacebookModal,
        onCloseDisconnectFacebook,
        anchorEl,
        faceboookMenuHandler,
        handleCloseFacebookMenu,
        handleConnectGoogle,
        openFacebookMenu,
        mainInfoRef,
        descriptionRef,
        attributRef,
        categoriesRef,
        serviceAreaRef,
        servicesRef,
        regularHoursRef,
        specialHoursRef,
        linksRef,
        contactRef,
        mediaRef,
        chartDonutData,
        missingItem,
        missings,
        scrollTo,
        series,
        mediasList,
        totalMediaCount,
        profile,
        loading,
        attributeListTxt,
    }
}

export default useLogic
