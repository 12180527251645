import { useTranslation } from "react-i18next"

import useUiPageSession from "@/hooks/useUiPageSession"
import { LocationService, ReputationService } from "@/services"
import ReputationStore from "@/store/reputation"
import UserSessionStore from "@/store/user-session"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

const useLogic = () => {
    useUiPageSession({
        title: "REPUTATION.TITLE",
        page: "reputation",
    })
    const {
        update: updateReputation,
        reviews: items,
        payloadFilter,
        updatePayloadFilter,
        alreadyLoaded,
        loading,
        alert,
    } = ReputationStore()
    const { t } = useTranslation()
    const refBloc: any = useRef()
    const { profile } = UserSessionStore()
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)

    const scrollIntoView = () => {
        if (refBloc.current) {
            refBloc.current.style.scrollMargin = "200px"
            refBloc.current.scrollIntoView({ top: 0, behavior: "smooth" })
        }
    }

    const MESSAGE_TEXT = useMemo(() => {
        return {
            RECONNECT_GOOGLE_TITLE: t("BUSINESSES.RECONNECT_GOOGLE.TITLE"),
            RECONNECT_GOOGLE_WARNING: t("BUSINESSES.RECONNECT_GOOGLE.WARNING"),
        }
    }, [t])

    const [defaultIds, setDefaultIds] = useState([])

    const fetchAvis = useCallback(async () => {
        const ids = []
        if (!profile?.uid) {
            return
        }
        if (defaultIds?.length === 0) {
            updateReputation({ alreadyLoaded: true, loading: false })
            return
        }

        if (payloadFilter.locationsId?.length > 0) {
            ids.push(...payloadFilter.locationsId)
        } else {
            ids.push(...defaultIds)
        }
        if (payloadFilter.reply === null) {
            delete payloadFilter.reply
        }
        const response = await ReputationService.fetchAvis({
            ...payloadFilter,
            _page: page + 1,
            locationsId: ids,
        })
        const reputationUpdate = {
            loading: false,
            alreadyLoaded: true,
            reviews: response.data,
            alert: null,
        }
        scrollIntoView()
        if (response.metadata?.has_review_hidden) {
            reputationUpdate.alert = {
                title: MESSAGE_TEXT.RECONNECT_GOOGLE_TITLE,
                contents: MESSAGE_TEXT.RECONNECT_GOOGLE_WARNING,
            }
        }
        setTotal(response?.metadata?.total)
        updateReputation(reputationUpdate)
    }, [
        defaultIds,
        profile?.uid,
        payloadFilter,
        page,
        updateReputation,
        MESSAGE_TEXT.RECONNECT_GOOGLE_TITLE,
        MESSAGE_TEXT.RECONNECT_GOOGLE_WARNING,
    ])

    const handleSelectionChange = useCallback(
        (items) => {
            const filteredLocationIds = items?.map((item) => item.value) || []
            setPage(0)
            if (filteredLocationIds?.length > 0) {
                updatePayloadFilter({ _page: 0, locationsId: filteredLocationIds })
                updateReputation({
                    selectedBusinesses: items,
                })
            } else {
                updatePayloadFilter({ locationsId: [] })
                updateReputation({ selectedBusinesses: [] })
            }
        },
        [updatePayloadFilter, updateReputation]
    )

    const handleSelectionRateChange = useCallback(
        (items) => {
            let testNotAnswerd = false
            const itemFilter = []
            for (const item of items) {
                if (item.value != "6") {
                    itemFilter.push(Number(item.value))
                } else {
                    testNotAnswerd = true
                }
            }
            const filteredRating = itemFilter || []
            if (filteredRating) {
                updatePayloadFilter({ _page: 0, rating: filteredRating, reply: testNotAnswerd ? [] : null })
                updateReputation({
                    selectedRating: items,
                })
            } else {
                updatePayloadFilter({ rating: [] })
                updateReputation({ selectedRating: [] })
            }
        },
        [updatePayloadFilter, updateReputation]
    )

    const handleDeleteChange = useCallback(() => {
        updateReputation({ payloadFilter: { ...payloadFilter, rating: [] }, selectedRating: [] })
    }, [payloadFilter, updateReputation])

    useEffect(() => {
        fetchAvis()
    }, [fetchAvis])

    useEffect(() => {
        if (!alreadyLoaded) {
            updateReputation({ loading: true })
        }
    }, [alreadyLoaded, updateReputation])

    useEffect(() => {
        if (profile?.uid) {
            ;(async () => {
                const ids = await LocationService.fetchLocationIdsByUserUid(profile?.uid)
                setDefaultIds(ids)
            })()
        }
    }, [profile?.uid])

    return {
        t,
        total,
        refBloc,
        alert,
        handleSelectionChange,
        handleSelectionRateChange,
        handleDeleteChange,
        items,
        page,
        setPage,
        loading,
    }
}

export default useLogic
