import env from "@/config/env"
import { encodeBase64 } from "@/utils"
import { useCallback, useMemo } from "react"

interface FacebookInput {
    companyId: string
    user_id: string
    location_id: string
}

const useAuthFacebook = ({ location }) => {
    const isLoggedFacebook = useMemo(() => {
        if (location?.meta?.facebook_id) return true

        return false
    }, [location])

    const objectToQueryString = (obj) => {
        const params = new URLSearchParams()
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                params.append(key, obj[key])
            }
        }
        return params.toString()
    }

    const openFacebookAccount = useCallback(({ companyId, user_id, location_id }: FacebookInput) => {
        const b64 = encodeBase64(
            `companyId=${companyId}&user_id=${user_id}&location_id=${location_id}&cb=${window.location.origin}/businesses&auth_type=facebook`
        )
        const query = objectToQueryString({
            client_id: env.FACEBOOK_APP_ID,
            redirect_uri: `${env.AUTH_URL}`,
            response_type: "token",
            scope: env.FACEBOOK_SCOPE,
            state: `${b64}`,
        })

        window.location.href = "https://www.facebook.com/v18.0/dialog/oauth?" + query
    }, [])

    return {
        openFacebookAccount,
        isLoggedFacebook,
    }
}

export default useAuthFacebook
