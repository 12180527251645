import CheckBoxAutocomplete from "@/components/autocomplete/checkbox-autocomplete"
import BaseInput from "@/components/base/input"
import BaseSelect from "@/components/base/select"
import { MAX_INPUT } from "@/data/limiter"
import useLogic from "@/hooks/users/form/useLogic"
import { Box, Paper, Typography } from "@mui/material"
import { Controller } from "react-hook-form"
import FormAction from "../base/form-action"

const UserForm = ({ callApi, onCancelForm, isUpdate = false }) => {
    const {
        handleSubmit,
        onSubmit,
        register,
        control,
        handeCancelButton,
        MESSAGE_TEXT,
        mailError,
        setMailError,
        roles,
        isAutoEdit,
        getValues,
        roleSelect,
        listItemsAutocomplete,
        setValue,
        trigger,
        t,
        loading,
        profile,
        errors,
        isValid,
    } = useLogic({
        isUpdate,
        callApi,
        onCancelForm,
    })

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="create-user">
            <Box className="inputs-container container-row">
                <Paper className="inputs-wrapper">
                    <Typography component="h2">{MESSAGE_TEXT.MAIN_INFO}</Typography>
                    <Box>
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    max={MAX_INPUT.TEXT}
                                    showLabelStar
                                    data-testid="firstname"
                                    label={MESSAGE_TEXT.USER_FIRST_NAME}
                                    register={register("firstname")}
                                    required
                                    error={Boolean(errors?.firstname)}
                                    helperText={errors?.firstname?.message}
                                    {...field}
                                />
                            )}
                            control={control}
                            name="firstname"
                        />
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    max={MAX_INPUT.TEXT}
                                    showLabelStar
                                    data-testid="lastname"
                                    label={MESSAGE_TEXT.USER_LAST_NAME}
                                    register={register("lastname")}
                                    required
                                    error={Boolean(errors?.lastname)}
                                    helperText={errors?.lastname?.message}
                                    {...field}
                                />
                            )}
                            control={control}
                            name="lastname"
                        />
                        <Controller
                            render={({ field }) => (
                                <BaseInput
                                    max={MAX_INPUT.TEXT}
                                    showLabelStar
                                    label={MESSAGE_TEXT.USER_EMAIL}
                                    register={register("email")}
                                    error={!!mailError || Boolean(errors?.email)}
                                    data-testid="email"
                                    helperText={mailError || errors?.email?.message}
                                    type="email"
                                    required
                                    multiline
                                    disabled={isUpdate}
                                    onChange={() => {
                                        setMailError("")
                                    }}
                                    {...field}
                                />
                            )}
                            control={control}
                            name="email"
                        />
                    </Box>
                </Paper>
                <Paper className="inputs-wrapper">
                    <Typography component="h2">{MESSAGE_TEXT.ROLE_TITLE}</Typography>
                    <Box>
                        <BaseSelect
                            required
                            options={roles}
                            controlName="role"
                            label={MESSAGE_TEXT.ROLE}
                            control={control}
                            formStateError={Boolean(errors?.role)}
                            disabled={isAutoEdit}
                        />
                        {getValues()?.role && roleSelect && (
                            <CheckBoxAutocomplete
                                id={roleSelect === "admin_company" ? "user-admin-company" : "user-admin-location"}
                                options={
                                    !listItemsAutocomplete || listItemsAutocomplete.length === 0
                                        ? [
                                              {
                                                  label: t("COMPANIES.EMPTY_OPTION"),
                                                  value: "empty",
                                                  disabled: true,
                                              },
                                          ]
                                        : listItemsAutocomplete
                                }
                                required
                                setValue={setValue}
                                trigger={trigger}
                                name={"roleByAdminType"}
                                label={
                                    roleSelect === "admin_company"
                                        ? MESSAGE_TEXT.COMPANY_ROLE
                                        : MESSAGE_TEXT.BUSINESS_ROLE
                                }
                                control={control}
                                error={Boolean(errors.roleByAdminType)}
                                disabled={isAutoEdit}
                            />
                        )}
                    </Box>
                </Paper>
            </Box>
            <FormAction
                handleCancel={handeCancelButton}
                isDisabled={loading || !profile || !isValid}
                isLoading={loading}
                isUpdating={isUpdate}
            />
        </form>
    )
}

export default UserForm
