import usePhoneInputLogic from "@/hooks/phone-input/useLogic"
import { CampaignService } from "@/services"
import CampaignStore from "@/store/campaign"
import UserSessionStore from "@/store/user-session"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

const useLogic = ({ setValue }) => {
    const { profile } = UserSessionStore()
    const { pagination, contacts, credits, update, locations } = CampaignStore()
    const [options, setOptions] = useState<any[]>([])
    const [editItem, setEditItem] = useState<any>(null)
    const [currentItem, setCurrentItem] = useState<any>(null)
    const { isPhoneValid } = usePhoneInputLogic()
    const { t } = useTranslation()

    const handleChangePage = useCallback(
        (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            update({
                pagination: {
                    page_number: newPage,
                    rows: [...contacts].slice(
                        newPage * pagination.page_size,
                        newPage * pagination.page_size + pagination.page_size
                    ),
                    total_count: [...contacts].length,
                    page_size: 10,
                },
            })
        },
        [contacts]
    )

    const fetchBusinessList = useCallback(async () => {
        update({ loadingBusiness: true })
        const entities = await CampaignService.fetchBusinessEntities(profile?.uid, "location")
        update({ locations: entities?.locations || [], credits: [], loadingBusiness: false })
    }, [profile?.uid, setValue])

    const creditSms = useMemo(() => {
        return credits.find((credit) => credit.type === "sms_rb")?.remaining || 0
    }, [credits])

    const creditEmail = useMemo(() => {
        return credits.find((credit) => credit.type === "email_rb")?.remaining || 0
    }, [credits])

    const handleViewChange = useCallback((value: number) => {
        const reviewType = value === 1 ? "email" : "sms"
        setValue("type", reviewType)
        if (reviewType === "sms") {
            setValue("phone", "")
        } else {
            setValue("email", "")
        }
    }, [])

    const invalidPhone = useMemo(() => {
        if (currentItem) {
            return (
                /^\+\d{1,4}$/.test(currentItem[t("CAMPAIGN.PHONE_NUMBER")]) ||
                currentItem[t("CAMPAIGN.PHONE_NUMBER")] === "" ||
                !isPhoneValid(currentItem[t("CAMPAIGN.PHONE_NUMBER")])
            )
        }
        return true
    }, [currentItem])

    useEffect(() => {
        if (contacts?.length > 0) {
            update({
                pagination: {
                    page_number: pagination.page_number,
                    rows:
                        contacts?.length === 1
                            ? contacts
                            : [...contacts].slice(
                                  pagination.page_number * pagination.page_size,
                                  pagination.page_number * pagination.page_size + pagination.page_size
                              ),
                    total_count: [...contacts].length,
                    page_size: 10,
                },
            })
        }
    }, [contacts])

    useEffect(() => {
        if (!locations) {
            fetchBusinessList()
        } else {
            const mappedEntities = locations?.map((location) => ({
                label: location.name,
                value: location.uid,
            }))
            setOptions(mappedEntities)
            if (mappedEntities?.length === 1) {
                setValue("business", mappedEntities[0]?.value)
            }
        }
    }, [fetchBusinessList, locations])

    return {
        editItem,
        options,
        creditSms,
        creditEmail,
        handleChangePage,
        setEditItem,
        handleViewChange,
        invalidPhone,
        currentItem,
        setCurrentItem,
    }
}

export default useLogic
