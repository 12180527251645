import env from "@/config/env"
import Cookies from "js-cookie"
import { create } from "zustand"

export interface IResellerStore {
    resellerLogoDark: string
    resellerLogo: string
    resellerName: string
    resellerFavicon: string
    resellerUid: string
    resellerFetched: boolean
}

export interface IResellerSetter {
    updateState?: (update: Partial<IResellerStore>) => void
    flush?: () => void
}

const { resellerLogo, resellerLogoDark, resellerName, resellerFavicon, resellerUid } = JSON.parse(
    Cookies.get(env.COOKIE_NAME) ?? "{}"
)

const initalState = {
    resellerLogo,
    resellerLogoDark,
    resellerName,
    resellerFavicon,
    resellerUid,
    resellerFetched: false,
}

const ResellerStore = create<IResellerStore & IResellerSetter>((set) => ({
    ...initalState,
    updateState: (update) => set(() => ({ ...update })),
    flush: () => set(initalState),
}))

export default ResellerStore
