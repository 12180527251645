import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    container: {
        padding: 0,
        display: "flex",
        gap: 16,
        flexDirection: "column",
        maxWidth: "100%",
        "@media (min-width: 600px)": {
            padding: 0,
        },
        "@media (min-width: 768px)": {
            gap: 24,
            maxWidth: "100%",
            padding: 0,
        },
    },
    list: {
        padding: 0,
        display: "flex",
        flexDirection: "column",
        gap: 8,
        "@media (min-width: 1200px)": {
            display: "grid",
            gridTemplateColumns: "calc(50% - 8px) calc(50% - 8px)",
            gap: 16,
        },
        "& .MuiListItemButton-root": {
            "&:hover": {
                backgroundColor: theme.palette.common.white,
                boxShadow: "0px 4px 5px 0px rgba(0, 4, 31, 0.08), 0px 1px 10px 0px rgba(0, 4, 31, 0.08)",
            },
            "& .text-ellipsis": {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "100%",
                display: "block",
            },
            "& .text-address": {
                color: theme.palette.action.active,
            },
            "& .content": {
                overflow: "hidden",
                gap: 8,
                display: "flex",
                flexDirection: "column",
                "& .name": {
                    fontSize: 20,
                },
                "& .address": {
                    display: "flex",
                    gap: 8,
                    "& .eye": {
                        color: theme.palette.action.active,
                    },
                },
                "& .social": {
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    flexWrap: "nowrap",
                    "& .not-connected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        padding: "4px 8px 4px 4px",
                        display: "flex",
                        gap: 8,
                        alignItems: "center",
                        width: "fit-content",
                        borderRadius: 40,
                        "& span": {
                            color: theme.palette.common.white,
                        },
                    },
                    "& .reconnected": {
                        backgroundColor: theme.palette.error.main,
                    },
                },
            },
        },
    },
    pagination: {
        display: "flex",
        justifyContent: "flex-end",
        "& .MuiToolbar-root": {
            gap: 26,
        },
        "& .table-pagination-actions-wrapper": {
            display: "flex",
            "& button": {
                padding: 12,
            },
        },
    },
}))

export default useStyles
