import { DispatchrService } from "../"
import { ErrorResponse } from "../base"

interface Listings {
    location_id: string
    location_name: string
    location_logo: string
    platforms: Platforms[]
}

type PlatformStatus =
    | "LIVE"
    | "OPTED_OUT"
    | "UNVERIFIED"
    | "UNAVAILABLE"
    | "WAITING_ON_CUSTOMER"
    | "WAITING_VERIFICATION"
    | "WAITING_ON_YEXT"
    | "WAITING_ON_PUBLISHER"

interface Platforms {
    platformId: string
    status: PlatformStatus
}

class ListingsApi {
    fetchListings = async ({
        locationsId,
    }: {
        locationsId: string[]
    }): Promise<Listings[] & Partial<ErrorResponse>> => {
        return await DispatchrService.post(`/listing`, { locationsId })
    }
}

export default new ListingsApi()
