import useNotification from "@/hooks/useNotification"
import UiPageSessionStore from "@/store/ui-page-session"
import { BusinessItemType } from "@/types/businesses"
import dayjs from "dayjs"
import { useCallback, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { isGoogleConnected } from "@/utils"
import { useTranslation } from "react-i18next"

dayjs.extend(utc)
dayjs.extend(timezone)

const useLogic = ({ list }: { list: BusinessItemType[] }) => {
    const { updateState } = UiPageSessionStore()
    const { notif } = useNotification()
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleItemClick = useCallback((row: BusinessItemType) => {
        updateState({ showPage: "detail" })
        navigate(`/businesses/${row.uid}`)
    }, [])

    const isLocationNew = useCallback(
        (item: BusinessItemType) => {
            const now = dayjs.utc().format("YYYY-MM-DDTHH:mm:ss.msZ")
            const createdAt = dayjs.tz(item.createdAt).utc()

            return dayjs.tz(now).utc().diff(dayjs.tz(createdAt).utc(), "minute") <= 1440
        },
        [list]
    )

    useEffect(() => {
        if (list?.length > 0) {
            if (searchParams.get("auth_type") === "google") {
                if (searchParams.get("created")) {
                    notif({
                        message: t("BUSINESSES.AUTH.GOOGLE_AUTH_SUCCESS", { created: searchParams.get("created") }),
                        type: "SUCCESS",
                    })
                }
                if (searchParams.get("updated")) {
                    notif({
                        message: t("BUSINESSES.RECONNECT_GOOGLE.SUCCESS"),
                        type: "SUCCESS",
                    })
                }
                if (searchParams.get("failed")) {
                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                }
            }
            if (searchParams.get("auth_type") === "facebook" && searchParams.get("status") === "success") {
                notif({
                    message: t("BUSINESSES.AUTH.FACEBOOK_AUTH_SUCCESS"),
                    type: "SUCCESS",
                })
            }
            for (const key of searchParams.keys()) {
                searchParams.delete(key)
            }
            setSearchParams(searchParams)
        }
    }, [searchParams, list])

    return { isGoogleConnected, handleItemClick, isLocationNew }
}

export default useLogic
