import { EntitiesApi, ResellerApi } from "@/api"
import { useDebounce } from "@/hooks/useDebounce"
import useNotification from "@/hooks/useNotification"
import { CookiesService } from "@/services"
import useOverviewStore from "@/store/overview"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { decode64AndParse } from "@/utils"
import { paginate } from "@/utils/paginate"
import deburr from "lodash.deburr"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

export type SearchPropsType = {
    inputLabel: string
    popoverTitle: string
    checkboxList: { label: string; name: string; checked: boolean; count: number }[]
    textSearch?: string
}

const useLogic = () => {
    const { profile, role } = UserSessionStore()
    const [isLoading, setIsLoading] = useState(false)
    const [loadingCheckLicence, setLoadingCheckLicence] = useState(false)
    const [isAdminCompany, setIsAdminCompany] = useState<boolean>(false)
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const [alert, setAlert] = useState<any>()
    const {
        updateState: setUiPageSession,
        refreshItems,
        paginatedItems,
        items,
        menuPage,
        showPage,
    } = UiPageSessionStore()
    const { t } = useTranslation()
    const { notif } = useNotification()
    const [searchParams, setSearchParams] = useSearchParams()
    const [metadata, setMetadata] = useState({
        page_size: 0,
        page_number: 0,
        total_count: 0,
    })

    const MESSAGE_TEXT = useMemo(() => {
        return {
            BUSINESS_CREATE_FAILED: t("BUSINESSES.CREATE.SAME_NAME"),
            BUSINESS_CREATE_SUCCESS: t("BUSINESSES.CREATE.SUCCESS"),
            BUSINESSES_SCORE_TITLE: t("BUSINESSES.SCORE.TITLE"),
        }
    }, [t])

    const [searchProps, setSearchProps] = useState<SearchPropsType>({
        inputLabel: "LISTINGS.SEARCH",
        popoverTitle: "BUSINESSES.SCORE.TITLE",
        checkboxList: [
            { label: "BUSINESSES.MISSING_INFO", name: "missing", checked: false, count: 0 },
            { label: "BUSINESSES.WEAK", name: "weak", checked: false, count: 0 },
            { label: "BUSINESSES.MEDIUM", name: "medium", checked: false, count: 0 },
            { label: "BUSINESSES.STRONG", name: "strong", checked: false, count: 0 },
        ],
        textSearch: "",
    })

    const { resetLoading } = useOverviewStore()

    const handleChangePage = useCallback((_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setMetadata((prev) => ({
            ...prev,
            page_number: newPage,
        }))
    }, [])

    const hanldeModalAlert = (value: boolean) => {
        setOpenAlert(value)
    }

    const refreshListBusinesses = useCallback(async () => {
        setIsLoading(true)
        const entity_type: any = "location"
        const response = await EntitiesApi.fetchListsEntities({
            page_number: 0,
            page_size: 0,
            user_uid: profile.uid,
            entity_type,
        })
        if (response?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else if (response?.locations) {
            const paginated = paginate([...response.locations], 1)
            setAlert(null)
            for (const location of response?.locations || []) {
                if (!!location.locationState?.locationNotFound || !!location.locationState?.needsReconnection) {
                    setAlert({
                        title: t("BUSINESSES.RECONNECT_GOOGLE.TITLE"),
                        contents: t("BUSINESSES.RECONNECT_GOOGLE.WARNING"),
                    })
                    break
                }
            }
            setMetadata((prev) => ({
                ...prev,
                total_count: paginated.total,
                page_size: paginated.totalPages,
            }))
            setUiPageSession({
                items: [...response.locations],
                paginatedItems: paginated.items,
            })
        }
        setIsLoading(false)
    }, [profile.uid, notif, t, setUiPageSession])

    const filteredItems = useMemo(() => {
        const data = {
            loaded: false,
            weak: [],
            medium: [],
            strong: [],
            missing: [],
            all: [],
        }
        for (const item of items || []) {
            const score = item?.locationState?.completionScore || 0
            const missingItems = Object.values(item?.locationState?.missingInformation || [])
            const mandatoryList = ["name", "country", "primary_category", "logo"]
            if (missingItems.includes("phone") && missingItems.includes("websiteUrl")) {
                mandatoryList.push("websiteUrl")
                mandatoryList.push("phone")
            }
            const hasMissingMandatory = missingItems.filter((item: string) => mandatoryList.includes(item)).length > 0
            if (score <= 33) {
                data.weak.push(item)
            } else if (score <= 66) {
                data.medium.push(item)
            } else if (score > 66) {
                data.strong.push(item)
            }
            if (hasMissingMandatory) {
                data.missing.push(item)
            }
            data.all.push(item)
        }
        data.loaded = true
        return data
    }, [items])

    const textFilterValue = useDebounce(searchProps?.textSearch, 100)

    useEffect(() => {
        const onboarding = searchParams.get("onboarding")
        if (onboarding) {
            if (onboarding === "1") {
                notif({ message: MESSAGE_TEXT.BUSINESS_CREATE_SUCCESS, type: "SUCCESS" })
            }
            searchParams.delete("onboarding")
            setSearchParams(searchParams)
        }
    }, [MESSAGE_TEXT, searchParams, notif, setSearchParams])

    useEffect(() => {
        if (profile.uid && role && menuPage === "businesses") {
            const roleList: any = decode64AndParse(role)
            if (
                roleList &&
                (roleList.includes("ROLE_ADMIN_LOCATION") || roleList.includes("ROLE_ADMIN_ESTABLISHMENT"))
            ) {
                setIsAdminCompany(false)
            }
            if (
                roleList &&
                roleList.includes("ROLE_ADMIN_COMPANY") &&
                !roleList.includes("ROLE_ADMIN_LOCATION") &&
                !roleList.includes("ROLE_ADMIN_ESTABLISHMENT")
            ) {
                setIsAdminCompany(true)
            }
        }
    }, [profile?.uid, role, menuPage])

    useEffect(() => {
        if (filteredItems?.loaded && profile.uid && menuPage === "businesses") {
            const dataSource = []
            let allUnchecked = true
            for (const entry of searchProps.checkboxList) {
                if (entry.checked) {
                    dataSource.push(...filteredItems[entry.name])
                    allUnchecked = false
                }
            }
            const page_number = metadata?.page_number ? metadata?.page_number + 1 : 1
            const uniqueSource = [...new Map(dataSource.map((item) => [item.uid, item])).values()]
            const source = allUnchecked ? filteredItems.all : uniqueSource
            const filtered = source.filter((item) => {
                if (textFilterValue) {
                    return deburr(item.name?.toLowerCase())?.indexOf(deburr(textFilterValue?.toLowerCase())) > -1
                }
                return true
            })
            const paginated = paginate(filtered, page_number)
            setMetadata((prev) => ({
                ...prev,
                total_count: paginated.total,
                page_size: paginated.totalPages,
            }))
            setUiPageSession({ paginatedItems: paginated.items })
        }
    }, [
        searchProps?.checkboxList,
        filteredItems,
        setUiPageSession,
        profile.uid,
        menuPage,
        metadata?.page_number,
        textFilterValue,
    ])

    useEffect(() => {
        setMetadata((current) => ({
            ...current,
            page_number: 0,
        }))
    }, [textFilterValue, searchProps?.checkboxList])

    useEffect(() => {
        setSearchProps((current) => ({
            ...current,
            checkboxList: current.checkboxList.map((list) => ({
                ...list,
                count: filteredItems[list.name]?.length,
            })),
        }))
    }, [filteredItems])

    useEffect(() => {
        setUiPageSession({ refreshItems: true })
    }, [setUiPageSession])

    useEffect(() => {
        if (refreshItems && menuPage === "businesses" && showPage === "list") {
            resetLoading()
            refreshListBusinesses()
            setUiPageSession({ refreshItems: false })
        }
    }, [refreshListBusinesses, refreshItems, menuPage, showPage, setUiPageSession])

    const handleCheckLicence = useCallback(async () => {
        setLoadingCheckLicence(true)
        const res = await ResellerApi.checkLicence({
            uid: profile.uid,
            reseller_uid: CookiesService.get("resellerUid"),
        })
        if (res?.has_price) {
            hanldeModalAlert(true)
        } else {
            setUiPageSession({ showPage: "create" })
        }
        setLoadingCheckLicence(false)
    }, [profile?.uid])

    return {
        t,
        isAdminCompany,
        handleChangePage,
        metadata,
        setUiPageSession,
        paginatedItems,
        searchProps,
        setSearchProps,
        alert,
        isLoading,
        hanldeModalAlert,
        openAlert,
        handleCheckLicence,
        loadingCheckLicence,
    }
}

export default useLogic
