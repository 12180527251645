import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useLinksStore from "@/store/overview/links"
import UiPageSessionStore from "@/store/ui-page-session"
import { EditLinkFormValues } from "@/types/businesses"
import { isValidURL, isValidURLSocialNetwork } from "@/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"

const defaultSocialNetwork: any = [
    { title: "FACEBOOK", name: "facebookUrl", placeholder: "https://www.facebook.com/", type: ["facebook.com"] },
    { title: "INSTAGRAM", name: "instagramUrl", placeholder: "https://www.instagram.com/", type: ["instagram.com"] },
    {
        title: "YOUTUBE",
        name: "youtubeUrl",
        placeholder: "https://www.youtube.com/",
        type: ["youtube.com/@", "youtube.com/channel/"],
    },
    { title: "LINKEDIN", name: "linkedinUrl", placeholder: "https://www.linkedin.com/", type: ["linkedin.com/"] },
    { title: "TWITTER", name: "twitterUrl", placeholder: "https://twitter.com/", type: ["twitter.com"] },
    { title: "TIKTOK", name: "tiktokUrl", placeholder: "https://www.tiktok.com/@", type: ["tiktok.com/@"] },
    {
        title: "PINTEREST",
        name: "pinterestUrl",
        placeholder: "https://www.pinterest.com/",
        type: ["pinterest.com/", "pinterest.fr"],
    },
]

const defaultValidity = {
    websiteUrl: false,
    appointmentUrl: false,
    facebookUrl: false,
    instagramUrl: false,
    youtubeUrl: false,
    twitterUrl: false,
    linkedinUrl: false,
    tiktokUrl: false,
    pinterestUrl: false,
}

const useLogic = ({ mandatoryCheck }) => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const [showAdornment, setShowAdornment] = useState(defaultValidity)
    const { location, updateLocation } = useLocation()
    const { linksNetwork, websiteUrl, update } = useLinksStore()
    const [loading, setLoading] = useState(false)
    const [valueChanged, setValueChanged] = useState(false)
    const { notif } = useNotification()
    const [urlRequired, setUrlRequired] = useState(false)
    const [isUrlInvalid, setUrlInvalid] = useState(defaultValidity)
    const { previousItem, updatePreviousItem, updateState: setUiPageSession } = UiPageSessionStore()

    const schema = useMemo(() => {
        return z.object({
            websiteUrl: location && location?.phone ? z.string().optional() : z.string(),
            facebookUrl: z.string().optional(),
            instagramUrl: z.string().optional(),
            youtubeUrl: z.string().optional(),
            twitterUrl: z.string().optional(),
            linkedinUrl: z.string().optional(),
            tiktokUrl: z.string().optional(),
            pinterestUrl: z.string().optional(),
            appointmentUrl: z.string().optional(),
        })
    }, [location])

    const defaultValue = useMemo(() => {
        return {
            websiteUrl: previousItem?.websiteUrl ?? websiteUrl,
            facebookUrl: previousItem?.facebookUrl ?? linksNetwork["facebook"]?.value,
            instagramUrl: previousItem?.instagramUrl ?? linksNetwork["instagram"]?.value,
            youtubeUrl: previousItem?.youtubeUrl ?? linksNetwork["youtube"]?.value,
            twitterUrl: previousItem?.twitterUrl ?? linksNetwork["twitter"]?.value,
            linkedinUrl: previousItem?.linkedinUrl ?? linksNetwork["linkedin"]?.value,
            tiktokUrl: previousItem?.tiktokUrl ?? linksNetwork["tiktok"]?.value,
            pinterestUrl: previousItem?.pinterestUrl ?? linksNetwork["pinterest"]?.value,
            appointmentUrl: previousItem?.appointmentUrl ?? linksNetwork["appointment"]?.value,
        }
    }, [location, linksNetwork, previousItem])

    const {
        control,
        setValue,
        watch,
        handleSubmit,
        getValues,
        formState: { isSubmitting },
    } = useForm<EditLinkFormValues>({
        defaultValues: defaultValue,
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const errorLinks = useMemo(() => {
        const total = []
        Object.keys(isUrlInvalid).map((media) => isUrlInvalid[media] && total.push(media))
        return total
    }, [isUrlInvalid])

    const checkValidityForm = ({ location, data, defaultSocialNetwork }) => {
        let valid = true
        if (!location?.phone && !data.websiteUrl) {
            setUrlRequired(true)
            setLoading(false)
            valid = false
        }

        if (!(location?.phone && data.websiteUrl === "")) {
            if (!isValidURL(data.websiteUrl)) {
                setUrlInvalid((prev) => ({ ...prev, websiteUrl: true }))
                setLoading(false)
                valid = false
            }
        }

        if (data.appointmentUrl && data.appointmentUrl !== "") {
            if (!isValidURL(data.appointmentUrl)) {
                setUrlInvalid((prev) => ({ ...prev, appointmentUrl: true }))
                setLoading(false)
                valid = false
            }
        }

        for (const link of defaultSocialNetwork) {
            if (
                data[link.name] &&
                (!isValidURL(data[link.name]) || !isValidURLSocialNetwork(data[link.name], link.type))
            ) {
                setUrlInvalid((prev) => ({ ...prev, [link.name]: true }))
                setLoading(false)
                valid = false
            }
        }
        return valid
    }

    const onSubmit: SubmitHandler<any> = async (data: EditLinkFormValues) => {
        setLoading(true)
        setValueChanged(false)
        setUrlInvalid(defaultValidity)
        setUrlRequired(false)
        const body = { ...location }
        body.websiteUrl = data.websiteUrl
        if (checkValidityForm({ location, data, defaultSocialNetwork })) {
            body["attributIds"] = {
                ...body["attributIds"],
                [linksNetwork["facebook"]?.id]: data.facebookUrl,
                [linksNetwork["linkedin"]?.id]: data.linkedinUrl,
                [linksNetwork["instagram"]?.id]: data.instagramUrl,
                [linksNetwork["youtube"]?.id]: data.youtubeUrl,
                [linksNetwork["twitter"]?.id]: data.twitterUrl,
                [linksNetwork["tiktok"]?.id]: data.tiktokUrl,
                [linksNetwork["pinterest"]?.id]: data.pinterestUrl,
                [linksNetwork["appointment"]?.id]: data.appointmentUrl,
            }

            const response = await ResellerApi.updateLocation({ ...body })
            if (response?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else {
                notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
                navigateTo("detail")
                setUrlRequired(false)
                update({ websiteUrl: response.websiteUrl })
                updatePreviousItem(watch())
                setUiPageSession({ refreshItems: true })
                updateLocation(response)
            }
        }
        setLoading(false)
    }

    const handleCancelButton = useCallback(() => {
        setValueChanged(false)
        updatePreviousItem({
            websiteUrl: null,
            facebookUrl: null,
            instagramUrl: null,
            youtubeUrl: null,
            twitterUrl: null,
            linkedinUrl: null,
            tiktokUrl: null,
            pinterestUrl: null,
            appointmentUrl: null,
        })
        setUrlInvalid(defaultValidity)
        setUrlRequired(false)
        navigateTo("detail")
    }, [location])

    const handlePreviousButton = useCallback(() => {
        updatePreviousItem({ ...watch() })
        setValueChanged(false)
        setUrlRequired(false)
        navigateTo("detail")
    }, [])

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        if (errors.websiteUrl) {
            setUrlRequired(true)
        }
    }

    useEffect(() => {
        if (defaultValue) {
            setValue("websiteUrl", defaultValue?.websiteUrl || "")
            setValue("facebookUrl", defaultValue?.facebookUrl)
            setValue("linkedinUrl", defaultValue?.linkedinUrl)
            setValue("youtubeUrl", defaultValue?.youtubeUrl)
            setValue("twitterUrl", defaultValue?.twitterUrl)
            setValue("tiktokUrl", defaultValue?.tiktokUrl)
            setValue("pinterestUrl", defaultValue?.pinterestUrl)
            setValue("instagramUrl", defaultValue?.instagramUrl)
            setValue("appointmentUrl", defaultValue?.appointmentUrl)
        }
        if (mandatoryCheck) {
            setUrlRequired(true)
        } else {
            setUrlRequired(false)
        }
    }, [defaultValue, mandatoryCheck])

    return {
        location,
        watch,
        setUrlRequired,
        navigateTo,
        handleSubmit,
        handleCancelButton,
        onSubmit,
        onErrors,
        setShowAdornment,
        handlePreviousButton,
        getValues,
        t,
        urlRequired,
        isUrlInvalid,
        control,
        loading,
        showAdornment,
        defaultSocialNetwork,
        setValueChanged,
        isSubmitting,
        valueChanged,
        linksNetwork,
        errorLinks,
    }
}

export default useLogic
