import { Stack } from "@mui/material"
import useStyles from "../styles"
import EmailEdit from "../view/EmailEdit"
import SmsEdit from "../view/SmsEdit"

const SecondStepCampaign = ({
    hasModel,
    listReviews,
    type,
    onChange,
    onCloseReviews,
    setValue,
    atValues,
    data,
    register,
    defaultValue,
    feedback,
    updateFeedback,
    hasNewUrl,
}) => {
    const { classes } = useStyles()

    return (
        <Stack className={classes.stepBloc} gap={2}>
            {type === "email" && (
                <EmailEdit
                    onChange={onChange}
                    onCloseReviews={onCloseReviews}
                    register={register}
                    setValue={setValue}
                    hasModel={hasModel}
                    listReviews={listReviews}
                    atValues={atValues}
                    data={data}
                    feedback={feedback}
                    defaultValue={defaultValue}
                    handleFeedback={updateFeedback}
                    hasNewUrl={hasNewUrl}
                />
            )}
            {type === "sms" && (
                <SmsEdit
                    onChange={onChange}
                    onCloseReviews={onCloseReviews}
                    atValues={atValues}
                    setValue={setValue}
                    hasModel={hasModel}
                    listReviews={listReviews}
                    defaultValue={defaultValue}
                    feedback={feedback}
                    handleFeedback={updateFeedback}
                    hasNewUrl={hasNewUrl}
                    data={data}
                />
            )}
        </Stack>
    )
}

export default SecondStepCampaign
