import { FetchKpiInput } from "@/api/statistics"
import { StatisticLocalPresenceService, LocationService } from "@/services"
import KpiLocalPresenceStore from "@/store/kpi-local-presence"
import UserSessionStore from "@/store/user-session"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import useUiPageSession from "../useUiPageSession"

const useLogic = () => {
    const {
        graph,
        kpi,
        payloadFilter,
        update,
        selectedBusinesses,
        selectedPeriod,
        loading,
        updatePayload,
        updatePeriod,
    } = KpiLocalPresenceStore()
    const [keyWordsList, setKeyWordsList] = useState<any>()

    const { profile } = UserSessionStore()

    useUiPageSession({
        title: "LOCAL_PRESENCE.TITLE_PERFORMANCE",
        page: "local_presence",
    })

    const { t } = useTranslation()

    const handleSelectionChange = useCallback((items) => {
        const filteredLocationIds = items?.map((item) => item.value) || []
        if (filteredLocationIds?.length > 0) {
            update({
                selectedBusinesses: items,
            })
            updatePayload({ locationsId: filteredLocationIds })
        } else {
            updatePayload({ locationsId: [] })
            update({ selectedBusinesses: [] })
        }
    }, [])
    const handleSelectionPeriodChange = useCallback((item) => {
        let type = "year"
        if (item.value === 1) {
            type = "month"
        } else if (item.value === 2) {
            type = "quarter"
        }
        updatePayload({ type: type })
        updatePeriod(item)
    }, [])

    const defaultIds = useMemo(async () => {
        if (profile?.uid) {
            return await LocationService.fetchLocationIdsByUserUid(profile?.uid)
        }
        return []
    }, [profile?.uid])

    const fetchKpi = useCallback(
        async (filterParams: Partial<FetchKpiInput>) => {
            update({ loading: true })

            const ids = payloadFilter.locationsId.length > 0 ? payloadFilter.locationsId : await defaultIds
            if (ids) {
                const locationsId = filterParams?.locationsId?.length > 0 ? filterParams.locationsId : ids
                const response = await StatisticLocalPresenceService.fetchKpi({
                    ...payloadFilter,
                    ...filterParams,
                    locationsId,
                })
                update({ graph: response.graph, kpi: response.kpi })

                if (response?.keywords) {
                    const keyWords = response.keywords.map((item: any, index) => {
                        return {
                            id: index + 1,
                            ...item,
                        }
                    })
                    setKeyWordsList(keyWords)
                }
            }

            update({ loading: false })
        },
        [payloadFilter, defaultIds]
    )

    useEffect(() => {
        fetchKpi({})
    }, [fetchKpi])

    const graphs = useMemo(() => {
        if (graph)
            return {
                views: StatisticLocalPresenceService.resolveGraphsViews(graph, payloadFilter.type),
                clicks: StatisticLocalPresenceService.resolveGraphsClicks(graph, payloadFilter.type),
                calls: StatisticLocalPresenceService.resolveGraphsCalls(graph, payloadFilter.type),
            }
        return {
            views: {},
            clicks: {},
            calls: {},
        }
    }, [graph, payloadFilter.type])

    return {
        t,
        handleSelectionChange,
        handleSelectionPeriodChange,
        kpi,
        keyWordsList,
        profile,
        graph,
        payloadFilter,
        graphs,
        loading,
        selectedBusinesses,
        selectedPeriod,
    }
}

export default useLogic
