import { ApiService, UserApi } from "@/api"
import { UserLoginResponse } from "@/api/user"
import { CookiesService, ResellerService } from "@/services"
import CampaignStore from "@/store/campaign"
import KpiLocalPresenceStore from "@/store/kpi-local-presence"
import KpiReputationStore from "@/store/kpi-reputation"
import LocationStore from "@/store/location"
import useOverviewStore from "@/store/overview"
import ReputationStore from "@/store/reputation"
import ResellerStore from "@/store/reseller"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore, { IUserSessionSetter, IUserSessionStore } from "@/store/user-session"

interface LoginReturn {
    success: boolean
    error?: string
}

class UserSessionService {
    clearSession = () => {
        CookiesService.flush()
        ResellerStore.getState().updateState({ resellerFetched: false })
    }

    setUserSession = (loginResponse: UserLoginResponse) => {
        const expandGoogleBanner = CookiesService.get("expandGoogleBanner") ?? true
        CookiesService.set({
            firstname: loginResponse?.user?.firstname,
            lastname: loginResponse?.user?.lastname,
            role: loginResponse?.user?.role,
            user_uid: loginResponse?.user?.uid,
            smsSender: loginResponse?.vendor?.smsSender,
            feedbackUrl: loginResponse?.metadata?.linkUrl,
            emailSpotconnect: loginResponse?.vendor?.emailSpotconnect,
            email: loginResponse?.user?.email,
            token: loginResponse?.tokens?.token,
            refreshToken: loginResponse?.tokens?.refresh_token,
            resellerUid: loginResponse?.vendor?.uid,
            expandGoogleBanner,
        })
        ResellerService.setState({ resellerUid: loginResponse?.vendor?.uid })
        ApiService.setToken(loginResponse?.tokens?.token, loginResponse?.tokens?.refresh_token)
    }

    login = async (
        emailOrClientId: string,
        passwordOrCredential: string,
        authType = "default"
    ): Promise<LoginReturn> => {
        const loginResponse = await UserApi.connectUser(emailOrClientId, passwordOrCredential, authType)
        if (loginResponse?.error) {
            this.clearSession()

            return {
                success: false,
                error: loginResponse?.error === undefined ? "SYSTEM_ERROR.INVALID_REQUEST" : loginResponse?.error,
            }
        } else if (loginResponse?.user) {
            UserSessionStore.getState().updateState({
                role: loginResponse?.user?.role,
                token: loginResponse.tokens?.token,
                refreshToken: loginResponse.tokens?.refresh_token,
                isLoggedIn: true,
                profile: {
                    email: loginResponse.user.email,
                    firstname: loginResponse.user.firstname,
                    lastname: loginResponse.user.lastname,
                    uid: loginResponse.user.uid,
                },
            })

            this.setUserSession(loginResponse)
            return { success: true }
        } else {
            this.clearSession()
            return { success: false }
        }
    }

    logCompanyAdmin = async ({
        company_uid,
        user_uid,
    }: {
        company_uid: string
        user_uid: string
    }): Promise<boolean> => {
        const loginResponse = await UserApi.fetchUserInfos(user_uid, company_uid)
        if (loginResponse?.user) {
            UserSessionStore.getState().updateState({
                role: loginResponse.user.role,
                token: loginResponse.tokens?.token,
                refreshToken: loginResponse.tokens?.refresh_token,
                isLoggedIn: true,
                profile: {
                    email: loginResponse.user.email,
                    firstname: loginResponse.user.firstname,
                    lastname: loginResponse.user.lastname,
                    uid: loginResponse.user.uid,
                },
            })
            this.setUserSession(loginResponse)

            return true
        }
        return false
    }

    logout = async () => {
        this.clearSession()
        UserSessionStore.getState().flush()
        UiPageSessionStore.getState().flush()
        ReputationStore.getState().flush()
        CampaignStore.getState().flush()
        KpiLocalPresenceStore.getState().flush()
        KpiReputationStore.getState().flush()
        LocationStore.getState().flush()
        useOverviewStore.getState().flush()
        ResellerStore.getState().flush()
        await ApiService.invalidateToken()
    }

    resetPassword = async (email: string, lang: string) => {
        return await UserApi.resetPassword(email, lang)
    }

    initPassword = async ({ email, uid, password, confirm_password }) => {
        return await UserApi.initPassword({ email, uid, password, confirm_password })
    }

    getState = (): IUserSessionStore & IUserSessionSetter => {
        return UserSessionStore.getState()
    }

    setState = (update: Partial<IUserSessionStore>): void => {
        const { updateState } = this.getState()
        updateState(update)
    }
}

export default new UserSessionService()
