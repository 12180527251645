import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    titleWrapper: {
        display: "flex",
        gap: 8,
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        padding: "8px 8px 0",
        "@media (min-width: 768px)": {
            minHeight: 48,
            padding: 24,
            paddingBottom: 0,
        },
        "&.title-overview": {
            padding: 0,
        },
        "& h1": {
            fontSize: 34,
            wordBreak: "break-word",
        },
        "& button": {
            padding: 0,
            minWidth: "fit-content",
            boxShadow: "none",
        },
        "& > svg": {
            backgroundColor: "rgba(1, 128, 255, 0.24)",
            padding: 8,
            borderRadius: 16,
            color: theme.palette.primary.main,
        },
        "& svg": {
            fontSize: 16,
        },
    },
}))

export default useStyles
