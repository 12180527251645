import SelectBusiness from "@/components/select/select-business"
import SelectPeriodeType from "@/components/select/select-periode-type"
import { Paper, Stack } from "@mui/material"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import useStyles from "../styles"

export const FilterAnalytics = ({
    handleSelectionChange,
    handleSelectionPeriodChange,
    selectedBusinesses,
    selectedPeriod,
}) => {
    const { classes } = useStyles()
    const { t } = useTranslation()

    const periods = useMemo(() => {
        return [
            {
                label: t("LOCAL_PRESENCE.MONTH"),
                value: 1,
            },
            // {
            //TODO: à decommenter si l'équipe produit décide de le remettre en attendant ceci est un fix du DLP-1789
            //     label: t("LOCAL_PRESENCE.QUARTER"),
            //     value: 2,
            // },
            {
                label: t("LOCAL_PRESENCE.YEAR"),
                value: 3,
            },
        ]
    }, [])

    const selectBusinessLabel = useMemo(() => {
        if (selectedBusinesses.length === 0 || selectedBusinesses.length > 1) {
            return t("BUSINESSES.TITLE")
        }
        return `${t("BUSINESSES.TITLE_SINGULAR")}`
    }, [selectedBusinesses.length, t])

    const businessesSelectionChange = useCallback(
        (items) => {
            handleSelectionChange(items)
        },
        [handleSelectionChange]
    )

    const periodSelectionChange = useCallback(
        (item) => {
            handleSelectionPeriodChange(item)
        },
        [handleSelectionChange]
    )

    return (
        <Stack gap={2}>
            <Paper className={classes.wrapperBloc}>
                <Stack className="btn-filter-left">
                    <SelectBusiness
                        label={selectBusinessLabel}
                        defaultValue={selectedBusinesses}
                        selectionChange={businessesSelectionChange}
                    />
                </Stack>
                {periods && (
                    <Stack className="btn-filter-right">
                        {/* <DateRangeFieldValue
                        value={[dayjs("2022-04-17"), dayjs("2022-04-21")]}
                        handleChangeDates={() => null}
                    /> */}
                        <SelectPeriodeType
                            lists={periods}
                            selected={selectedPeriod}
                            selectionChange={periodSelectionChange}
                        />
                    </Stack>
                )}
            </Paper>
        </Stack>
    )
}
