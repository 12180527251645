import { Box } from "@mui/material"
import useStyles from "../styles"
import BaseAppBar from "@/components/base/app-bar"
import { EditContainerProps } from "@/types/page"
import { DrawerBloc } from "@/components/business/drawer-bloc"

const EditZoneContainer = ({ children, onClose, open }: EditContainerProps) => {
    const { classes } = useStyles()

    return (
        <DrawerBloc
            disableScrollLock
            data-testid="close-edit-zone"
            hideBackdrop
            anchor={"right"}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            disableEscapeKeyDown
            classPaper={classes.container}
        >
            <BaseAppBar />
            <Box className="children-wrapper">{children}</Box>
        </DrawerBloc>
    )
}

export default EditZoneContainer
