import logoDilypse from "@/assets/images/logo-dilypse.png"
import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import useStyles from "@/components/login/styles"
import useNotification from "@/hooks/useNotification"
import { UserSessionService } from "@/services"
import UiService from "@/services/ui"
import ResellerStore from "@/store/reseller"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import { GoogleLogin } from "@react-oauth/google"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
const LoginPage = () => {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const { classes } = useStyles()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { notif } = useNotification()
    // const [emailInvalid, setEmailInvalid] = useState(false)
    const [passwordInvalid, setPasswordInvalid] = useState(false)
    const [showMailAdornment, setShowMailAdornment] = useState(false)
    const [showPasswordAdornment, setShowPasswordAdornment] = useState(false)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { preferredLanguage } = UiStore()
    //const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const { resellerLogo, resellerName } = ResellerStore()
    const { isConnected } = UserSessionStore()
    const { updateState } = UiPageSessionStore()

    const loginFlow = useCallback(
        async (email, password, authType = "default") => {
            const { success, error } = await UserSessionService.login(email, password, authType)
            if (error) {
                setError(t(`${error}`))
            }
            setLoading(false)
            if (success) {
                updateState({
                    menuPage: "reputation",
                })
                navigate("/reputation")
            }
        },
        [navigate, t, updateState]
    )

    const onSubmit = async (event: any) => {
        event.preventDefault()
        setLoading(true)
        setError("")
        if (!isConnected) {
            setError(t("SYSTEM_ERROR.INVALID_REQUEST"))
            setLoading(false)
        } else {
            loginFlow(email, password)
        }
    }

    const updateEmail = (email: string) => {
        setEmail(email)
    }

    const updatePassword = (password: string) => {
        if (password.length > 1000 && password.length < 8) {
            setPasswordInvalid(true)
        } else {
            setPasswordInvalid(false)
        }
        setPassword(password)
    }

    const handleLoginSuccess = useCallback(
        async (responseGoogle) => {
            if (responseGoogle?.clientId && responseGoogle?.credential) {
                setLoading(true)
                setError("")
                loginFlow(responseGoogle.clientId, responseGoogle.credential, "google")
            }
        },
        [loginFlow]
    )

    useEffect(() => {
        UiService.setState({ title: "LOGIN.TITLE" })
    }, [])

    return (
        <Box className={classes.loginWrapper}>
            {resellerLogo && (
                <img data-testid="logo" src={resellerLogo || logoDilypse} alt={resellerName || "Dilypse"} />
            )}
            <Typography data-testid="title" variant="h1">
                {t("LOGIN.TITLE")}
            </Typography>
            {preferredLanguage && (
                <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    onError={() => {
                        notif({
                            type: "ERROR",
                            message: t(`LOGIN.GOOGLE_AUTH_FAILED`),
                        })
                    }}
                    size="large"
                    useOneTap
                    auto_select={false}
                    context="signup"
                    locale={preferredLanguage}
                />
            )}

            <Stack
                className="separator"
                flexDirection={"row"}
                justifyContent={"center"}
                sx={{
                    maxWidth: "360px",
                    width: "360px",
                    position: "relative",
                    "&:before": {
                        content: '""',
                        position: "absolute",
                        top: "12px",
                        left: 0,
                        height: "2px",
                        width: "100%",
                        backgroundColor: "rgba(91, 125, 216, 0.16)",
                        zIndex: 0,
                    },
                    "& p": {
                        width: "57px",
                        backgroundColor: "white",
                        display: "block",
                        position: "relative",
                        zIndex: 2,
                        color: "action.active",
                    },
                    "@media(max-width: 425px)": {
                        width: "100%",
                    },
                }}
            >
                <Typography>{t("LOGIN.OR")}</Typography>
            </Stack>
            <form onSubmit={onSubmit}>
                <BaseInput
                    type="email"
                    label={t("LABEL.EMAIL")}
                    value={email}
                    data-testid="email"
                    // error={emailInvalid}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateEmail(e.target.value.trim())}
                    onFocus={() => setShowMailAdornment(true)}
                    onBlur={() => setShowMailAdornment(false)}
                    required
                    autoComplete="on"
                    startAdornment={(showMailAdornment || email) && <Icon name="envelope" />}
                />

                <BaseInput
                    label={t("LABEL.PASSWORD")}
                    type="password"
                    value={password}
                    data-testid="password"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updatePassword(e.target.value.trimStart())}
                    onFocus={() => setShowPasswordAdornment(true)}
                    onBlur={() => setShowPasswordAdornment(false)}
                    required
                    autoComplete="on"
                    startAdornment={(showPasswordAdornment || password) && <Icon name="lock" />}
                />
                <Link to={"/forgot-password"}>
                    <Typography component={"span"} className="primary">
                        {t("LOGIN.FORGOT")}
                    </Typography>
                </Link>
                {error && (
                    <Typography data-testid="error" component={"span"} className="error">
                        {error}
                    </Typography>
                )}

                <Button
                    variant="contained"
                    data-testid="connect"
                    type="submit"
                    disabled={!password || !email || loading || passwordInvalid}
                >
                    {loading && <CircularProgress size={16} />}
                    {t("BUTTON.BUTTON_CONNECT")}
                </Button>
            </form>
        </Box>
    )
}

export default LoginPage
