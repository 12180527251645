import { SelectType } from "@/types/select"
import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    Stack,
    SxProps,
    Typography,
} from "@mui/material"
import { useMemo } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Icon from "../Icon"
import CustomTooltip from "../Tooltip"
import { iconBtnSx } from "../page-container/list-container/title-wrapper/styles"
import { defaultSx, inputLabelSx } from "./style"

interface BaseSelectProps {
    id?: string
    label?: string
    sx?: SxProps | any
    defaultValue?: string
    className?: string
    required?: boolean
    controlName?: string
    control?: any
    options?: SelectType[]
    secondOptions?: SelectType[]
    listSubheaderOne?: string
    listSubheaderTwo?: string
    disabled?: boolean
    register?: any
    dataTestId?: string
    onItemClicked?: (item: any) => void
    formStateError?: boolean
    multiSelect?: boolean
    open?: boolean
    onSelectionChange?: (selected: any) => void
    onClearClicked?: () => void
    selected?: string[]
    mobileWidth?: number
}

const BaseSelect = ({
    id,
    label,
    sx,
    defaultValue,
    className,
    required = false,
    controlName,
    control,
    options,
    secondOptions,
    listSubheaderOne,
    listSubheaderTwo,
    disabled,
    register,
    onItemClicked,
    dataTestId,
    multiSelect,
    formStateError,
    onSelectionChange,
    onClearClicked,
    selected,
    mobileWidth = 768,
    ...rest
}: BaseSelectProps) => {
    const { t } = useTranslation()

    const otherSelectedNumber = useMemo(() => {
        return selected?.length > 1 ? selected.length - 1 : 0
    }, [selected])

    return (
        <FormControl
            fullWidth
            className={className}
            variant="filled"
            required={required}
            {...rest}
            {...register}
            disabled={disabled}
            focused={false}
            aria-label="custom-select"
        >
            <InputLabel sx={{ ...inputLabelSx(formStateError) }} error={formStateError} id={id}>
                {label}
            </InputLabel>

            {control && (
                <Controller
                    render={({ field, fieldState }) => (
                        <Select
                            defaultValue={defaultValue}
                            labelId={id}
                            MenuProps={{
                                className: "select-menu-wrapper",
                                sx: {
                                    width: 390,
                                },
                                classes: { paper: "select-paper" },
                            }}
                            error={!!fieldState?.error}
                            sx={{
                                ...defaultSx({
                                    noBottomBorder: false,
                                    showLabelStar: true,
                                    error: !!fieldState?.error,
                                }),
                                ...sx,
                            }}
                            {...field}
                        >
                            {(!options || options?.length === 0) && (
                                <MenuItem value="" disabled>
                                    <Typography>{t("AUTOCOMPLETE.NO_OPTIONS")}</Typography>
                                </MenuItem>
                            )}
                            {options?.map((option, key) => (
                                <MenuItem
                                    value={option.value}
                                    key={`${String(key)}`}
                                    sx={{
                                        paddingBottom: "8px",
                                        paddingTop: "8px",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                        flexDirection: "row",
                                        textWrap: "wrap",
                                        gap: 1,
                                    }}
                                    onClick={() => {
                                        if (typeof onItemClicked === "function") {
                                            onItemClicked(option)
                                        }
                                    }}
                                >
                                    {option.prefix && <Typography component={"span"}>{option.prefix}</Typography>}
                                    <span>{option.label}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    name={controlName || ""}
                    control={control}
                    defaultValue={defaultValue}
                />
            )}
            {!control && !multiSelect && (
                <Select
                    value={defaultValue}
                    labelId={id}
                    MenuProps={{ className: "select-menu-wrapper" }}
                    sx={{
                        ...defaultSx({
                            noBottomBorder: false,
                            showLabelStar: true,
                        }),
                        ...sx,
                    }}
                >
                    {(!options || options?.length === 0) && (
                        <MenuItem value="" sx={{ justifyContent: "center" }} disabled>
                            <Typography component={"span"} sx={{ color: "action.active" }}>
                                {t("AUTOCOMPLETE.NO_OPTIONS")}
                            </Typography>
                        </MenuItem>
                    )}
                    {options?.map((option, key) => (
                        <MenuItem
                            value={option.value}
                            key={`${String(key)}`}
                            sx={{
                                paddingBottom: "8px",
                                paddingTop: "8px",
                            }}
                            onClick={() => {
                                if (typeof onItemClicked === "function") {
                                    onItemClicked(option)
                                }
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            )}

            {multiSelect && (
                <Select
                    labelId="multiple-checkbox-label"
                    id="multiple-checkbox"
                    key={"multiple-select-checkbox"}
                    multiple
                    value={selected}
                    onChange={onSelectionChange}
                    sx={{
                        ...defaultSx({
                            noBottomBorder: false,
                            showLabelStar: true,
                        }),
                        ...sx,
                    }}
                    renderValue={(selected: any) => (
                        <Stack gap={1} flexDirection="row" sx={{ width: "calc(100% - 75px)" }}>
                            <Typography className="first-selected" component="span">
                                {selected[0]?.label}
                            </Typography>
                            {otherSelectedNumber > 0 && (
                                <Typography width={selected[0]?.label?.length > 20 ? 75 : 30} component="span">
                                    +{otherSelectedNumber}
                                </Typography>
                            )}
                        </Stack>
                    )}
                    endAdornment={
                        selected?.length > 0 && (
                            <Stack
                                flexDirection={"row"}
                                marginRight={3}
                                className="text-secondary"
                                sx={{ position: "absolute", right: "8px" }}
                            >
                                <CustomTooltip title={t("BUSINESSES.ERASE_TEXT")}>
                                    <IconButton sx={iconBtnSx} onClick={onClearClicked} className="text-clear-icon">
                                        <Icon name="close" />
                                    </IconButton>
                                </CustomTooltip>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ marginX: "8px", height: "24px", alignSelf: "center" }}
                                />
                            </Stack>
                        )
                    }
                    MenuProps={{
                        className: options && secondOptions ? "select-menu-wrapper-rate" : "select-menu-wrapper",
                        sx: {
                            width: 390,
                            top: -10,
                            "& .MuiList-root": {
                                columnCount: options && secondOptions ? 2 : 1,
                                paddingTop: options && secondOptions ? "8px" : 1,
                                columnGap: 0,
                                "@media(max-width: 767px)": {
                                    columnCount: 1,
                                },
                            },
                        },
                    }}
                >
                    {(!options || options?.length === 0) && (
                        <MenuItem value="" sx={{ justifyContent: "center" }} disabled>
                            <Typography component={"span"} sx={{ color: "action.active" }}>
                                {t("AUTOCOMPLETE.NO_OPTIONS")}
                            </Typography>
                        </MenuItem>
                    )}
                    <ListSubheader
                        sx={{
                            borderRight: "1px solid #5B7DD829",
                            lineHeight: "28px",
                            "@media(max-width: 767px)": {
                                borderRight: "none",
                                paddingTop: "11px",
                                lineHeight: "10px",
                                paddingBottom: "4px",
                            },
                        }}
                    >
                        {listSubheaderOne}
                    </ListSubheader>
                    {secondOptions &&
                        options?.map((value: any, k) => (
                            <MenuItem
                                key={`${String(k)}`}
                                value={value}
                                data-e2e="filter-option"
                                sx={{
                                    borderRight: "1px solid #5B7DD829",
                                    "&.Mui-selected": {
                                        backgroundColor: "transparent",
                                    },
                                    "@media(max-width: 767px)": {
                                        borderRight: "none",
                                        paddingTop: "2px",
                                        paddingBottom: options.length - 1 == k ? "10px" : "1px",
                                    },
                                }}
                            >
                                <Checkbox
                                    checked={selected.findIndex((item: any) => item.value === value.value) > -1}
                                />
                                <ListItemText primary={value.label} sx={{ textWrap: "pretty" }} />
                            </MenuItem>
                        ))}
                    {options && secondOptions && (
                        <Box
                            component="span"
                            sx={{
                                "@media (min-width: 768px)": {
                                    display: "block",
                                    breakAfter: "column",
                                },
                            }}
                        ></Box>
                    )}
                    <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{
                            marginX: "8px",
                            "@media (min-width: 768px)": { display: "none" },
                        }}
                    />
                    <ListSubheader
                        sx={{
                            lineHeight: "28px",
                            "@media(max-width: 767px)": {
                                paddingTop: "11px",
                                lineHeight: "10px",
                                paddingBottom: "4px",
                            },
                        }}
                        className="aside-item"
                    >
                        {listSubheaderTwo}
                    </ListSubheader>
                    {secondOptions?.map((value: any, k) => (
                        <MenuItem
                            key={`${String(k)}`}
                            className="aside-item"
                            value={value}
                            data-e2e="filter-option"
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: "transparent",
                                },
                                "@media(max-width: 767px)": {
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                },
                            }}
                        >
                            <Checkbox checked={selected.findIndex((item: any) => item.value === value.value) > -1} />
                            <ListItemText primary={value.label} sx={{ textWrap: "pretty" }} />
                        </MenuItem>
                    ))}
                    {options &&
                        !secondOptions &&
                        options.map((value: any, k) => (
                            <MenuItem
                                key={`${String(k)}`}
                                value={value}
                                data-e2e="filter-option"
                                sx={{
                                    "&.Mui-selected": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                <Checkbox
                                    checked={selected.findIndex((item: any) => item.value === value.value) > -1}
                                />
                                <ListItemText primary={value.label} sx={{ textWrap: "pretty" }} />
                            </MenuItem>
                        ))}
                </Select>
            )}
        </FormControl>
    )
}

export default BaseSelect
