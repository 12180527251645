import { Box } from "@mui/material"
import BaseAppBar from "@/components/base/app-bar"
import { EditContainerProps } from "@/types/page"
import { DrawerBloc } from "@/components/business/drawer-bloc"

const EditAttributeContainer = ({ children, onClose, open }: EditContainerProps) => {
    return (
        <DrawerBloc
            disableScrollLock
            hideBackdrop
            anchor={"right"}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            disableEscapeKeyDown
        >
            <BaseAppBar />
            <Box className="children-wrapper">{children}</Box>
        </DrawerBloc>
    )
}

export default EditAttributeContainer
