import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { Box, IconButton, Paper, Typography } from "@mui/material"
import classNames from "classnames"
import OverviewTitle from "../title"
import useStyles from "./styles"
import { useTranslation } from "react-i18next"
import useMainInfoStore from "@/store/overview/main-info/useMainInfoStore"
import ErrorMessage from "@/components/business/error-message"
import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"

const OverviewContact = ({ isLoading, mandatoryCheck = false, sx }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { navigateTo } = useBusinessLogic()
    const { address, name, logo, id, isAddressVisible, companyName, phone } = useMainInfoStore()

    return (
        <Paper
            elevation={1}
            className={classNames("section clickable", classes.overviewContact)}
            onClick={() => !isLoading && navigateTo("edit-main-info")}
            id="mainInfo"
            sx={sx}
            data-e2e="main-info"
        >
            <OverviewTitle
                title={t("USERS.MAIN_INFO")}
                icon="annotationInfo"
                tooltipText={t("BUSINESSES.EDIT_GENERAL.MAIN_TOOLTIP")}
            />
            <Box className="image-container">
                {name && !logo && (
                    <Box className="image-frame without-logo" data-e2e="without-logo">
                        <Icon name="image-placeholder" />
                        <Typography component="span">Logo</Typography>
                    </Box>
                )}
                {logo && (
                    <Box className="image-frame image-preview" data-e2e="with-logo">
                        <img src={logo} alt="Logo" />
                    </Box>
                )}
                <Box className="contact-wrapper">
                    {id && mandatoryCheck && <ErrorMessage />}
                    {name && (
                        <Box className="title-wrapper">
                            <Icon name="profile" />
                            <Typography variant="h3">{name}</Typography>
                        </Box>
                    )}
                    {companyName && (
                        <Box className="line-wrapper">
                            <Icon name="building" />
                            <Typography component={"span"}>{companyName}</Typography>
                        </Box>
                    )}
                    {address && (
                        <Box className="line-wrapper address">
                            <Box>
                                <Icon name="map" />
                                <Typography component={"span"}>{address}</Typography>
                            </Box>
                            <CustomTooltip
                                title={
                                    isAddressVisible
                                        ? t("BUSINESSES.ADDRESS.VISIBLE_TOOLTIP")
                                        : t("BUSINESSES.ADDRESS.HIDDEN_TOOLTIP")
                                }
                            >
                                <IconButton>
                                    <Icon name={isAddressVisible ? "eye" : "eye-off"} />
                                </IconButton>
                            </CustomTooltip>
                        </Box>
                    )}

                    {phone && (
                        <Box className="line-wrapper">
                            <Icon name="phone" />
                            <Typography component={"span"}>{phone}</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Paper>
    )
}

export default OverviewContact
