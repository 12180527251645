import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { modalMediaStyle } from "@/pages/businesses/edit-business/edit-media/style"
import { withSxProp } from "@/utils"
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    Modal,
    Paper,
    Stack,
    Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { labelErrorSx, logoSx } from "../form-container/edit/edit-main-info/styles"
import { mediaModalSx } from "../form-container/list-media/styles"

const AddMediaModal = ({
    open,
    onClose,
    error,
    handleImage,
    inputRef,
    inputChangeHandler,
    handleGoogle,
    handleFacebook,
    googleChecked,
    fbChecked,
}) => {
    const { t } = useTranslation()
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper elevation={6} sx={withSxProp(modalMediaStyle, logoSx, mediaModalSx, { alignItems: "start" })}>
                <Box className="title-wrapper">
                    <Typography id="modal-modal-title" data-testid="title-add" variant="h2">
                        {t("MEDIA.ADD")}
                    </Typography>
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton onClick={onClose}>
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
                <Stack gap={1}>
                    <Typography variant="body1" color="action.active">
                        {t("MEDIA.ADD_PLATEFORM")}
                    </Typography>
                    <FormGroup className="flex" sx={{ flexDirection: "row", gap: "24px" }}>
                        <FormControlLabel
                            sx={{ gap: 1 }}
                            checked={googleChecked}
                            control={
                                <Checkbox
                                    sx={{ padding: "0px" }}
                                    onChange={(event) => {
                                        handleGoogle(event.target.checked)
                                    }}
                                />
                            }
                            label="Google"
                        />
                        <FormControlLabel
                            sx={{ gap: 1 }}
                            checked={fbChecked}
                            control={
                                <Checkbox
                                    sx={{ padding: "0px" }}
                                    onChange={(event) => {
                                        handleFacebook(event.target.checked)
                                    }}
                                />
                            }
                            label="Facebook"
                        />
                    </FormGroup>
                </Stack>
                <Box sx={{ width: "100%" }}>
                    <Box
                        component={"label"}
                        className="drop-zone-two"
                        onDrop={(e) => {
                            e.preventDefault()
                            handleImage(e.dataTransfer.files)
                        }}
                        onDragOver={(e) => {
                            e.preventDefault()
                        }}
                        sx={labelErrorSx({ error, hidden: false })}
                    >
                        <Icon name="image-plus" className="image-plus" />
                        <Box>
                            <Typography data-testid="title-choose" component={"span"} className="btn-txt">
                                {t("BUSINESSES.EDIT_LOGO.CHOOSE_FILE")}
                            </Typography>{" "}
                            <Typography
                                data-testid="title-deposite"
                                component="span"
                                sx={{ display: "none", "@media (min-width: 768px)": { display: "initial" } }}
                            >
                                {t("BUSINESSES.EDIT_LOGO.DEPOSIT_FILE")}
                            </Typography>
                        </Box>
                        <Stack sx={{ alignItems: "center", textAlign: "center" }} className="prerequisites">
                            <Typography
                                fontSize={14}
                                dangerouslySetInnerHTML={{
                                    __html: t("MEDIA.MAX_SIZE", {
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                                component="span"
                                className="image-type"
                                variant="caption"
                            />
                            <Typography
                                fontSize={14}
                                dangerouslySetInnerHTML={{
                                    __html: t("MEDIA.MAX_SIZE_IMAGE", {
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                                component="span"
                                className="image-type"
                                variant="caption"
                            />
                            <Typography component="span" className="image-type" variant="caption" fontSize={14}>
                                <strong>{t("MEDIA.ACCEPTED_FORMAT")}:</strong> JPEG, JPG, PNG, MP4, M4V, MOV
                            </Typography>
                        </Stack>
                        <input
                            type="file"
                            hidden
                            ref={inputRef}
                            accept=".png, .jpg, .jpeg, .mp4"
                            onChange={inputChangeHandler}
                            multiple
                        />
                    </Box>
                    {error && (
                        <Typography component="span" className="error">
                            {t("MEDIA.ERROR_FILES")}
                        </Typography>
                    )}
                </Box>
                {error && (
                    <Stack
                        flexDirection={"row"}
                        alignItems="start"
                        paddingX="16px"
                        paddingY="6px"
                        borderRadius={1}
                        sx={{ background: "#E5F6FD" }}
                    >
                        <Icon name="info" sx={{ width: "22px", color: "#2EAFF7", padding: "7px 12px 7px 0px" }} />
                        <Typography variant="body2" paddingY={"8px"}>
                            {t("MEDIA.ALERT_MESSAGE")}
                        </Typography>
                    </Stack>
                )}

                <Box className="action-wrapper">
                    <Button data-testid="title-close" onClick={onClose}>
                        {t("USERS.CANCEL")}
                    </Button>
                    {/* <Button data-testid="title-btn-choose" className="primary" onClick={handleChooseFileClick}>
                        <Icon name="file-plus" />
                        {t("BUSINESSES.EDIT_LOGO.CHOOSE_FILE")}
                    </Button> */}
                </Box>
            </Paper>
        </Modal>
    )
}
export default AddMediaModal
