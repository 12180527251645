import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useAttributesStore, { AttributChecked, AttributeType, ListAttributes } from "@/store/overview/attributes"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"

interface LoggedAttribute {
    [key: string]: boolean | string[]
}

const useLogic = () => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const { businessId } = useParams()
    const { profile } = UserSessionStore()
    const [loading, setLoading] = useState(false)
    const { notif } = useNotification()
    const { listAttributes, update, flush } = useAttributesStore()
    const { menuPage, showPage, previousItem, updatePreviousItem, updateState: setUiPageSession } = UiPageSessionStore()
    const { location, fetchAttribute, updateLocation } = useLocation()
    const { preferredLanguage } = UiStore()

    const getAttributes = useCallback(async () => {
        const listAttributes = (await fetchAttribute(businessId)) ?? []
        const attributes = []
        const locationAttrSelect = Object.keys(location?.attributIds ?? {})
        let selected: any = null
        listAttributes?.map((attribute) => {
            if (attribute.type === "RADIO" || attribute.type === "CHECKBOX") {
                const opt = []
                selected = null
                if (attribute.type === "CHECKBOX") {
                    selected = []
                }
                attribute.values.map((val) => {
                    opt.push({
                        display: val.displayName,
                        value: val.value,
                        checked:
                            locationAttrSelect.includes(attribute.id) &&
                            ((attribute.type === "RADIO" && location.attributIds[attribute.id] === val.value) ||
                                (attribute.type === "CHECKBOX" &&
                                    location.attributIds[attribute.id].includes(val.value))),
                    })
                    if (
                        locationAttrSelect.includes(attribute.id) &&
                        attribute.type === "RADIO" &&
                        location.attributIds[attribute.id] === val.value
                    ) {
                        selected = opt[opt.length - 1]
                    }
                    if (
                        locationAttrSelect.includes(attribute.id) &&
                        attribute.type === "CHECKBOX" &&
                        location.attributIds[attribute.id].includes(val.value)
                    ) {
                        selected.push(opt[opt.length - 1])
                    }
                })
                if (attribute.type === "RADIO") {
                    const findOpt = opt.findIndex((el) => el.checked)
                    opt.push({
                        display: t("BUSINESSES.EDIT_ATTRIBUTES.UNDEFINED"),
                        value: "NONE",
                        checked: findOpt < 0, //TODO: à verifier
                    })
                    if (findOpt === -1) {
                        selected = opt[opt.length - 1]
                    }
                }
                const option = {
                    title: attribute.groupLabel,
                    attributes: [
                        {
                            id: attribute.id,
                            type: attribute.type,
                            title: attribute.label,
                            options: [...opt],
                            selected: selected,
                        },
                    ],
                }

                const findAttribute = attributes.findIndex((el) => el.title === attribute.groupLabel)
                if (findAttribute >= 0) {
                    attributes[findAttribute].attributes.push(option.attributes[0])
                } else {
                    attributes.push(option)
                }
            }
        })
        update({ listAttributes: attributes })
    }, [businessId, location?.attributIds])

    useEffect(() => {
        if (previousItem && previousItem.attributes?.length > 0) {
            update({ listAttributes: [...previousItem.attributes] })
        }

        if (
            profile.uid &&
            businessId &&
            menuPage === "businesses" &&
            showPage === "edit-attribute" &&
            (!previousItem || (previousItem && !previousItem.attributes))
        ) {
            getAttributes()
        }
    }, [showPage, location, preferredLanguage, getAttributes, profile?.uid, businessId])

    const handlePreviousButton = useCallback(() => {
        navigateTo("detail")
        updatePreviousItem({
            attributes: [...listAttributes],
        })
    }, [updatePreviousItem, listAttributes])

    const handleCancelButton = useCallback(() => {
        navigateTo("detail")
        flush()
        updatePreviousItem({
            attributes: null,
            isOpenWithoutHour: false,
        })
    }, [updatePreviousItem])

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        console.log(errors)
    }
    const schema = useMemo(() => {
        return z.object({
            attributes: z.string().array().optional(),
        })
    }, [])

    const { handleSubmit } = useForm<ListAttributes>({
        defaultValues: {
            attributes: [],
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const handleCheckboxAttribute = (attr: AttributeType, arrayAttributes: LoggedAttribute) => {
        if (attr.type === "CHECKBOX" && attr.selected && attr.selected.value !== "NONE") {
            const arrayBox = attr.selected.map((sel: AttributChecked) => sel.value)
            if (arrayBox.length > 0) {
                arrayAttributes[attr.id] = [...arrayBox]
            }
        }
    }

    const onSubmit: SubmitHandler<any> = async () => {
        setLoading(true)
        const body: any = { ...location }
        const arrayAttributes: LoggedAttribute = {}
        listAttributes.map((section) => {
            section.attributes.map((attr: AttributeType) => {
                if (attr.type === "RADIO" && attr.selected && attr.selected.value != "NONE") {
                    arrayAttributes[attr.id] = attr.selected.value
                }
                handleCheckboxAttribute(attr, arrayAttributes)
            })
        })

        const currentLocationAttributs = Object.entries({ ...body["attributIds"] }).filter(
            ([key, value]) => typeof value !== "boolean"
        )
        body["attributIds"] = { ...Object.fromEntries(currentLocationAttributs), ...arrayAttributes }
        const response = await ResellerApi.updateLocation({ ...body })
        if (response?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
            navigateTo("detail")
            flush()
            updatePreviousItem({
                attributes: null,
            })
            setUiPageSession({ refreshItems: true })
            updateLocation(response)
        }
        setLoading(false)
    }

    return {
        handleSubmit,
        onSubmit,
        onErrors,
        handleCancelButton,
        handlePreviousButton,
        t,
        loading,
        listAttributes,
    }
}

export default useLogic
