import { ReputationApi } from "@/api"
import { ListingService } from ".."

export type FetchAvisInput = {
    _page: number
    _perPage: number
    _sortBy: string
    _sortOrder: number
    locationsId?: string[]
}

type MetadataAvis = {
    has_review_hidden: boolean
    total: number
}

type FetchAvisResponse = {
    data: any[]
    metadata: MetadataAvis
}

class ReputationService {
    fetchReview = async ({ ids }: { ids: string[] }) => {
        return await ReputationApi.fetchData({ ids })
    }

    populateLogoPlatform = async (data) => {
        const platformIds = data.map((data) => data.platformId)
        const platformMap = await ListingService.getPlatformInfo(platformIds)
        const dataWithPlatformLogo = data?.map((data) => ({
            ...data,
            platformLogo: platformMap?.[data.platformId]?.logoUrl,
        }))
        return dataWithPlatformLogo
    }

    fetchAvis = async (filterParams: FetchAvisInput): Promise<FetchAvisResponse> => {
        const response = await ReputationApi.fetchData(filterParams)
        const dataWithPlatformLogo = await this.populateLogoPlatform(response.data ?? [])
        return {
            data: dataWithPlatformLogo,
            metadata: response.metadata as MetadataAvis,
        }
    }
}

export default new ReputationService()
