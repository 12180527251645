import env from "@/config/env"
import Cookies, { CookieAttributes } from "js-cookie"

type CookieJSON = {
    email: string
    firstname: string
    lastname: string
    token: string
    refreshToken: string
    role: string
    user_uid: string
    lang: string
    feedbackUrl: string
    smsSender: string
    emailSpotconnect: string

    resellerUid: string
    resellerLogo: string
    resellerLogoDark: string
    resellerName: string
    resellerFavicon: string
    expandGoogleBanner: boolean
}

class CookiesService {
    private cookieOptions: CookieAttributes = {}
    private cookieName = env.COOKIE_NAME

    constructor() {
        this.cookieOptions = {
            expires: 1,
            secure: true,
            sameSite: "Strict",
        }
    }

    getAll(): CookieJSON {
        const data = Cookies.get(this.cookieName) ?? "{}"
        return JSON.parse(data)
    }

    get(key: keyof CookieJSON) {
        const data = this.getAll() ?? {}
        return data[key]
    }

    set(input: Partial<CookieJSON> = {}) {
        const oldCookies = this.getAll()
        const updated = {
            ...oldCookies,
            ...input,
        }
        Cookies.set(this.cookieName, JSON.stringify(updated), this.cookieOptions)
    }

    delete(keys: Partial<keyof CookieJSON>[] = []) {
        const cookies = this.getAll()
        for (const key of keys) {
            delete cookies[key]
        }
        Cookies.set(this.cookieName, JSON.stringify(cookies), this.cookieOptions)
    }

    flush() {
        this.delete([
            "email",
            "firstname",
            "lastname",
            "role",
            "user_uid",
            "lang",
            "feedbackUrl",
            "smsSender",
            "emailSpotconnect",

            "resellerUid",
            "resellerLogo",
            "resellerLogoDark",
            "resellerName",
            "resellerFavicon",
        ])
    }
}

export default new CookiesService()
