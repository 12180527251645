import BaseSelect from "@/components/base/select"
import { useSelectBusiness } from "@/hooks/select-business/useSelectBusiness"
import useLocationStore from "@/store/location"
import { useTranslation } from "react-i18next"
import { styleSx } from "./style"

const SelectBusiness = ({ label, selectionChange, defaultValue }) => {
    const { t } = useTranslation()

    const { businesses } = useLocationStore()
    const { handleOnSelectionChange, handleClear, selected } = useSelectBusiness({ selectionChange, defaultValue })

    return (
        <BaseSelect
            options={businesses || []}
            label={label || t("MENU.BUSINESSES")}
            multiSelect
            onSelectionChange={handleOnSelectionChange}
            onClearClicked={handleClear}
            selected={selected}
            sx={styleSx}
            mobileWidth={768}
            id="select-business-reputation"
        />
    )
}

export default SelectBusiness
