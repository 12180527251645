import { Button } from "@mui/material"
import { CSSProperties, useCallback } from "react"
import useNotification from "./useNotification"

const buttonSx: CSSProperties = {
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    right: 0,
    margin: 1,
}

const useClipboard = () => {
    const { notif } = useNotification()
    const handleClipboardCopy = useCallback(
        (uid: string) => {
            navigator.clipboard.writeText(uid)
            notif({ type: "SUCCESS", message: "Value copied to the clipboard" })
        },
        [notif]
    )
    const ButtonClipboard = useCallback(
        ({ label, value }: { label: string; value: any }) => {
            return (
                <Button variant="contained" sx={buttonSx} onClick={() => handleClipboardCopy(value)}>
                    {label}
                </Button>
            )
        },
        [handleClipboardCopy]
    )

    return {
        ButtonClipboard,
    }
}

export default useClipboard
