import { Container, Stack } from "@mui/material"
import { SkeletonHeaderBtn } from "../../header-btn-left-right"
import { SkeletonBusinessListItem } from "./item"
import { v4 as uuidv4 } from "uuid"

export const SkeletonBusinessList = () => {
    return (
        <Container>
            <SkeletonHeaderBtn />
            <Stack gap={2} flexDirection="row" flexWrap={"wrap"}>
                {[...Array(10)].map((key) => (
                    <SkeletonBusinessListItem key={uuidv4()} />
                ))}
            </Stack>
        </Container>
    )
}
