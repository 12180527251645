import BaseInputPhone from "@/components/base/BaseInputPhone"
import BaseInput from "@/components/base/input"
import usePhoneInputLogic from "@/hooks/phone-input/useLogic"
import { useMemo } from "react"

interface PropsInputEdit {
    originalValue: string
    value: string
    index: string
    langOfCountryName: string
    setData: (value: any) => void
    invalidEmail: (value: string) => boolean
    invalidPhone: (value: string) => boolean
}
export const RenderInputEdit = ({
    originalValue,
    value,
    index,
    langOfCountryName,
    setData,
    invalidEmail,
    invalidPhone,
}: PropsInputEdit) => {
    const { isPhoneValid } = usePhoneInputLogic()
    const type = useMemo(() => {
        if (originalValue) {
            if (typeof originalValue === "string" && originalValue?.includes("@")) {
                return "email"
            }
            if (isPhoneValid(originalValue)) {
                return "phone"
            }
        }
        return "text"
    }, [originalValue, isPhoneValid])

    if (type === "phone") {
        return (
            <BaseInputPhone
                control={null}
                name="p"
                required
                value={value}
                label={index}
                onlyCountries={["CA", "US", "FR"]}
                lang={langOfCountryName}
                onChange={(info) => {
                    setData((prev) => ({
                        ...prev,
                        [index]: info?.numberValue,
                        code: info?.countryCode,
                    }))
                }}
                error={invalidPhone(value)}
                // errorLabel={invalidPhone(editData[key]) && t("BUSINESSES.EDIT_MAIN_INFO.PHONE_INVALID")}
            />
        )
    } else {
        return (
            <BaseInput
                type={type}
                label={index}
                value={value}
                className="edited-cell"
                onChange={(e) => {
                    setData((prev) => ({
                        ...prev,
                        [index]: e.target.value,
                    }))
                }}
                error={type === "email" && invalidEmail(value)}
                // helperText={
                //     type === "email" && invalidEmail(editData[key]) && t("USERS.INVALID_FORMAT_EMAIL")
                // }
            />
        )
    }
}
