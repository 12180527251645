import { CreditType } from "@/api/campaign"
import { LocationType } from "@/types/businesses"
import { PaginationMetadata } from "@/types/listTable"
import { CountryCode } from "libphonenumber-js"
import { create } from "zustand"

export interface ICampaignStore {
    isLoading: boolean
    dataSent: boolean
    feedback: boolean
    openUpload: boolean
    hasModel: boolean
    file: File
    contacts: any[]
    credits: CreditType[]
    creditFetched: boolean
    message: string
    rawText: string
    editorText: string
    locations: LocationType[]
    activeStep: number
    isDisabled: boolean
    defaultFlag: CountryCode
    hasNewUrl: boolean
    pagination: PaginationMetadata
    noCredit: boolean
    creditAsked: boolean
    businessSelect: any
    listReviews: any
    loadingBusiness: boolean
}
export interface ICampaignSetter {
    update: (update: Partial<ICampaignStore>) => void
    flush: () => void
}

const initalState: ICampaignStore = {
    isLoading: false,
    dataSent: false,
    feedback: true,
    hasModel: false,
    openUpload: false,
    file: null,
    contacts: [],
    credits: [],
    creditFetched: false,
    message: "",
    rawText: "",
    editorText: "",
    locations: null,
    activeStep: 0,
    isDisabled: false,
    defaultFlag: "CA",
    hasNewUrl: false,
    businessSelect: null,
    listReviews: [],
    pagination: {
        rows: [],
        page_size: 10,
        page_number: 0,
        total_count: 0,
    },
    noCredit: false,
    creditAsked: false,
    loadingBusiness: false,
}

const CampaignStore = create<ICampaignStore & ICampaignSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    flush: () => set(initalState),
}))

export default CampaignStore
