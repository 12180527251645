import { Paper, Skeleton, Stack } from "@mui/material"

export const SkeletonBusinessListItem = () => {
    return (
        <Paper
            sx={{
                height: "120px",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
                flex: "1 49%",
            }}
        >
            <Skeleton variant="rectangular" width={72} height={72} sx={{ minWidth: "72px" }} />
            <Stack
                flexDirection={"row"}
                gap={2}
                justifyContent="space-between"
                alignItems={"center"}
                width="100%"
                height="100%"
            >
                <Stack gap={2} width="100%">
                    <Skeleton variant="rectangular" width={180} height={24} />
                    <Skeleton variant="rectangular" width="80%" height={12} />
                </Stack>
                <Stack gap={2} justifyContent="flex-start" alignItems={"start"} height="100%">
                    <Skeleton variant="rectangular" width={50} height={24} />
                </Stack>
            </Stack>
        </Paper>
    )
}
