import { Paper, Skeleton } from "@mui/material"

export const SkeletonChart = () => {
    return (
        <Paper>
            <Skeleton variant="rectangular" width={200} height={24} />
            <Skeleton variant="rectangular" width={"100%"} height={450} />
        </Paper>
    )
}
