import { create } from "zustand"

interface IMetaType {
    yext_id?: string
    gmb_id?: string
    facebook_id?: string
    instagram_id?: string
}
export interface IContentMediaStore {
    meta: IMetaType
    locationId: string
    url: string
    type: string
    category: string
    label: string
    provider: string[]
    state: string
    createdAt: string
    id: string
    thumbnailUrl: string
    updatedAt: string
    publishedAt: string
}

export interface IMediaLocal {
    fbChecked: boolean
    file?: File
    fileCrop?: File
    googleChecked: boolean
    type: string
}

interface IDateFile {
    date: string
    timezone_type: number
    timezone: string
}

interface IMetaFile {
    yext_id: string
    gmb_id: string
    facebook_id: string
    instagram_id: string
}

export interface IMediaValue extends Partial<IMediaLocal> {
    id: string
    meta: IMetaFile
    createdAt: IDateFile
    updatedAt: IDateFile
    locationId: string
    url: string
    thumbnailUrl: string
    type: string
    category: string
    label: string
    provider: string[]
}

interface IMediaStore {
    media: IMediaValue
    listMedia: IContentMediaStore[]
    listMediaLocal: (IMediaLocal & Partial<IContentMediaStore>)[]
}

const initalState: IMediaStore = {
    media: null,
    listMedia: [],
    listMediaLocal: [],
}

export interface IMediaSetter {
    update?: (update: Partial<IMediaStore>) => void
    flush?: () => void
}

const useMediaStore = create<IMediaStore & IMediaSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default useMediaStore
