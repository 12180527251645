import { Fragment } from "react"
import CreateUser from "./create-user"
import UserList from "./list-user"
import ShowDetail from "./overview-user"
import EditUser from "./edit-user"
import useUiPageSession from "@/hooks/useUiPageSession"

const UsersPage = () => {
    const { handleDrawerClose, showPage, open } = useUiPageSession({ title: "USERS.TITLE_HEADER", page: "users" })
    return (
        <Fragment>
            <UserList />
            <CreateUser open={showPage === "create" && open} onClose={handleDrawerClose} />
            <EditUser open={showPage === "edit" && open} onClose={handleDrawerClose} />
            <ShowDetail open={showPage === "detail" && open} onClose={handleDrawerClose} />
        </Fragment>
    )
}

export default UsersPage
