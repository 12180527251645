import UiStore from "@/store/ui"
import { CountryCode, isValidNumberForRegion, parsePhoneNumber } from "libphonenumber-js"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

interface AddressParser {
    region?: string
    countryCode?: CountryCode
    address?: string
    city?: string
    zip?: string
}

const useFormater = () => {
    const { t, i18n } = useTranslation()
    const { preferredLanguage } = UiStore()

    const parseAddress = useCallback(
        ({ region, countryCode, address, city, zip }: AddressParser): string => {
            let fullAddress = ""
            if (countryCode === "FR") {
                fullAddress = `${address ? address + ", " : ""}${zip ? zip + " " : ""}${city ? city + ", " : ""}${
                    region ? t(`PROVINCE.${region}`) + ", " : ""
                }${countryCode ? t(`COUNTRY.${countryCode}`) : ""}`
            } else if (countryCode === "CA" || countryCode === "US") {
                fullAddress = `${address ? address + ", " : ""}${city ? city + ", " : ""}${
                    region ? t(`PROVINCE.${region}`) + (zip ? " " : ", ") : ""
                }${zip ? zip + ", " : ""}${countryCode ? t(`COUNTRY.${countryCode}`) : ""}`
            }
            return fullAddress.trim().replace(/^, */, "").replace(/, *$/, "")
        },
        [t]
    )

    const internationalPhone = useCallback((phone: string, countryCode: CountryCode): string => {
        try {
            const formated = parsePhoneNumber(phone, countryCode)
            if (formated.number) {
                if (countryCode === "CA" || countryCode === "US") {
                    const formatedPhone = formated.nationalNumber
                        .toString()
                        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") // les numéros de téléphones US et CA ont des - à la place des espaces après le préfix
                    return `+${formated.countryCallingCode} ${formatedPhone}`
                }
                return formated.formatInternational()
            }
            return ""
        } catch (error) {
            return ""
        }
    }, [])

    /**
     * @param {string} url - L'url à nettoyer
     * @returns {string} L'url néttoyer
     * **/
    const cleanUrl = (url: string): string => {
        if (url) return url.replace(/^(https?:\/\/)?/i, "").replace(/\/$/, "")
        return ""
    }

    const parseVariables = useCallback(
        (text: string, contact: any, business) => {
            let html = text

            html = html.replace(
                new RegExp(
                    `@${translateString("CAMPAIGN.FIRSTNAME", "fr-CA")}|@${translateString(
                        "CAMPAIGN.FIRSTNAME",
                        "en"
                    )}`,
                    "g"
                ),
                (contact[t("CAMPAIGN.FIRSTNAME")] || contact.firstname || "").trim()
            )
            html = html.replace(
                new RegExp(
                    `data-value='${translateString("CAMPAIGN.FIRSTNAME", "fr-CA")}'|data-value='${translateString(
                        "CAMPAIGN.FIRSTNAME",
                        "en"
                    )}`,
                    "g"
                ),
                `data-value='${t("CAMPAIGN.FIRSTNAME")}'`.trim()
            )
            html = html.replace(
                new RegExp(
                    `@${translateString("CAMPAIGN.LASTNAME", "fr-CA")}|@${translateString("CAMPAIGN.LASTNAME", "en")}`,
                    "g"
                ),
                (contact[t("CAMPAIGN.LASTNAME")] || contact.lastname || "").trim()
            )

            html = html.replace(
                new RegExp(
                    `data-value='${translateString("CAMPAIGN.LASTNAME", "fr-CA")}'|data-value='${translateString(
                        "CAMPAIGN.LASTNAME",
                        "en"
                    )}`,
                    "g"
                ),
                `data-value='${t("CAMPAIGN.LASTNAME")}'`.trim()
            )

            html = html.replace(
                new RegExp(
                    `@${translateString("CAMPAIGN.BUSINESS", "fr-FR")}|@${translateString(
                        "CAMPAIGN.BUSINESS",
                        "fr-CA"
                    )}|@${translateString("CAMPAIGN.BUSINESS", "en")}`,
                    "g"
                ),
                (business || "").trim()
            )

            html = html.replace(
                new RegExp(
                    `data-value='${translateString("CAMPAIGN.BUSINESS", "fr-FR")}'|data-value='${translateString(
                        "CAMPAIGN.BUSINESS",
                        "fr-CA"
                    )}'|data-value='${translateString("CAMPAIGN.BUSINESS", "en")}`,
                    "g"
                ),
                `data-value='${t("CAMPAIGN.BUSINESS")}'`.trim()
            )
            return html
        },
        [t]
    )

    const parseMentionPreferLang = useCallback(
        (text: string) => {
            let html = text

            html = html.replace(
                new RegExp(
                    `@${translateString("CAMPAIGN.FIRSTNAME", "fr-CA")}|@${translateString(
                        "CAMPAIGN.FIRSTNAME",
                        "en"
                    )}`,
                    "g"
                ),
                `@${t("CAMPAIGN.FIRSTNAME")}`.trim()
            )
            html = html.replace(
                new RegExp(
                    `data-value='${translateString("CAMPAIGN.FIRSTNAME", "fr-CA")}'|data-value='${translateString(
                        "CAMPAIGN.FIRSTNAME",
                        "en"
                    )}`,
                    "g"
                ),
                `data-value='${t("CAMPAIGN.FIRSTNAME")}'`.trim()
            )
            html = html.replace(
                new RegExp(
                    `@${translateString("CAMPAIGN.LASTNAME", "fr-CA")}|@${translateString(
                        "CAMPAIGN.LASTNAME",
                        "fr-CA"
                    )}|@${translateString("CAMPAIGN.LASTNAME", "en")}`,
                    "g"
                ),
                `@${t("CAMPAIGN.LASTNAME")}`.trim()
            )
            html = html.replace(
                new RegExp(
                    `data-value='${translateString("CAMPAIGN.LASTNAME", "fr-CA")}'|data-value='${translateString(
                        "CAMPAIGN.LASTNAME",
                        "en"
                    )}`,
                    "g"
                ),
                `data-value='${t("CAMPAIGN.LASTNAME")}'`.trim()
            )

            html = html.replace(
                new RegExp(
                    `@${translateString("CAMPAIGN.BUSINESS", "fr-FR")}|@${translateString(
                        "CAMPAIGN.BUSINESS",
                        "fr-CA"
                    )}|@${translateString("CAMPAIGN.BUSINESS", "fr-CA")}|@${translateString(
                        "CAMPAIGN.BUSINESS",
                        "en"
                    )}`,
                    "g"
                ),
                `@${t("CAMPAIGN.BUSINESS")}`.trim()
            )
            html = html.replace(
                new RegExp(
                    `data-value='${translateString("CAMPAIGN.BUSINESS", "fr-FR")}'|data-value='${translateString(
                        "CAMPAIGN.BUSINESS",
                        "fr-CA"
                    )}'|data-value='${translateString("CAMPAIGN.BUSINESS", "en")}`,
                    "g"
                ),
                `data-value='${t("CAMPAIGN.BUSINESS")}'`.trim()
            )
            return html
        },
        [t]
    )

    const translateString = useCallback(
        (text: string, lang: string) => {
            return i18n.t(text, { lng: lang })
        },
        [preferredLanguage, i18n]
    )

    return {
        parseAddress,
        parseVariables,
        parseMentionPreferLang,
        internationalPhone,
        cleanUrl,
        isValidPhone: isValidNumberForRegion,
        translateString,
    }
}

export default useFormater
