import ListContainer from "@/components/base/page-container/list-container"
import TitleWrapper from "@/components/base/page-container/list-container/title-wrapper"
import ListTable from "@/components/list-table"
import useLogic from "@/hooks/companies/list/useLogic"
import { Paper } from "@mui/material"

const ListCompany = () => {
    const { t, handleChangePage, items, metadata, selectedItem, updateViewState } = useLogic()

    return (
        <ListContainer>
            <TitleWrapper title={t("COMPANIES.TITLE")} pageIcon={"company"} />
            <Paper>
                <ListTable
                    handleChangePage={handleChangePage}
                    generateLabels={(row) => [row.name]}
                    rows={items}
                    metadata={metadata}
                    toolTipRowText={t("COMPANIES.VIEW_TOOLTIP")}
                    toolTipEditText={t("COMPANIES.EDIT_TOOLTIP")}
                    handleEdit={(index: number) => {
                        if (items[index]) {
                            updateViewState({
                                showPage: "edit",
                                selectedItem:
                                    selectedItem && selectedItem.uid === items[index].uid ? selectedItem : items[index],
                            })
                        }
                    }}
                />
            </Paper>
        </ListContainer>
    )
}

export default ListCompany
