import { ResellerApi } from "@/api"
import { CookiesService, UiService } from "@/services"
import ResellerStore, { IResellerSetter, IResellerStore } from "@/store/reseller"

class ResellerService {
    getState = (): IResellerStore & IResellerSetter => {
        return ResellerStore.getState()
    }

    setState = (update: Partial<IResellerStore>): void => {
        const { updateState } = this.getState()
        updateState(update)
    }

    getResellerInfos = async () => {
        const reseller = await ResellerApi.fetchResellerInfos(window.location.hostname)

        CookiesService.set({
            resellerLogoDark: reseller?.vendor?.logoDark || "",
            resellerLogo: reseller?.vendor?.logo || "",
            resellerFavicon: reseller?.vendor?.favicon || "",
            resellerName: reseller?.vendor?.name || "",
        })

        UiService.setState({ favicon: reseller?.vendor?.favicon })

        const resellerState = {
            resellerLogoDark: reseller?.vendor?.logoDark,
            resellerLogo: reseller?.vendor?.logo,
            resellerFavicon: reseller?.vendor?.favicon,
            resellerName: reseller?.vendor?.name,
        }
        ResellerStore.getState().updateState(resellerState)
        return resellerState
    }
    //TODO: remove commented code
    // getResellerBusinesses = async ({ page_number, page_size, user_uid = null }) => {
    //     const params = {
    //         page_number,
    //         page_size,
    //     }
    //     if (user_uid) {
    //         params["user_uid"] = user_uid
    //     }
    //     return ResellerApi.fetchResellerBusinesses(params)
    // }

    clearReseller = () => {
        CookiesService.delete(["resellerFavicon", "resellerLogo", "resellerLogoDark", "resellerName"])
    }
}

export default new ResellerService()
