import { useCallback, useEffect } from "react"
import OverviewMainInfoStore from "."
import useFormater from "@/hooks/useFormater"
import useLocation from "@/hooks/useLocation"
import { ResellerApi } from "@/api"
import UserSessionStore from "@/store/user-session"

const useMainInfoStore = () => {
    const { location } = useLocation()
    const { update, ...storeProps } = OverviewMainInfoStore()
    const { parseAddress, internationalPhone } = useFormater()
    const { profile } = UserSessionStore()

    const getAddress = useCallback(
        (payload) => {
            if (!payload) {
                return ""
            }
            return parseAddress({
                region: payload.region,
                countryCode: payload.country,
                address: payload.address,
                city: payload.city,
                zip: payload.zip,
            })
        },
        [parseAddress]
    )

    const getPhone = useCallback(
        (payload) => {
            if (!payload?.phone) {
                return ""
            }
            return payload?.countryPhone ? internationalPhone(payload.phone, payload.countryPhone) : payload.phone
        },
        [internationalPhone]
    )

    const fetchCompanyInfo = useCallback(async () => {
        if (location?.companyId && profile?.uid) {
            const response: any = await ResellerApi.fetchCompanyInfo({ id: location?.companyId, user_uid: profile.uid })
            if (response) {
                update({ companyName: response.name })
            }
        }
    }, [location?.companyId, profile.uid, update])

    const setMainInfo = useCallback(
        (payload) => {
            update({
                address: getAddress(payload),
                phone: getPhone(payload),
                region: payload.region,
                countryCode: payload.country,
                city: payload.city,
                zip: payload.zip,
                companyId: payload.companyId,
                countryPhone: payload.countryPhone,
                email: payload.email,
                id: payload.id,
                logo: payload.logo,
                name: payload.name,
                placeId: payload.placeId,
                status: payload.status,
                storeCode: payload.storeCode,
                uid: payload.uid,
                isAddressVisible: payload?.serviceArea?.businessType === "CUSTOMER_AND_BUSINESS_LOCATION",
            })
        },
        [getAddress, getPhone, update]
    )

    useEffect(() => {
        fetchCompanyInfo()
    }, [fetchCompanyInfo])

    useEffect(() => {
        if (location) {
            setMainInfo(location)
        }
    }, [location, setMainInfo])

    return {
        ...storeProps,
        update,
        setMainInfo,
    }
}

export default useMainInfoStore
