import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import dayjs, { Dayjs } from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
dayjs.extend(customParseFormat)
interface UseLogicInput {
    onChange?: (value: Dayjs) => void
    onClickOutside?: (value: Dayjs) => void
    defaultValue?: string
}

const useLogic = ({ defaultValue, onChange, onClickOutside }: UseLogicInput) => {
    const anchorEl = useRef<any>()
    const inputRef = useRef<any>(null)
    const [open, setOpen] = useState<boolean>(false)
    const { preferredLanguage } = UiStore()
    const [value, setValue] = useState<Dayjs>()
    const [textValue, setTextValue] = useState<string>()
    const { previousItem } = UiPageSessionStore()

    const showAa = useMemo(() => {
        let value = false
        if (preferredLanguage?.toLowerCase() === "en") {
            value = true
        }
        return value
    }, [preferredLanguage])

    const rangeTimes = useMemo(() => {
        const ranges: any = []
        let startTime
        let endTime
        let currentTime
        switch (preferredLanguage?.toLowerCase()) {
            case "fr":
            case "fr-fr":
            case "fr-ca":
                startTime = dayjs().set("hour", 0).set("minute", 0)
                endTime = dayjs().set("hour", 23).set("minute", 30)
                currentTime = dayjs(startTime)
                while (currentTime.isBefore(endTime) || currentTime.isSame(endTime)) {
                    ranges.push(currentTime.format("HH:mm"))
                    currentTime = currentTime.add(30, "minutes")
                }
                break
            case "en":
                startTime = dayjs().set("hour", 0).set("minute", 0)
                endTime = dayjs().set("hour", 23).set("minute", 30)
                currentTime = dayjs(startTime)
                while (currentTime.isBefore(endTime) || currentTime.isSame(endTime)) {
                    ranges.push(currentTime.format("h:mm A"))
                    currentTime = currentTime.add(30, "minutes")
                }
                break
        }
        return ranges
    }, [preferredLanguage])

    const options = useMemo(() => {
        return rangeTimes
    }, [rangeTimes])

    const field_uid = useMemo(() => {
        return new Date().valueOf()
    }, [])

    const handleOnChange = (inputValue: Dayjs) => {
        setOpen(true)
        if (typeof onChange === "function") {
            setValue(inputValue)
            onChange(inputValue)
        }
    }

    const handleOnInput = () => {
        setTimeout(() => {
            const inputValue = inputRef?.current?.value?.replace(/[hmap]/g, "")
            handleTextFilterProcess(inputValue)
            const newValue = dayjs(inputValue, showAa ? "h:mm A" : "HH:mm")
            if (newValue?.isValid()) {
                setValue(newValue)
                onChange(newValue)
            }
        })
    }

    const handleTextFilterProcess = useCallback(
        (value) => {
            const firstPair = value?.split(":")?.[0]
            // const textFilter = showAa ? Number(firstPair) : firstPair?.padStart("0")
            const splitValue = value?.split(" ")?.at(-1)
            const textFilter = `${firstPair?.padStart("0")}:00${showAa ? splitValue : ""}`
            setTextValue(textFilter)
        },
        [showAa, anchorEl?.current, options]
    )

    const handleTimeClick = useCallback(
        (timeString: string) => {
            const selected = dayjs(timeString, showAa ? "h:mm A" : "HH:mm")
            setValue(selected)
            setOpen(false)
            if (typeof onChange === "function") {
                onChange(selected)
            }
        },
        [showAa, previousItem]
    )

    const handleOnFocus = useCallback(() => {
        setTimeout(() => {
            const inputValue = inputRef?.current?.value?.replace(/[hmap]/g, "")
            handleTextFilterProcess(inputValue)
            setOpen(true)
        })
    }, [value, showAa])

    const handleClickAway = useCallback(() => {
        setOpen(false)
        const inputValue = inputRef?.current?.value
        if (showAa && inputValue.includes("aa")) {
            let newValue = inputValue?.replace("aa", "AM")
            if (inputValue?.split(":")?.[1]?.includes("mm")) {
                newValue = newValue.replace("mm", "00")
            }
            const dayjsValue = dayjs(newValue, showAa ? "h:mm A" : "HH:mm")
            setValue(dayjsValue)
            onChange(dayjsValue)
        }
        if (typeof onClickOutside === "function") {
            onClickOutside(inputValue)
        }
    }, [inputRef?.current, showAa])

    useEffect(() => {
        if (defaultValue) {
            const isAaFormat = dayjs(defaultValue, "h:mm A").isValid()
            const isDefaultFormat = dayjs(defaultValue, "HH:mm").isValid()
            if (isAaFormat) {
                setValue(dayjs(defaultValue, "h:mm A"))
            } else if (isDefaultFormat) {
                setValue(dayjs(defaultValue, "HH:mm"))
            }
        }
    }, [defaultValue])

    useEffect(() => {
        if (textValue && open) {
            const filter = showAa && textValue?.startsWith("0") ? textValue?.slice(1, textValue.length) : textValue
            const target: any = document.querySelector(
                `.time_${filter?.replace(":", "_")?.replace(" ", "_")}_${field_uid}`
            )
            if (target?.parentNode) {
                target.parentNode.scrollTop = target.offsetTop
            }
        }
    }, [textValue, field_uid, open, showAa])

    return {
        anchorEl,
        open,
        showAa,
        value,
        inputRef,
        options,
        textValue,
        field_uid,
        handleOnChange,
        handleTimeClick,
        handleOnFocus,
        handleClickAway,
        handleOnInput,
    }
}

export default useLogic
