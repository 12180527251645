import Icon from "@/components/base/Icon"
import { Button, CircularProgress, Paper, Stack, SxProps } from "@mui/material"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"

interface FormActionProps {
    step: number
    handleCancel: () => void
    isDisabled?: boolean
    isCampaign?: boolean
    isLoading: boolean
    isUpdating?: boolean
    buttonText?: string
    sx?: SxProps
    onSubmit?: () => void
}

const FormAction = ({ handleCancel, isDisabled, isCampaign, isLoading, sx, onSubmit, step }: FormActionProps) => {
    const { t } = useTranslation()
    const { classes } = useStyles()

    return (
        <Paper className={classes.wrapper} elevation={6} sx={sx}>
            {step !== 0 && (
                <Button color="secondary" onClick={handleCancel}>
                    {!isCampaign ? t("USERS.CANCEL") : t("CAMPAIGN.BACK")}
                </Button>
            )}

            <Button variant={"contained"} data-testid="create" type="submit" disabled={isDisabled} onClick={onSubmit}>
                <Stack flexDirection={"row"} alignItems="center" gap={1}>
                    {isLoading && <CircularProgress size={16} />}
                    {step !== 0 && !isLoading && <Icon name="send" />}
                    {step == 0
                        ? t("CAMPAIGN.CONTINUE")
                        : step === 1
                        ? t("CAMPAIGN.REVIEW_AND_SEND")
                        : t("CAMPAIGN.SEND")}
                </Stack>
            </Button>
        </Paper>
    )
}

export default FormAction
