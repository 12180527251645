import Icon from "@/components/base/Icon"
import TablePaginationActions from "@/components/base/table/PaginationActions"
import SelectBusiness from "@/components/select/select-business"
import SelectRates from "@/components/select/select-rates"
import ReputationStore from "@/store/reputation"
import { Button, Paper, Stack, TablePagination, Typography } from "@mui/material"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ItemReputation from "../item-reputation"
import useStyles from "../styles"

export const ListReputation = ({
    mockData = null,
    items = null,
    page = null,
    setPage = null,
    total = null,
    refBloc = null,
    handleSelectionChange,
    handleSelectionRateChange,
    handleDeleteChange,
}) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { selectedBusinesses, selectedRating } = ReputationStore()
    const navigate = useNavigate()

    const reviews = useMemo(() => {
        return mockData || items
    }, [items, mockData])

    const businessesSelectionChange = useCallback(
        (items) => {
            handleSelectionChange(items)
        },
        [handleSelectionChange]
    )

    const ratesSelectionChange = useCallback(
        (items) => {
            handleSelectionRateChange(items)
        },
        [handleSelectionRateChange]
    )

    const selectBusinessLabel = useMemo(() => {
        if (selectedBusinesses.length === 0 || selectedBusinesses.length > 1) {
            return t("BUSINESSES.TITLE")
        }
        return `${t("BUSINESSES.TITLE_SINGULAR")}`
    }, [selectedBusinesses.length, t])
    return (
        <Stack className={classes.container} ref={refBloc}>
            <Stack gap={2}>
                <Paper className={classes.wrapperBloc}>
                    <Stack flexDirection={"row"} gap={1} className="filter-btn">
                        <SelectBusiness
                            label={selectBusinessLabel}
                            defaultValue={selectedBusinesses}
                            selectionChange={businessesSelectionChange}
                        />
                        <SelectRates
                            onClear={() => ratesSelectionChange([])}
                            selectionChange={ratesSelectionChange}
                            selected={selectedRating ?? []}
                        />
                    </Stack>
                    <Button
                        className="open-campaign"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            navigate("/campaign")
                        }}
                    >
                        <Icon name={"plus"} />
                        <Typography data-testid="user-button" component="span">
                            {t("CAMPAIGN.NEW_OPINION")}
                        </Typography>
                    </Button>
                </Paper>
            </Stack>

            {reviews.map((item) => (
                <ItemReputation key={item.id} data={item} handleDeleteChange={handleDeleteChange} />
            ))}
            <TablePagination
                className={classes.pagination}
                component={"div"}
                labelDisplayedRows={({ from, to, count }) => {
                    return "" + from + "-" + to + " " + t("USERS.PAGING") + " " + count
                }}
                count={total ?? items?.length}
                rowsPerPage={10}
                page={page}
                onPageChange={(__, pageActive) => {
                    setPage(pageActive)
                }}
                rowsPerPageOptions={[]}
                ActionsComponent={TablePaginationActions}
            />
        </Stack>
    )
}
