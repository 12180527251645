import { Stack } from "@mui/material"
import useStyles from "../styles"
import EmailPreview from "../view/EmailPreview"
import SmsPreview from "../view/SmsPreview"

const ThirdStepCampaign = ({ type, html, data, feedback = true, business }) => {
    const { classes } = useStyles()

    return (
        <Stack className={classes.stepBloc} gap={2}>
            {type === "email" && <EmailPreview html={html} contacts={data} feedback={feedback} business={business} />}
            {type === "sms" && <SmsPreview html={html} contacts={data} business={business} />}
        </Stack>
    )
}

export default ThirdStepCampaign
