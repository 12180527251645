import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import IconNames from "@/types/icons"
import { convertToStarArray, getIconStar } from "@/utils"
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material"
import { useMemo } from "react"

interface IBlocNumber {
    label: string
    icon: IconNames
    number: string
    pourcentage: string
    color: string
    tooltip: string
    noData: any
    score: number
    type: "no-data" | "superior" | "inferior"
}

export const BlocStar = ({ label, icon, number, score, pourcentage, color, type, tooltip, noData }: IBlocNumber) => {
    const stars = useMemo(() => {
        const nb = score || 0
        const scoreArray = convertToStarArray(nb)
        return scoreArray
    }, [score])

    return (
        <Paper className="item-view" sx={{ background: color }}>
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                    <Icon name={icon} />
                    <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                        {label}
                    </Typography>
                </Stack>
                <CustomTooltip title={tooltip}>
                    <IconButton sx={{ color: "white" }}>
                        <Icon name="info" />
                    </IconButton>
                </CustomTooltip>
            </Stack>
            <Stack flexDirection={"row"} alignItems={"center"} gap={"10px"} flexWrap="wrap">
                {!noData && (
                    <>
                        <Typography variant={"h2"} className="text-number">
                            {number}
                        </Typography>
                        <Stack flexDirection={"row"} data-e2e="review-stars">
                            {stars.map((i, k) => (
                                <Icon key={k} name={getIconStar(i)} sx={{ color: "white" }} />
                            ))}
                        </Stack>
                    </>
                )}
                {noData && <Typography className="text-nodata">{number}</Typography>}
                {pourcentage != undefined && (
                    <Box className={`pourcentage ${type}`}>
                        <Typography component="span" sx={{ color: "inherit" }}>
                            {pourcentage}
                        </Typography>
                    </Box>
                )}
            </Stack>
        </Paper>
    )
}
