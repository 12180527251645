import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()((theme) => ({
    container: {
        gap: 16,
        "@media (min-width: 1024px)": {
            gap: 24,
        },
    },
    headerList: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 8,
        flexWrap: "wrap",
        padding: 0,
        "@media (min-width: 1024px)": {
            flexWrap: "nowrap",
            gap: 16,
        },
        "& .listing-item": {
            padding: "8px 16px",
        },
        "& .btn-left": {
            width: "100%",

            "@media (min-width: 1024px)": {
                width: "400px",
            },
        },
        "& .btn-right": {
            width: "100%",
            "@media (min-width: 1024px)": {
                width: "200px",
            },
        },
    },
}))
