import { EntitiesApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { FormValues } from "@/types/businesses"
import { openGoogleAccount } from "@/utils/auth"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"

const useLogic = () => {
    const { updateState: setUiPageSession, showPage, previousItem } = UiPageSessionStore()
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const { notif } = useNotification()
    const { profile } = UserSessionStore()
    const [companies, setCompanies] = useState([])
    const refreshListBusinesses = useCallback(async () => {
        try {
            const entity_type: any = "company"
            const response = await EntitiesApi.fetchEntities({
                user_uid: profile.uid,
                entity_type,
            })
            if (response?.companies) {
                const companiesOptions = response.companies.map((item) => {
                    return {
                        label: item.name,
                        value: item.uid,
                    }
                })
                setCompanies(companiesOptions)
            }
        } catch (error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
    }, [profile])

    const MESSAGE_TEXT = useMemo(
        () => ({
            INVALID_REQUEST: t("SYSTEM_ERROR.INVALID_REQUEST"),
            BUSINESS_CREATE_SUCCESS: t("BUSINESSES.CREATE.SUCCESS"),
            FIELD_REQUIRED: t("BUSINESSES.FIELD_REQUIRED"),
            CANCEL: t("USERS.CANCEL"),
            BACK: t("USERS.BACK_TOOLTIP"),
            BUSINESS_CREATE_SAVE: t("BUTTON.CONNECT_TO_GOOGLE"),
            BUSINESS_NAME: t("BUSINESSES.NAME"),
            BUSINESS_CREATE_TITLE: t("BUSINESSES.CREATE.TITLE"),
            EXIST: t("BUSINESSES.CREATE.EXIST"),
        }),
        [t]
    )

    const schema = useMemo(() => {
        return z.object({
            company: z.string().nonempty(MESSAGE_TEXT.FIELD_REQUIRED),
        })
    }, [MESSAGE_TEXT])

    const onSubmit: SubmitHandler<any> = async (data: FormValues) => {
        setLoading(true)
        openGoogleAccount({
            companyId: getValues().company,
            user_id: profile.uid,
        })
    }

    const { handleSubmit, formState, reset, control, getValues, setValue } = useForm<FormValues>({
        defaultValues: {
            company: "",
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
        shouldUnregister: true,
        resetOptions: {
            keepDefaultValues: false,
            keepDirty: false,
            keepIsSubmitted: false,
            keepTouched: false,
            keepValues: false,
        },
    })

    const handleCancelButton = useCallback(() => {
        reset()
        setUiPageSession({ previousItem: null })
        navigateTo("list")
    }, [])

    const handlePreviousButton = useCallback(() => {
        setUiPageSession({ previousItem: getValues()?.company })
        navigateTo("list")
    }, [])

    useEffect(() => {
        if (showPage === "create") {
            refreshListBusinesses()
            if (previousItem) {
                setValue("company", previousItem)
            }
        }
    }, [showPage, refreshListBusinesses])

    return {
        MESSAGE_TEXT,
        handlePreviousButton,
        handleSubmit,
        onSubmit,
        companies,
        t,
        control,
        handleCancelButton,
        loading,
        profile,
        formState,
    }
}

export default useLogic
