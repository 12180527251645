import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { useLogic } from "@/hooks/businesses/disconnect-facebook/useLogic"
import { modalStyle } from "@/pages/users/styles"
import { withSxProp } from "@/utils"
import { Box, Button, CircularProgress, IconButton, Modal, Paper, Typography } from "@mui/material"
import { disconnectModal } from "../container/styles"

const DisconnectFacebookModal = ({ open, onClose, t }) => {
    const { loading, onDisconnect } = useLogic({ callback: onClose })
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper elevation={6} sx={withSxProp(modalStyle, disconnectModal)}>
                <Box className="title-wrapper">
                    <Typography id="modal-modal-title" variant="h2">
                        {t("BUSINESSES.DISCONNECT_FACEBOOK.TITLE")}
                    </Typography>
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton onClick={onClose}>
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
                <Typography id="modal-modal-description">{t("BUSINESSES.DISCONNECT_FACEBOOK.ASK")}</Typography>
                <Box className="action-wrapper">
                    <Button onClick={onClose}>{t("USERS.CANCEL")}</Button>
                    <Button
                        variant="contained"
                        className="disconnect"
                        onClick={onDisconnect}
                        data-e2e="disconnect-facebook-button"
                        disabled={loading}
                    >
                        {loading && <CircularProgress size={16} />}
                        {!loading && <Icon name="disconnect" />}
                        {t("BUSINESSES.DISCONNECT_FACEBOOK.CONFIRM_BUTTON")}
                    </Button>
                </Box>
            </Paper>
        </Modal>
    )
}

export default DisconnectFacebookModal
