import usePhoneInputLogic from "@/hooks/phone-input/useLogic"
import useWindowSize from "@/hooks/useWindowSize"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import { ListTableProps } from "@/types/listTable"
import { isDeepEqual, isValidEmail } from "@/utils"
import {
    Box,
    Button,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material"
import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from "../base/Icon"
import CustomTooltip from "../base/Tooltip"
import TablePaginationActions from "../base/table/PaginationActions"
import { RenderInputEdit } from "./cell-edit"
import useStyles from "./styles"
const RenderBtnAction = ({ toolTipDeleteText, handleDelete, handleEdit, index, toolTipEditText }) => {
    return (
        <>
            {handleDelete && (
                <CustomTooltip title={toolTipDeleteText}>
                    <IconButton onClick={() => handleDelete(index)}>
                        <Icon name="delete-1" />
                    </IconButton>
                </CustomTooltip>
            )}
            {handleEdit && (
                <CustomTooltip title={toolTipEditText}>
                    <IconButton className="edit-btn" onClick={() => handleEdit(index)}>
                        <Icon name="edit" />
                    </IconButton>
                </CustomTooltip>
            )}
        </>
    )
}
const ListTable = ({
    rows,
    handleChangePage,
    className,
    handleDelete,
    handleEdit,
    metadata,
    toolTipRowText,
    toolTipDeleteText,
    toolTipEditText,
    generateLabels,
    headCells,
    editItem,
    handleCancel,
    handleSave,
    multipleCol,
    handleDataChange,
    disableButton,
    changeComponentMobile = true,
}: ListTableProps) => {
    const { updateState: setStateUiPageSession } = UiPageSessionStore()
    const { t } = useTranslation()
    const [width] = useWindowSize()
    const { classes } = useStyles()
    const [editData, setEditData] = useState(editItem)
    const { preferredLanguage } = UiStore()
    const { isPhoneValid } = usePhoneInputLogic()
    const langOfCountryName = useMemo(() => {
        return preferredLanguage?.toLowerCase() || "fr-ca"
    }, [preferredLanguage])

    const handleItemClick = useCallback((row: { uid: string }) => {
        setStateUiPageSession({ selectedItem: row, showPage: "detail" })
    }, [])

    useEffect(() => {
        setEditData(editItem)
    }, [editItem])

    useEffect(() => {
        if (typeof handleDataChange === "function") {
            handleDataChange(editData)
        }
    }, [editData])

    const invalidPhone = useCallback(
        (value: string) => {
            if (editItem) {
                return /^\+\d{1,4}$/.test(value) || value === "" || !isPhoneValid(value)
            }
            return false
        },
        [editItem]
    )
    const invalidEmail = useCallback(
        (value: string) => {
            if (editItem) {
                return value === "" || !isValidEmail(value)
            }
            return false
        },
        [editItem]
    )
    return (
        <TableContainer className={`${classes.tableWrapper} ${className}`}>
            <Table aria-label="custom pagination table">
                {(width >= 768 || !changeComponentMobile) && headCells && (
                    <TableHead className="custom-header">
                        <TableRow>
                            {headCells?.map((cell: any, index: number) => (
                                <CustomTooltip key={`header-${index}`} title={cell.tooltip}>
                                    <TableCell
                                        key={`header-${index}`}
                                        width={cell.width ? cell.width : "33%"}
                                        component="td"
                                        scope="row"
                                        align={cell.align}
                                    >
                                        {cell.name}
                                    </TableCell>
                                </CustomTooltip>
                            ))}
                        </TableRow>
                    </TableHead>
                )}
                <TableBody data-testid="table-test">
                    {rows?.map((row, index: number) => (
                        <TableRow className={rows.length - 1 === index ? "last-child" : ""} key={`${String(index)}`}>
                            {(width >= 768 || !changeComponentMobile) && (
                                <Fragment key={`${String(index)}`}>
                                    {multipleCol
                                        ? Object.keys(row).map((key: string, index: number) => (
                                              <Fragment key={`${String(index)}`}>
                                                  {!["uid"].includes(key) && (
                                                      <TableCell component="td" scope="row">
                                                          {editItem && isDeepEqual(editItem, row) ? (
                                                              <RenderInputEdit
                                                                  originalValue={editItem[key]}
                                                                  value={editData ? editData[key] : ""}
                                                                  index={key}
                                                                  langOfCountryName={langOfCountryName}
                                                                  setData={setEditData}
                                                                  invalidEmail={invalidEmail}
                                                                  invalidPhone={invalidPhone}
                                                              />
                                                          ) : (
                                                              <CustomTooltip
                                                                  disableTouchListener
                                                                  title={toolTipRowText}
                                                                  followCursor
                                                              >
                                                                  <Button
                                                                      className="btn-info"
                                                                      data-testid={row.uid}
                                                                      sx={
                                                                          key == "count"
                                                                              ? { justifyContent: "end !important" }
                                                                              : null
                                                                      }
                                                                      onClick={() => {
                                                                          handleItemClick(row)
                                                                      }}
                                                                  >
                                                                      {row[key] && row[key].toString()}
                                                                  </Button>
                                                              </CustomTooltip>
                                                          )}
                                                      </TableCell>
                                                  )}
                                              </Fragment>
                                          ))
                                        : generateLabels(row).map((label: string, index: number) => (
                                              <TableCell key={`${String(index)}`} component="td" scope="row">
                                                  <CustomTooltip
                                                      disableTouchListener
                                                      title={toolTipRowText}
                                                      followCursor
                                                  >
                                                      <Button
                                                          className="btn-info"
                                                          data-testid={row.uid}
                                                          onClick={() => {
                                                              handleItemClick(row)
                                                          }}
                                                      >
                                                          {label && label.toString()}
                                                      </Button>
                                                  </CustomTooltip>
                                              </TableCell>
                                          ))}
                                </Fragment>
                            )}
                            {width < 768 && changeComponentMobile && (
                                <TableCell
                                    className={editItem && isDeepEqual(editItem, row) && "no-border"}
                                    component="td"
                                    scope="row"
                                >
                                    {editData && editItem && isDeepEqual(editItem, row) ? (
                                        <>
                                            <Stack gap={1} marginY={1}>
                                                {Object.keys(row).map(
                                                    (key: string, index: number) =>
                                                        key !== "uid" && (
                                                            <RenderInputEdit
                                                                originalValue={editItem[key]}
                                                                value={editData ? editData[key] : ""}
                                                                key={`${String(index)}`}
                                                                index={key}
                                                                langOfCountryName={langOfCountryName}
                                                                setData={setEditData}
                                                                invalidEmail={invalidEmail}
                                                                invalidPhone={invalidPhone}
                                                            />
                                                        )
                                                )}
                                            </Stack>
                                            <Box className="list-action-wrapper">
                                                {editItem && isDeepEqual(editItem, row) && (
                                                    <>
                                                        <CustomTooltip
                                                            disableTouchListener
                                                            title={t("CAMPAIGN.BACK")}
                                                            followCursor
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                className="btn btn-cancel"
                                                                onClick={() => {
                                                                    handleCancel()
                                                                }}
                                                            >
                                                                <Icon name="cancel" /> {t("CAMPAIGN.BACK")}
                                                            </Button>
                                                        </CustomTooltip>
                                                        <CustomTooltip
                                                            disableTouchListener
                                                            title={t("CAMPAIGN.SAVE")}
                                                            followCursor
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                className="btn btn-save"
                                                                onClick={() => handleSave(editData, index)}
                                                                disabled={disableButton}
                                                            >
                                                                <Icon name="save" /> {t("CAMPAIGN.SAVE")}
                                                            </Button>
                                                        </CustomTooltip>
                                                    </>
                                                )}
                                            </Box>
                                        </>
                                    ) : (
                                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                            <CustomTooltip disableTouchListener title={toolTipRowText} followCursor>
                                                <Button
                                                    data-testid={row.uid}
                                                    sx={{ display: "block" }}
                                                    onClick={() => {
                                                        handleItemClick(row)
                                                    }}
                                                >
                                                    <>
                                                        {generateLabels(row).map((label: string, index: number) => (
                                                            <Stack key={index} sx={{ margin: 0 }}>
                                                                {label && label.toString()}
                                                            </Stack>
                                                        ))}
                                                    </>
                                                </Button>
                                            </CustomTooltip>
                                            <Box className="list-action-wrapper">
                                                <RenderBtnAction
                                                    toolTipDeleteText={toolTipDeleteText}
                                                    handleDelete={handleDelete}
                                                    handleEdit={handleEdit}
                                                    index={index}
                                                    toolTipEditText={toolTipEditText}
                                                />
                                            </Box>
                                        </Stack>
                                    )}
                                </TableCell>
                            )}
                            {width >= 768 && (handleEdit || handleDelete) && (
                                <TableCell width={"115px"} sx={{ minWidth: "115px" }} component="td" scope="row">
                                    <Box className="list-action-wrapper">
                                        <>
                                            {editItem && isDeepEqual(editItem, row) ? (
                                                <>
                                                    <CustomTooltip
                                                        disableTouchListener
                                                        title={t("CAMPAIGN.BACK")}
                                                        followCursor
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            className="btn btn-cancel"
                                                            onClick={() => {
                                                                handleCancel()
                                                            }}
                                                        >
                                                            <Icon name="cancel" />
                                                        </Button>
                                                    </CustomTooltip>
                                                    <CustomTooltip
                                                        disableTouchListener
                                                        title={t("CAMPAIGN.SAVE")}
                                                        followCursor
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            className="btn btn-save"
                                                            onClick={() => handleSave(editData, index)}
                                                            disabled={disableButton}
                                                        >
                                                            <Icon name="save" />
                                                        </Button>
                                                    </CustomTooltip>
                                                </>
                                            ) : (
                                                <RenderBtnAction
                                                    toolTipDeleteText={toolTipDeleteText}
                                                    handleDelete={handleDelete}
                                                    handleEdit={handleEdit}
                                                    index={index}
                                                    toolTipEditText={toolTipEditText}
                                                />
                                            )}
                                        </>
                                    </Box>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
                {metadata && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelDisplayedRows={({ from, to, count }) => {
                                    return "" + from + "-" + to + " " + t("USERS.PAGING") + " " + count
                                }}
                                count={metadata.total_count}
                                rowsPerPage={metadata.page_size}
                                page={metadata.page_number}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[]}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    )
}
export default ListTable
