import { PrimaryCategoryType } from "@/store/location"
import { CategoriesType, CategoryType } from "@/types/businesses"
import { create } from "zustand"

export interface ICategories {
    primaryCategory: PrimaryCategoryType
    facebookCategory: CategoryType[]
    secondaryCategory: CategoryType[]
    categories?: CategoriesType
}

export interface IOverviewStoreSetter {
    updateCategories: (update: Partial<ICategories>) => void
    getInitialState: () => ICategories
    flush: () => void
}

const initalState = {
    primaryCategory: {
        id: "",
        label: "",
        serviceTypes: [],
    },
    secondaryCategory: [],
    facebookCategory: [],
}

const useBusinessCategoriesStore = create<ICategories & IOverviewStoreSetter>((set) => ({
    ...initalState,
    updateCategories: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default useBusinessCategoriesStore
