import { EntitiesApi } from "@/api"
import useLocationStore from "@/store/location"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { BusinessType } from "@/types/businesses"
import { SelectChangeEvent } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"

export const useSelectBusiness = ({
    selectionChange,
    defaultValue,
}: {
    selectionChange: (value: BusinessType[]) => void
    defaultValue: any[]
}) => {
    const FETCH_BUSINESS_PAGES = useMemo(() => {
        return ["reputation", "listing", "local_presence", "campaign"]
    }, [])
    const { menuPage } = UiPageSessionStore()
    const { update, businesses } = useLocationStore()
    const [selected, setSelected] = useState<string[]>(defaultValue ?? [])
    // const items = useDebounce(selected, 500)
    const { profile } = UserSessionStore()

    const fetchBusinessList = useCallback(async () => {
        if (!profile?.uid) {
            return
        }
        const entities = await EntitiesApi.fetchListsEntities({
            page_number: 0,
            page_size: 1000,
            user_uid: profile?.uid,
            entity_type: "location",
            fields: "uid,name",
        })
        const mappedEntities = entities?.data?.map((location) => ({
            label: location.name,
            value: location.uid,
        }))
        update({ businesses: mappedEntities })
    }, [profile?.uid, update])

    const handleOnSelectionChange = useCallback(
        (event: SelectChangeEvent<any>) => {
            const {
                target: { value },
            } = event
            const res = typeof value === "string" ? value.split(",") : value
            selectionChange(res)
            setSelected(res)
        },
        [selectionChange]
    )

    const handleClear = useCallback(() => {
        setSelected([])
        selectionChange([])
    }, [selectionChange])

    useEffect(() => {
        if (!businesses && FETCH_BUSINESS_PAGES.includes(menuPage)) {
            fetchBusinessList()
        }
    }, [fetchBusinessList, businesses, FETCH_BUSINESS_PAGES, menuPage])

    return {
        handleOnSelectionChange,
        handleClear,
        selected,
    }
}
