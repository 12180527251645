import { Paper, Skeleton, Stack } from "@mui/material"

export const SkeletonReputationItem = () => {
    return (
        <Paper>
            <Stack flexDirection={"row"} justifyContent="space-between">
                <Skeleton variant="rectangular" width={300} height={24} />
                <Skeleton variant="rectangular" width={200} height={24} />
            </Stack>
            <Stack gap={1}>
                <Skeleton variant="rectangular" width={200} height={24} />
                <Skeleton variant="rectangular" width={150} height={24} />
            </Stack>
            <Stack gap={1}>
                <Skeleton variant="rectangular" width={"100%"} height={16} />
                <Skeleton variant="rectangular" width={"100%"} height={16} />
                <Skeleton variant="rectangular" width={"90%"} height={16} />
            </Stack>
            <Skeleton variant="rectangular" width={"100%"} height={100} />
        </Paper>
    )
}
