import { MAX_INPUT } from "@/data/limiter"
import { useDebounce } from "@/hooks/useDebounce"
import useLoadGoogleMap from "@/hooks/useLoadGoogleMap"
import UiStore from "@/store/ui"
import { debounce } from "@mui/material/utils"
import * as React from "react"
import { useTranslation } from "react-i18next"
import BaseAutocomplete from "../../base/autocomplete"
import { GPlaceCallback, GPlaceRequest } from "../address-autocomplete/types"
const autocompleteService = { current: null }

export default function GooglePlaceAutocomplete(props: any) {
    useLoadGoogleMap()

    const {
        label,
        onSelect,
        field,
        max = MAX_INPUT.TEXT,
        maxItems = 20,
        helperText = "",
        helperTextPosition = "end",
    } = props
    const [inputValue, setInputValue] = React.useState<string>("")
    const [options, setOptions] = React.useState<any[]>([])
    const searchValue = useDebounce(inputValue, 400)
    const { preferredLanguage } = UiStore()
    const [open, setOpen] = React.useState(false)
    const { t } = useTranslation()
    const [loading, setLoading] = React.useState(false)

    const types = React.useMemo(() => {
        if (props?.types?.length > 0) {
            return props.types
        }
        return ["address"]
    }, [props?.types])

    const requestOpts = React.useMemo(() => {
        return {
            language: preferredLanguage,
            types,
        }
    }, [preferredLanguage, types])

    const fetchApi = React.useMemo(() => {
        const requestGPlaceApi = (request: GPlaceRequest, callback: GPlaceCallback) => {
            return (autocompleteService.current as any).getPlacePredictions({ ...request, ...requestOpts }, callback)
        }
        return debounce(requestGPlaceApi, 400)
    }, [requestOpts])

    React.useEffect(() => {
        let active = true

        if (!autocompleteService.current && (window as any)?.google?.maps?.places) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
        }

        if (!autocompleteService.current) {
            return undefined
        }
        if (searchValue === "" || searchValue?.length < 3) {
            setOpen(false)
            setOptions([])
            return undefined
        }

        const request: GPlaceRequest = {
            input: searchValue,
        }
        setLoading(true)
        fetchApi(request, (results?: any[]) => {
            if (active) {
                let newOptions: any[] = []
                if (results) {
                    const data =
                        results
                            .filter((res) => res.place_id)
                            .map((res) => ({
                                description: res.description,
                                placeId: res.place_id,
                            })) || []
                    newOptions = [...data]
                }
                setOpen(newOptions?.length > 0)
                setOptions(newOptions)
            }
            setLoading(false)
        })
        return () => {
            active = false
        }
    }, [searchValue, fetchApi])

    return (
        <BaseAutocomplete
            type="google-place"
            inputCssProps={{
                $multiple: true,
                $multipleelement: field?.length > 0 ? true : false,
            }}
            id="google-place"
            data-testid="google-place"
            inputValue={inputValue}
            getOptionLabel={(option: any) => option.description ?? ""}
            open={open}
            options={options}
            includeInputInList
            value={field}
            getOptionDisabled={() => field?.length >= maxItems}
            isOptionEqualToValue={(option: any, value: any) => option.description === value.description}
            onClose={() => {
                setOpen(false)
            }}
            onChange={(_: any, newValue: any) => {
                setOptions(newValue ? [newValue, ...options] : options)
                if (typeof onSelect === "function" && newValue) {
                    onSelect(newValue)
                }
            }}
            onInputChange={(event, newInputValue) => {
                if (newInputValue.length <= MAX_INPUT.TEXT) {
                    setInputValue(newInputValue)
                }
            }}
            loading={loading}
            loadingText={t("AUTOCOMPLETE.LOADING")}
            noOptionsText={t("AUTOCOMPLETE.NO_OPTIONS")}
            multiple
            label={label}
            helperText={helperText}
            helperTextPosition={helperTextPosition}
        />
    )
}
