import useFormater from "@/hooks/useFormater"
import { Box, Button, IconButton, Popover, Stack, Typography } from "@mui/material"
import { RefObject, useRef } from "react"
import { useTranslation } from "react-i18next"
import Icon from "../../Icon"
import CustomTooltip from "../../Tooltip"
import useStyles from "../style"

interface PropsIconAddModel {
    containerRef: RefObject<HTMLDivElement>
    listReviews: any
    handleToggleModal: () => void
    open: boolean
    setIsPreview: (value: boolean) => void
    handleClose: () => void
    setOpen: (value: boolean) => void
    editorType: "normal" | "email" | "sms"
    handleChangeModel: (content: string, subject: string) => void
}

export const IconAddModel = ({
    containerRef,
    listReviews,
    handleToggleModal,
    open,
    handleClose,
    setOpen,
    editorType,
    handleChangeModel,
    setIsPreview,
}: PropsIconAddModel) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const refModel = useRef<any>(null)
    const { parseMentionPreferLang } = useFormater()

    return (
        <div>
            <CustomTooltip title={t("CAMPAIGN.MODEL_TOOLTIP")}>
                <IconButton ref={refModel} onClick={handleToggleModal} className="btn-x">
                    <Icon name="file" sx={{ fill: "none" }} />
                </IconButton>
            </CustomTooltip>

            <Popover
                id="bloc-model"
                container={containerRef?.current}
                className={classes.blocModel}
                anchorEl={refModel.current}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{
                    maxWidth: editorType === "sms" ? "300px" : "575px",
                }}
                classes={{
                    paper: "div-model-paper",
                }}
            >
                <Box className="content-model">
                    <Stack className="header-model">
                        <Button variant="outlined" className="btn-edit-model" onClick={() => setOpen(true)}>
                            {t("CAMPAIGN.MODEL.MODIFY")}
                        </Button>
                    </Stack>
                    <Stack className={listReviews?.length > 0 ? "list-model" : ""}>
                        {listReviews &&
                            listReviews.map((item) => {
                                return (
                                    <Stack
                                        key={item.id}
                                        className="item-model"
                                        onClick={() => {
                                            handleChangeModel(parseMentionPreferLang(item.content), item.subject)
                                            handleClose()
                                            setIsPreview(false)
                                        }}
                                        onMouseEnter={() => {
                                            if (open) {
                                                handleChangeModel(parseMentionPreferLang(item.content), item.subject)
                                                setIsPreview(true)
                                            }
                                        }}
                                        onMouseLeave={() => {
                                            if (open) {
                                                handleChangeModel("", "")
                                                setIsPreview(false)
                                            }
                                        }}
                                    >
                                        <Typography variant={"h6"}>{item.title}</Typography>
                                        <div
                                            className="ellipse-two-line mention-blue"
                                            dangerouslySetInnerHTML={{
                                                __html: parseMentionPreferLang(
                                                    item.content
                                                        .replaceAll("</p><p><br></p><p>", " ")
                                                        .replaceAll("</p><p>", " ")
                                                ),
                                            }}
                                        />
                                    </Stack>
                                )
                            })}
                    </Stack>
                </Box>
            </Popover>
        </div>
    )
}
