import Icon from "@/components/base/Icon"
import { PlatformLocationMap } from "@/services/listing"
import { Box, List, ListItem, ListItemText, Stack, Typography } from "@mui/material"
import { iconWrapper, listItemText, listSx } from "./styles"

const WebsitesList = ({ items, viewType }) => {
    const isViewTypeLocation = viewType === "location"
    return (
        <List sx={listSx()}>
            {items.map((item: PlatformLocationMap) => (
                <ListItem key={item.id}>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Stack
                            direction={"row"}
                            gap={"16px"}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    ...iconWrapper,
                                    borderRadius: isViewTypeLocation ? "8px" : "50% 50%",
                                    p: isViewTypeLocation ? 0 : 1,
                                }}
                            >
                                {item.logo ? (
                                    <img
                                        src={item.logo}
                                        alt={item.name}
                                        width={56}
                                        height={56}
                                        style={{ borderRadius: isViewTypeLocation ? "8px" : "50% 50%" }}
                                    />
                                ) : (
                                    <Icon name="image-placeholder" sx={{ minWidth: 56, height: 56 }} />
                                )}
                            </Box>
                            <ListItemText sx={listItemText}>{item.name || ""}</ListItemText>
                        </Stack>
                        <Stack direction={"column"} gap={1}>
                            <Stack gap={0.75} direction={"row"} alignItems={"center"}>
                                <Icon
                                    name="checkCircle"
                                    color="success.main"
                                    sx={{
                                        width: 24,
                                        height: 24,
                                    }}
                                />
                                <Typography
                                    component={"span"}
                                    sx={{
                                        color: "action.active",
                                        fontSize: 20,
                                        fontWeight: 500,
                                    }}
                                >
                                    {item.synchronised || 0}
                                </Typography>
                            </Stack>
                            <Stack gap={0.75} direction={"row"} alignItems={"center"}>
                                <Icon
                                    name="refreshCircle"
                                    color="warning.main"
                                    sx={{
                                        width: 24,
                                        height: 24,
                                    }}
                                />
                                <Typography
                                    component={"span"}
                                    sx={{ color: "action.active", fontSize: 20, fontWeight: 500 }}
                                >
                                    {item.inProgress || 0}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </ListItem>
            ))}
        </List>
    )
}

export default WebsitesList
