import Icon from "@/components/base/Icon"
import { SearchPropsType } from "@/hooks/businesses/list/useLogic"
import { CookiesService } from "@/services"
import IconNames from "@/types/icons"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Paper,
    Typography,
} from "@mui/material"
import { Dispatch, Fragment, MouseEventHandler, SetStateAction } from "react"
import Filter from "./filter"
import useStyles, { actionBtnSx, btnTextSx, stackSx } from "./styles"

interface TitleWrapperProps {
    title: string
    pageIcon: IconNames
    buttonIcon?: IconNames
    buttonText?: string
    onClickButton?: MouseEventHandler
    showActionButton?: boolean
    alert?: {
        title: string
        contents: string
    }
    withSearch?: SearchPropsType
    setWithSearch?: Dispatch<SetStateAction<SearchPropsType>>
    loading?: boolean
    loadingCheckLicence?: boolean
}

const TitleWrapper = ({
    title,
    pageIcon,
    onClickButton,
    buttonIcon,
    buttonText,
    showActionButton,
    alert,
    withSearch,
    setWithSearch,
    loading,
    loadingCheckLicence,
}: TitleWrapperProps) => {
    const { classes } = useStyles({ withSearch })()

    return (
        <Box className={classes.wrapper}>
            <Box className="title">
                <Icon name={pageIcon} />
                <Typography data-testid="user-title" variant="h1">
                    {title}
                </Typography>
            </Box>
            {!loading && (
                <Fragment>
                    {alert && (
                        <Accordion
                            className={classes.MuiAccordion}
                            defaultExpanded={CookiesService.get("expandGoogleBanner") ?? true}
                            onChange={(event: React.SyntheticEvent, expanded: boolean) => {
                                CookiesService.set({ expandGoogleBanner: expanded })
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "#5F2120" }} />}
                                className="summary"
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <Icon size={22} sx={{ marginRight: "18px" }} name="google" />
                                <Typography className="title-accordion">{alert.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className="detail">
                                <Typography
                                    className="subtitle-accordion"
                                    dangerouslySetInnerHTML={{ __html: alert.contents }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {showActionButton && (
                        <Paper className="search-wrapper" sx={withSearch && stackSx}>
                            <Button
                                className="action-btn"
                                color="primary"
                                sx={withSearch && actionBtnSx}
                                variant="contained"
                                onClick={onClickButton}
                            >
                                {loadingCheckLicence && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                {!loadingCheckLicence && <Icon name={buttonIcon ?? "plus"} />}
                                <Typography data-testid="user-button" component="span" sx={btnTextSx}>
                                    {buttonText}
                                </Typography>
                            </Button>

                            {withSearch && <Filter setWithSearch={setWithSearch} withSearch={withSearch} />}
                        </Paper>
                    )}
                </Fragment>
            )}
        </Box>
    )
}

export default TitleWrapper
