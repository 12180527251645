import SerializedString from "@/components/serialized-string"
import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import ErrorMessage from "../../error-message"
import OverviewTitle from "../title"

const OverviewDescription = ({ sx, location, isLoading }) => {
    const { t } = useTranslation()
    const { navigateTo } = useBusinessLogic()
    return (
        <>
            {location && location.description && (
                <Paper
                    elevation={2}
                    className="clickable"
                    id="description"
                    sx={sx}
                    onClick={() => !isLoading && navigateTo("edit-description")}
                >
                    <OverviewTitle
                        title={t("BUSINESSES.LONG_DESCRIPTION")}
                        // icon="file"
                        isSecondTitle
                        tooltipText={t("BUSINESSES.EDIT_CONTENTS.MAIN_TOOLTIP")}
                    />
                    {(!location?.description || !location?.description?.short) && <ErrorMessage />}
                    {location.description?.short && <SerializedString text={location.description?.short ?? ""} />}
                </Paper>
            )}
        </>
    )
}

export default OverviewDescription
