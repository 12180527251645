import { ReviewType } from "@/api/reputation"
import { SelectType } from "@/types/select"
import { create } from "zustand"

interface PayloadFilter {
    _page: number
    _perPage: number
    _sortBy: string
    _sortOrder: number
    locationsId: string[]
    rating: number[]
    reply: any
}

export interface IReputationStore {
    reviews: ReviewType[]
    refreshItems: boolean
    selectedBusinesses: SelectType[]
    selectedRating: SelectType[]
    loading: boolean
    alreadyLoaded: boolean
    alert: IAlert
    payloadFilter: PayloadFilter
}

export interface IAlert {
    title: string
    contents: string
}

export interface IReputationSetter {
    update?: (values: Partial<IReputationStore>) => void
    updatePayloadFilter: (values: Partial<PayloadFilter>) => void
    flush?: () => void
}

const initalState: IReputationStore = {
    reviews: [],
    refreshItems: false,
    selectedBusinesses: [],
    selectedRating: [],
    loading: false,
    alreadyLoaded: false,
    alert: null,
    payloadFilter: {
        _page: 1,
        _perPage: 10,
        _sortBy: "createdAt",
        _sortOrder: -1,
        locationsId: [],
        rating: [],
        reply: null,
    },
}

const ReputationStore = create<IReputationStore & IReputationSetter>((set) => ({
    ...initalState,
    update: (values) => set(() => ({ ...values })),
    updatePayloadFilter: (values) => set((state) => ({ payloadFilter: { ...state.payloadFilter, ...values } })),
    flush: () => set(initalState),
}))

export default ReputationStore
