import UserSessionStore from "@/store/user-session"
import { decode64AndParse } from "@/utils"
import { useMemo } from "react"
const useProfileInfo = () => {
    const { profile, role } = UserSessionStore()
    const isAdminCompany = useMemo(() => {
        if (profile.uid && role) {
            const roleList: any = decode64AndParse(role)
            if (
                roleList &&
                (roleList.includes("ROLE_ADMIN_LOCATION") || roleList.includes("ROLE_ADMIN_ESTABLISHMENT"))
            ) {
                return false
            }
        }
        return true
    }, [profile, role])

    return {
        isAdminCompany,
    }
}

export default useProfileInfo
