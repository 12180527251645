import { Button, CircularProgress, IconButton, Popover, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import Icon from "../base/Icon"
import useStyles, { menuSx } from "./styles"
import { ChangeEvent, useCallback, useMemo, useState } from "react"
import BaseInput from "../base/input"
import data from "@emoji-mart/data"
import Picker from "@emoji-mart/react"
import UiStore from "@/store/ui"

const InputEmojiCustom = ({ text, setText, handleSave, isModify, handleCancel, isLoading, maxLength }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { preferredLanguage } = UiStore()
    const lengthError = useMemo(() => {
        return !text
    }, [text])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = useMemo(() => Boolean(anchorEl), [anchorEl])

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [setAnchorEl])

    const toggleMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(!open ? event.currentTarget : null)
        },
        [setAnchorEl, open]
    )

    return (
        <Stack className={classes.blocInputEmoji}>
            <Stack className={classes.emojiInput} data-e2e="review-replay-input">
                <BaseInput
                    multiline
                    helperText={`${text?.length || "0"}/4096`}
                    helperTextPosition="right"
                    value={text}
                    placeholder={t("REPUTATION.PLACEHOLDER_ANSWER")}
                    max={maxLength}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <Stack className="section-emoji">
                    <Stack className="bloc-emoji">
                        <IconButton onClick={toggleMenu} className="btn-x">
                            <Icon name="emoji" sx={{ fill: "none" }} />
                        </IconButton>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            id={"basic-menu"}
                            sx={menuSx}
                        >
                            <Stack className="bloc-emoji">
                                <Picker
                                    data={data}
                                    locale={preferredLanguage.split("-")[0]}
                                    onEmojiSelect={(e) => {
                                        setText((prev) => {
                                            if (prev.length <= 4096 - 2) {
                                                return prev + e.native
                                            }
                                            return prev
                                        })
                                    }}
                                />
                            </Stack>
                        </Popover>
                    </Stack>
                    <Stack className="btn-input-emoji">
                        {isModify && (
                            <>
                                <Button
                                    variant={"contained"}
                                    className="btn-cancel"
                                    onClick={() => handleCancel()}
                                    data-e2e="cancel-review"
                                    disabled={isLoading}
                                >
                                    {t("REPUTATION.CANCEL")}
                                </Button>
                                <Button
                                    variant={"contained"}
                                    onClick={() => handleSave(text)}
                                    disabled={lengthError}
                                    data-e2e="save-review"
                                >
                                    {isLoading && <CircularProgress size={16} sx={{ width: 20, height: 20 }} />}
                                    {!isLoading && <Icon name="save" sx={{ width: 20, height: 20 }} />}
                                    {t("REPUTATION.SAVE")}
                                </Button>
                            </>
                        )}
                        {!isModify && (
                            <Button
                                variant={"contained"}
                                onClick={() => handleSave(text)}
                                disabled={lengthError}
                                data-e2e="send-review"
                            >
                                {isLoading && <CircularProgress size={16} sx={{ width: 20, height: 20 }} />}
                                {!isLoading && <Icon name="send" sx={{ width: 20, height: 20 }} />}
                                {t("REPUTATION.SEND")}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default InputEmojiCustom
