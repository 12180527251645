import useRedirectLogic from "@/hooks/useRedirectLogic"
import UserSessionStore from "@/store/user-session"
import { Outlet } from "react-router-dom"

const PublicOutlet = () => {
    const { isLoggedIn } = UserSessionStore()
    useRedirectLogic()

    return <>{!isLoggedIn ? <Outlet /> : <></>}</>
}

export default PublicOutlet
