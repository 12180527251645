import Icon from "@/components/base/Icon"
import SelectTimeField from "@/components/select/select-time-field"
import { TimeFieldSx } from "@/components/select/select-time-field/style"
import useLogic from "@/hooks/businesses/edit-exceptional-schedule/edit-exceptional-item/useLogic"
import { ExceptionalScheduleItemType } from "@/types/businesses"
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, Paper, Switch, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { enUS, frFR } from "@mui/x-date-pickers/locales"
import dayjs from "dayjs"
import "dayjs/locale/en"
import "dayjs/locale/fr"
import { Dispatch, Fragment, SetStateAction } from "react"
import useWindowSize from "@/hooks/useWindowSize"
import { ActionBtn } from "../action-btn"
interface ExceptionalScheduleItemProps {
    list: ExceptionalScheduleItemType[]
    setDays: Dispatch<SetStateAction<ExceptionalScheduleItemType[]>>
    day: ExceptionalScheduleItemType
    scheduleIndex: number
    isFormSubmitted: boolean
}

const ExceptionalScheduleItem = ({ day, scheduleIndex, isFormSubmitted }: ExceptionalScheduleItemProps) => {
    const {
        classes,
        t,
        duplicateCurrentItem,
        onChangeDate,
        deleteCurrentItem,
        handleChangeClose,
        preferredLanguage,
        handleChange24,
        handleSelectTimefield,
        onChangeSchedule,
    } = useLogic({ scheduleIndex })
    const [width] = useWindowSize()
    return (
        <Paper elevation={2} className={`${classes.exceptionalItem}`}>
            <Box className="inputs">
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={
                        preferredLanguage === "en"
                            ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
                            : frFR.components.MuiLocalizationProvider.defaultProps.localeText
                    }
                    adapterLocale={preferredLanguage === "en" ? "en" : "fr"}
                >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                        <DatePicker
                            desktopModeMediaQuery="@media anypointer:fine"
                            sx={TimeFieldSx}
                            defaultValue={dayjs(day?.startDate)}
                            value={day?.startDate && dayjs(day?.startDate)}
                            onChange={(value) => onChangeDate(value)}
                            minDate={dayjs()}
                            slotProps={{
                                textField: {
                                    helperText: ((!day?.dateValid && day?.startDate) || !day?.startDate) &&
                                        isFormSubmitted && (
                                            <Typography day-testid="error" component={"span"} className="error">
                                                {t(
                                                    `BUSINESSES.SCHEDULE.${
                                                        !day?.startDate ? "DATE_ERROR" : "DATE_ERROR_DUPLICATE"
                                                    }`
                                                )}
                                            </Typography>
                                        ),
                                    error: Boolean((!day?.dateValid && day?.startDate) || !day?.startDate),
                                },
                            }}
                        />
                        {width < 768 && (
                            <ActionBtn
                                index={scheduleIndex}
                                duplicateCurrentItem={duplicateCurrentItem}
                                deleteCurrentItem={deleteCurrentItem}
                                day={day}
                            />
                        )}
                    </Box>
                </LocalizationProvider>

                <Box className="switch-and-time">
                    <FormGroup sx={{ marginTop: "4px" }}>
                        <FormControlLabel
                            control={<Switch />}
                            onChange={handleChangeClose}
                            label={day?.isOpen ? t("BUSINESSES.SCHEDULE.OPEN") : t("BUSINESSES.SCHEDULE.CLOSE")}
                            checked={day?.isOpen}
                        />
                    </FormGroup>
                    <Box className="inputs-hours">
                        <Box className="time-container">
                            {day?.isOpen &&
                                !day?.isAvailableAllDay &&
                                day?.times?.map((time, index) => (
                                    <Fragment key={`${String(index)}`}>
                                        <Box className="time-wrapper" key={`${String(index)}`}>
                                            <SelectTimeField
                                                onChange={(value) => {
                                                    handleSelectTimefield(value, index, "start")
                                                }}
                                                defaultValue={time.startTxt}
                                                shouldDisableTime={() => {
                                                    return !time.startValid
                                                }}
                                            />
                                            <Typography component={"span"} className="separator">
                                                -
                                            </Typography>
                                            <SelectTimeField
                                                onChange={(value) => handleSelectTimefield(value, index, "end")}
                                                defaultValue={time.endTxt}
                                                shouldDisableTime={() => {
                                                    return !time.endValid
                                                }}
                                            />
                                            <IconButton
                                                className="plus"
                                                onClick={() => {
                                                    onChangeSchedule(index > 0 ? "delete" : "add", index)
                                                }}
                                            >
                                                <Icon name={index > 0 ? "delete-1" : "plus"} />
                                            </IconButton>
                                        </Box>
                                        {(!time.endValid || !time.startValid) && isFormSubmitted && (
                                            <Typography
                                                data-testid="error"
                                                component={"span"}
                                                className="error time-error"
                                            >
                                                {t(
                                                    `BUSINESSES.SCHEDULE.${
                                                        !time.start || !time.end ? "HOUR_ERROR" : "HOUR_ERROR_ANTERIOR"
                                                    }`
                                                )}
                                            </Typography>
                                        )}
                                    </Fragment>
                                ))}
                        </Box>

                        {day?.isOpen && (
                            <FormGroup className="whole-day">
                                <FormControlLabel
                                    checked={day?.isAvailableAllDay}
                                    onChange={() => handleChange24(scheduleIndex)}
                                    control={<Checkbox />}
                                    label={t("BUSINESSES.SCHEDULE.24H")}
                                />
                            </FormGroup>
                        )}
                    </Box>
                </Box>
            </Box>
            {width >= 768 && (
                <ActionBtn
                    index={scheduleIndex}
                    duplicateCurrentItem={duplicateCurrentItem}
                    deleteCurrentItem={deleteCurrentItem}
                    day={day}
                />
            )}
        </Paper>
    )
}

export default ExceptionalScheduleItem
