import BlocSkeleton from "@/components/base/bloc-skeleton"
import useFormater from "@/hooks/useFormater"
import { urlify } from "@/utils"
import { Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import useStyles from "../styles"

export default function SmsPreview({ html, contacts, business }) {
    const { classes } = useStyles()
    const { parseVariables } = useFormater()

    const text = useMemo(() => {
        return urlify(parseVariables(html, contacts, business))
    }, [contacts, parseVariables, urlify])
    return (
        <Stack className={classes.smsBloc} gap={3}>
            <Stack gap={1} alignItems="center">
                <Stack className="rectangle"></Stack>
            </Stack>
            <Stack gap={2} sx={{ width: "100%" }}>
                <BlocSkeleton width="70%" totalSkeleton={2} />
                <Stack className="bloc-message">
                    <Typography
                        component="div"
                        color="white"
                        sx={{ wordBreak: "normal", whiteSpace: "pre-line" }}
                        dangerouslySetInnerHTML={{ __html: text }}
                    ></Typography>
                </Stack>
                <BlocSkeleton width="50%" totalSkeleton={2} />
            </Stack>
        </Stack>
    )
}
