import BaseAppBar from "@/components/base/app-bar"
import { DrawerBloc } from "@/components/business/drawer-bloc"
import { PageProps } from "@/types/page"
import { Box } from "@mui/material"
import useStyles from "./styles"

const OverViewContainer = ({ onClose, open, children }: PageProps) => {
    const { classes } = useStyles()

    return (
        <DrawerBloc
            disableEscapeKeyDown
            hideBackdrop
            anchor={"right"}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            classPaper={classes.container}
        >
            <BaseAppBar />
            <Box className="children-wrapper">{children}</Box>
        </DrawerBloc>
    )
}

export default OverViewContainer
