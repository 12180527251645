import CategoryAutocomplete from "@/components/autocomplete/category-autocomplete"
import { GoogleCategoriesOptionsType } from "@/components/autocomplete/category-autocomplete/type"
import Icon from "@/components/base/Icon"
import { TitleWrapper } from "@/components/base/title-wrapper"
import FormContainer from "@/components/business/form-container/edit"
import FormAction from "@/components/business/form-container/edit/form-action"
import useLogic from "@/hooks/businesses/edit-category/useLogic"
import { PageProps } from "@/types/page"
import { Box, Paper, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const EditCategory = ({ onClose, open }: PageProps) => {
    const { t } = useTranslation()
    const {
        loading,
        defaultValues,
        defaultPrimary,
        defaultSecondary,
        defaultFacebook,
        location,
        primaryValue,
        isFormValid,
        handleSubmit,
        onSubmit,
        onErrors,
        setValue,
        handlePrevious,
        handleCancelButton,
        setDefaultValues,
    } = useLogic()

    return (
        <FormContainer open={open} onClose={onClose}>
            <TitleWrapper
                handlePreviousButton={handlePrevious}
                data-testid="title"
                title={t("BUSINESSES.EDIT_CATEGORIES.TITLE")}
            />
            <form noValidate onSubmit={handleSubmit(onSubmit, onErrors)}>
                <Box className="inputs-container">
                    <Paper className="inputs-wrapper">
                        <Typography variant="h2">
                            <Icon name="tag" />
                            {t("BUSINESSES.EDIT_CATEGORIES.MAIN_CATEGORY_SHORT_INFOS")}
                        </Typography>

                        <Box className="wrapper" data-e2e="primary-category">
                            <CategoryAutocomplete
                                setCategory={(category: GoogleCategoriesOptionsType) => {
                                    setValue("primaryCategory", category?.value || "")
                                    setDefaultValues({
                                        ...defaultValues,
                                        primaryCategory: {
                                            title: category?.title || "",
                                            value: category?.value || "",
                                        },
                                    })
                                }}
                                filteredCategory={defaultSecondary}
                                required
                                defaultValue={defaultValues?.primaryCategory}
                                label={t("BUSINESSES.EDIT_CATEGORIES.MAIN_CATEGORY_LONG_INFOS")}
                            />
                        </Box>
                    </Paper>
                    <Paper className="inputs-wrapper" data-e2e="secondary-category">
                        <Typography variant="h2">
                            <Icon name="tag" />
                            {t("BUSINESSES.EDIT_CATEGORIES.SECOND_CATEGORY_SHORT_INFOS")}
                        </Typography>

                        <CategoryAutocomplete
                            multiple
                            filteredCategory={defaultPrimary}
                            setCategory={(category: GoogleCategoriesOptionsType[]) => {
                                setValue("secondaryCategory", category.length ? category.map((cat) => cat.value) : [])
                            }}
                            defaultValue={defaultValues?.secondaryCategory}
                            label={t("BUSINESSES.EDIT_CATEGORIES.SECOND_CATEGORY_LONG_INFOS")}
                            maxItems={20}
                            helperText={`${defaultSecondary?.length}/20`}
                        />
                    </Paper>
                    {location?.meta?.facebook_id && (
                        <Paper className="inputs-wrapper" data-e2e="facebook-category">
                            <Typography variant="h2">
                                <Icon name="tag" />
                                {t("BUSINESSES.FACEBOOK_CATEGORY")}
                            </Typography>

                            <CategoryAutocomplete
                                required
                                multiple
                                filteredCategory={defaultFacebook}
                                categoryType="facebook"
                                setCategory={(category: GoogleCategoriesOptionsType[]) => {
                                    setValue(
                                        "facebookCategory",
                                        category.length ? category.map((cat) => cat.value) : []
                                    )
                                }}
                                defaultValue={defaultValues?.facebookCategory}
                                maxItems={3}
                                label={t("BUSINESSES.FACEBOOK_CATEGORY")}
                                helperText={`${defaultFacebook?.length}/3`}
                            />
                        </Paper>
                    )}
                </Box>
                <FormAction
                    handleCancel={handleCancelButton}
                    isDisabled={!primaryValue || !isFormValid}
                    isLoading={loading}
                    isUpdating
                />
            </form>
        </FormContainer>
    )
}

export default EditCategory
