import { convertBigNumber } from "@/utils"

class ChartService {
    createFormatter =
        (key: string, graphs, isPourcentage = true) =>
        (value, series) => {
            if (key === "total") {
                return this.getElementTooltip({
                    pourcentageNumber:
                        graphs.pourcentages?.length > 0 && !isNaN(graphs.pourcentages[series.dataPointIndex][key])
                            ? graphs.pourcentages[series.dataPointIndex][key]
                            : null,
                    value: graphs.totalCurrent[series.dataPointIndex],
                    isPourcentage,
                })
            } else {
                return this.getElementTooltip({
                    pourcentageNumber:
                        graphs.pourcentages?.length > 0 ? graphs.pourcentages[series.dataPointIndex][key] : 0,
                    value: value,
                    isPourcentage,
                })
            }
        }

    getElementTooltip = ({ pourcentageNumber, value, isPourcentage }) => {
        let pourcentage = pourcentageNumber
        let className = "normal"

        if (pourcentage === 0) {
            pourcentage = `+${String(pourcentage)}`
            className = "blue"
        } else if (Math.sign(pourcentage) === -1) {
            pourcentage = String(pourcentage)
            className = "red"
        } else if (pourcentage > 0) {
            pourcentage = `+${String(pourcentage)}`
            className = "green"
        }

        const val: string | number = convertBigNumber(value)
        if (typeof value !== "undefined" && pourcentage) {
            return val + `<span class='${className}'> ${pourcentage}${isPourcentage ? "%" : ""}</span>`
        }
        return val
    }
}

export default new ChartService()
