import Icon from "@/components/base/Icon"
import IconNames from "@/types/icons"
import { Box, Typography } from "@mui/material"
import { Fragment } from "react"

const SocialNetwork = ({ linksNetwork }) => {
    return (
        <>
            {linksNetwork &&
                Object.keys(linksNetwork).length > 0 &&
                Object.keys(linksNetwork).map((linkKey, key) => (
                    <Fragment key={key}>
                        {linksNetwork[linkKey]?.value !== "" && linkKey !== "appointment" && (
                            <Box className="line-wrapper" data-testid="linkNetwork">
                                <Icon name={linksNetwork[linkKey].icon as IconNames} />
                                <Typography component={"span"} className="link-url">
                                    {linksNetwork[linkKey].value}
                                </Typography>
                            </Box>
                        )}
                    </Fragment>
                ))}
        </>
    )
}

export default SocialNetwork
