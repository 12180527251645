import { CookiesService } from "@/services"
import { LocationType } from "@/types/businesses"
import { ApiService } from "../"
import { ErrorResponse, ResponseMetadata } from "../base"
import { CompanyInfo } from "../reseller"

interface EntitiesFields {
    locations: { uid: string }[]
}

interface FetchEntitiesProps {
    page_number?: number
    page_size?: number
    user_uid?: string
    entity_type?: string
    fields?: string
}

interface FetchEntitiesOtherData {
    metadata?: ResponseMetadata
    data?: LocationType[] & CompanyInfo[]
}

interface FetchEntitiesResponse extends Partial<FetchEntitiesOtherData> {
    locations: LocationType[]
    companies: CompanyInfo[]
}

interface FetchEntitiesWithFieldsProps {
    user_uid: string
    entity_type: string
    fields: string[]
}

class EntitiesApi {
    fetchListsEntities = async ({
        page_number,
        page_size,
        user_uid,
        entity_type,
        fields = "",
    }: FetchEntitiesProps): Promise<FetchEntitiesResponse & Partial<ErrorResponse>> => {
        return ApiService.get(`/entities`, {
            page_number,
            page_size,
            user_uid,
            reseller_uid: CookiesService.get("resellerUid"),
            entity_type,
            fields,
        })
    }

    fetchEntities = async ({
        user_uid,
        entity_type,
    }: FetchEntitiesProps): Promise<FetchEntitiesResponse & Partial<ErrorResponse>> => {
        return ApiService.get(`/entities`, {
            user_uid,
            reseller_uid: CookiesService.get("resellerUid"),
            entity_type,
        })
    }

    fetchEntitiesWithFields = async ({
        user_uid,
        entity_type,
        fields,
    }: FetchEntitiesWithFieldsProps): Promise<EntitiesFields & Partial<ErrorResponse>> => {
        return ApiService.get(`/entities`, {
            user_uid,
            reseller_uid: CookiesService.get("resellerUid"),
            entity_type,
            fields,
        })
    }
}

export default new EntitiesApi()
