import Icon from "@/components/base/Icon"
import env from "@/config/env"
import { modalStyle } from "@/pages/users/styles"
import { withSxProp } from "@/utils"
import { openGoogleAccount } from "@/utils/auth"
import { Box, Button, Modal, Paper, Stack, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import useStyles from "./styles"

const ReconnectGoogleModal = ({ popupContainer, t, open = false, location, user, onClose }) => {
    const [openModal, setOpenModal] = useState(false)
    const { classes } = useStyles()
    const locationNotFound = useMemo(() => {
        return location?.locationState?.locationNotFound
    }, [location])
    const needsReconnection = useMemo(() => {
        return location?.locationState?.needsReconnection
    }, [location])
    const reconnectionFailed = useMemo(() => {
        return location?.locationState?.reconnectionFailed
    }, [location])
    const linkGoogleDoc = useMemo(() => {
        return `${env.DOCS_LINK}/${t("BUSINESSES.RECONNECT_GOOGLE.DOC_LINK")}`
    }, [t])
    useEffect(() => {
        setOpenModal(open)
    }, [open])

    return (
        <Modal className={classes.container} open={openModal} container={() => popupContainer?.current}>
            <Paper sx={withSxProp(modalStyle, { maxWidth: 440, boxSizing: "border-box" })}>
                {!reconnectionFailed && (needsReconnection || locationNotFound) && (
                    <>
                        <Typography
                            variant="h1"
                            component={"span"}
                            data-e2e="popup-need-reco"
                            sx={{ fontSize: 24, textAlign: "left", width: "100%" }}
                        >
                            {t("BUSINESSES.RECONNECT_GOOGLE.TITLE_ONE")}
                        </Typography>
                        <Typography sx={{ color: "action.active", fontSize: 14 }}>
                            {t("BUSINESSES.RECONNECT_GOOGLE.MANDATORY")}
                        </Typography>
                        <Box className="action-wrapper">
                            <Button onClick={onClose}>{t("REPUTATION.CANCEL")}</Button>
                            <Button
                                onClick={() =>
                                    openGoogleAccount({
                                        companyId: location.companyId,
                                        user_id: user.uid,
                                        gmb_access_id: location.gmbAccessId,
                                        yext_id: location.meta.yext_id,
                                        locationName: location.meta.gmb_id,
                                    })
                                }
                                variant="contained"
                                size="large"
                                sx={{ gap: 1, width: "100%" }}
                            >
                                <Icon name="unicolorGoogle" />
                                {t("BUSINESSES.RECONNECT_GOOGLE.TITLE_BUTTON")}
                            </Button>
                        </Box>
                    </>
                )}

                {reconnectionFailed && (
                    <>
                        <Typography
                            variant="h1"
                            component={"span"}
                            data-e2e="popup-reco-failed"
                            sx={{ fontSize: 24, textAlign: "left", width: "100%" }}
                        >
                            {t("BUSINESSES.RECONNECT_GOOGLE.ERROR")}
                        </Typography>
                        <Typography sx={{ color: "action.active" }}>
                            {t(`BUSINESSES.RECONNECT_GOOGLE.${"FAQ" || "FORBIDEN"}`)}
                        </Typography>
                        <Stack sx={{ width: "100%", gap: 1 }}>
                            <Button color="secondary" size="large" className="btn" onClick={onClose}>
                                {t("REPUTATION.CANCEL")}
                            </Button>
                            <Button
                                color="secondary"
                                size="large"
                                className="btn"
                                onClick={() =>
                                    openGoogleAccount({
                                        companyId: location.companyId,
                                        user_id: user.uid,
                                        gmb_access_id: location.gmbAccessId,
                                        yext_id: location.meta.yext_id,
                                        locationName: location.meta.gmb_id,
                                    })
                                }
                            >
                                {t("BUTTON.RETRY")}
                            </Button>
                            <Button
                                href={linkGoogleDoc}
                                target="blank"
                                variant="contained"
                                size="large"
                                sx={{ width: "100%", boxSizing: "border-box" }}
                            >
                                {t("BUSINESSES.RECONNECT_GOOGLE.DOC")}
                            </Button>
                        </Stack>
                    </>
                )}
            </Paper>
        </Modal>
    )
}

export default ReconnectGoogleModal
