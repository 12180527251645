import { UserSessionService } from "@/services"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { useEffect } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

const UNPROTECTED_ROUTE = ["/login"]
const SHOULD_CLEAR_SESSION_ON_ROUTE = ["/forgot-password", "/reset-password"]

const useRedirectLogic = () => {
    const navigate = useNavigate()
    const { isLoggedIn } = UserSessionStore()
    const { updateState: updatePageSession } = UiPageSessionStore()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    useEffect(() => {
        const isLoginRequired = !UNPROTECTED_ROUTE.includes(window.location.pathname)
        const shouldClearSession = SHOULD_CLEAR_SESSION_ON_ROUTE.includes(window.location.pathname)

        const isBasePath = window.location.pathname === "/"
        if (searchParams.get("personae_token")) {
            const logUser = async () => {
                const logPersonae = await UserSessionService.logCompanyAdmin({
                    user_uid: searchParams.get("user_uid"),
                    company_uid: searchParams.get("company_uid"),
                })
                if (logPersonae) {
                    updatePageSession({
                        menuPage: "reputation",
                    })
                    navigate("/reputation")
                } else {
                    await UserSessionService.logout()
                    navigate("/login")
                }
            }
            logUser()
        } else if (isLoginRequired && !isLoggedIn && !shouldClearSession) {
            navigate("/login")
        } else if ((!isLoginRequired && isLoggedIn && !shouldClearSession) || isBasePath) {
            updatePageSession({
                menuPage: "reputation",
            })
            navigate("/reputation")
        }
    }, [isLoggedIn, pathname, searchParams, navigate])
}

export default useRedirectLogic
