import { SxProps } from "@mui/material"
import { makeStyles } from "tss-react/mui"

export const menuSx: SxProps = {
    "& .MuiBackdrop-root": { opacity: "0 !important" },
    "& .MuiPaper-root": {
        padding: 0,
        "& ul": {
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: 0,
            "& li": {
                padding: 0,
                gap: 1,
                fontSize: 15,
                minHeight: 26,
                "& span": { height: 26 },
            },
        },
    },
}

const useStyles = makeStyles()((theme) => ({
    blocInputEmoji: {
        "& .bloc-helper-text": {
            flexDirection: "row",
            padding: "3px 8px 0px 8px",
            "& .number-char": {
                color: theme.palette.action.active,
            },
        },
    },
    emojiInput: {
        position: "relative",
        "& .btn-input-emoji": {
            gap: "8px",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            "& button": {
                gap: "8px",
            },
            "& .btn-cancel": {
                backgroundColor: "rgba(1, 128, 255, 0.12)",
                color: "#0180FF",
            },
        },
        "& .MuiFilledInput-root": {
            padding: "16px 16px 60px 16px",
        },
        "& .section-emoji": {
            position: "absolute",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            bottom: "38px",
            left: 0,
            width: "100%",
            boxSizing: "border-box",
            padding: "0px 16px",
            "& .bloc-emoji": {
                position: "relative",
                "& .MuiIconButton-root ": {
                    padding: "0px",
                },
            },
        },
        "& textarea": {
            letterSpacing: "0.15px",
            "&::placeholder": {
                color: "#68738D",
                opacity: 1,
            },
        },
    },
}))

export default useStyles
