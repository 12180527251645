import Icon from "@/components/base/Icon"
import useWindowSize from "@/hooks/useWindowSize"
import { Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import BusinessSocial from "../social"

const BusinessAddress = ({ item, address, isGoogleConnected }) => {
    const [width] = useWindowSize()
    //TODO: cette variable a été commenté suite au DLP-1735, EN ATTENTE D UN UPDATE AU NIVEAU DE L APP
    // const showFacebookChip = useMemo(() => {
    //     return !item?.meta?.facebook_id
    // }, [item])

    const showGoogleChip = useMemo(() => {
        return !item?.locationState?.locationNotFound && !item?.locationState?.needsReconnection && !isGoogleConnected
    }, [item, isGoogleConnected])

    const needsReconnection = useMemo(() => {
        return (item?.locationState?.locationNotFound || item?.locationState?.needsReconnection) && !isGoogleConnected
    }, [item, isGoogleConnected])

    const hideAddress = useMemo(() => {
        if (width <= 480) {
            if (
                // showFacebookChip &&
                showGoogleChip
            ) {
                return true
            }
        }
        return false
    }, [showGoogleChip, width])
    // showFacebookChip
    return (
        <>
            {address && (
                <Stack flexDirection={"row"} width={"100%"} alignItems={"center"} className="address">
                    {!hideAddress && (
                        <>
                            <Icon
                                className="eye"
                                name={
                                    item.serviceArea?.businessType === "CUSTOMER_AND_BUSINESS_LOCATION"
                                        ? "eye"
                                        : "eye-off"
                                }
                            />
                            <Typography component="span" className="text-ellipsis text-address">
                                {address}
                            </Typography>
                        </>
                    )}
                    <BusinessSocial
                        showFacebookChip={false}
                        showGoogleChip={showGoogleChip}
                        needsReconnection={needsReconnection}
                    />
                </Stack>
            )}
        </>
    )
}

export default BusinessAddress
