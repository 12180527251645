import { SkeletonBlocHour } from "@/components/skeleton/bloc-hour"
import { Stack, Paper, Skeleton } from "@mui/material"
import { useStyles } from "./styles"

export const SkeletonBusinessHour = () => {
    const { classes } = useStyles()
    return (
        <Paper className={classes.container}>
            <Stack height={50} justifyContent="center">
                <Skeleton variant="rectangular" width={200} height={20} />
            </Stack>
            <Paper elevation={2} sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Skeleton variant="rectangular" width={200} height={20} />
                <Skeleton variant="rectangular" width={200} height={50} />
            </Paper>
            <Stack className="list-bloc">
                <SkeletonBlocHour />
                <SkeletonBlocHour />
            </Stack>
        </Paper>
    )
}
