import { EntitiesApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const useLogic = () => {
    const { t } = useTranslation()
    const { profile } = UserSessionStore()

    const [metadata, setMetadata] = useState({
        page_size: 10,
        page_number: 0,
        total_count: 0,
    })
    const { notif } = useNotification()
    const { updateState, selectedItem, items, refreshItems, menuPage } = UiPageSessionStore()

    const handleChangePage = useCallback((_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setMetadata((prev) => ({
            ...prev,
            page_number: newPage,
        }))
    }, [])

    const refreshListCompanies = useCallback(async () => {
        const entity_type: any = "company"
        const response = await EntitiesApi.fetchListsEntities({
            page_number: metadata.page_number,
            page_size: metadata.page_size,
            user_uid: profile.uid,
            entity_type,
        })
        if (response?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else if (response?.metadata?.total_count) {
            setMetadata((prev) => ({
                ...prev,
                total_count: response.metadata.total_count,
                page_size: response.metadata.page_size,
            }))
            updateState({ items: [...response.data] })
        }
    }, [profile, metadata])

    useEffect(() => {
        if (profile.uid && menuPage === "companies") {
            refreshListCompanies()
        }
    }, [metadata.page_number, profile?.uid, menuPage])

    useEffect(() => {
        if (profile.uid && refreshItems && menuPage === "companies") {
            refreshListCompanies()
            updateState({ refreshItems: false })
        }
    }, [refreshItems, profile.uid, menuPage])

    return {
        t,
        handleChangePage,
        metadata,
        items,
        updateViewState: updateState,
        selectedItem,
    }
}

export default useLogic
