import { Paper, Skeleton, Stack } from "@mui/material"
import { ContentText } from "../../../content-text"
import { SkeletonBusinessItemMedia } from "../media"
import { useStyles } from "./styles"
import { v4 as uuidv4 } from "uuid"

export const SkeletonBusinessItemDescription = () => {
    const { classes } = useStyles()
    return (
        <Paper className={classes.container}>
            <Skeleton variant="rectangular" width={200} height={20} />
            <Stack className="list-bloc">
                <ContentText />
                <ContentText />
            </Stack>
            <SkeletonBusinessItemMedia key={uuidv4()} />
        </Paper>
    )
}
