import Icon from "@/components/base/Icon"
import SelectTimeField from "@/components/select/select-time-field"
import useLogic from "@/hooks/businesses/edit-standard-schedule/edit-schedule-item/useLogic"
import { DayItem } from "@/types/businesses"
import { Box, Checkbox, FormLabel, IconButton, Paper, Stack, Switch, Typography } from "@mui/material"
import classNames from "classnames"
import { Dispatch, Fragment, SetStateAction } from "react"

interface ScheduleItemProps {
    day: DayItem
    isFormSubmitted: boolean
    setDays: Dispatch<SetStateAction<DayItem[]>>
    dataE2e?: string
}

const ScheduleStandardItem = ({ day, isFormSubmitted, dataE2e, setDays }: ScheduleItemProps) => {
    const { classes, data, handleChangeClose, t, handleChange24, handleSelectTimefield, onChangeSchedule } = useLogic({
        day,
        setDays,
    })

    return (
        <Paper elevation={2} className={`${classes.paper}`} data-e2e={dataE2e || ""}>
            {data && (
                <Fragment>
                    <Box className={classNames("paper-header align-baseline")}>
                        <Typography variant="h3" className="day">
                            {t(`BUSINESSES.SCHEDULE.${data?.name}`)}
                        </Typography>
                        <Stack flexDirection={"row"} alignItems={"center"}>
                            <Switch checked={data?.isOpen || false} onChange={handleChangeClose} />
                            <FormLabel>
                                {data?.isOpen ? t("BUSINESSES.SCHEDULE.OPEN") : t("BUSINESSES.SCHEDULE.CLOSE")}
                            </FormLabel>
                        </Stack>
                    </Box>
                    {data?.isOpen && (
                        <Box className={classNames("paper-content align-start")}>
                            <Stack flexDirection={"row"} alignItems={"center"}>
                                <Checkbox checked={data?.isAvailableAllDay || false} onChange={handleChange24} />
                                <FormLabel>{t("BUSINESSES.SCHEDULE.24H")}</FormLabel>
                            </Stack>
                            {!data?.isAvailableAllDay && (
                                <Box className="schedule-list">
                                    {data?.times?.map((time, index) => (
                                        <Fragment key={index}>
                                            <Box className="schedule-input-wrapper" key={index}>
                                                <SelectTimeField
                                                    dataE2e="schedule-start-time"
                                                    onChange={(value) => {
                                                        handleSelectTimefield(value, index, "start")
                                                    }}
                                                    defaultValue={time?.startTxt}
                                                    shouldDisableTime={() => {
                                                        return !time.startValid
                                                    }}
                                                />
                                                <Typography component={"span"} className="separator">
                                                    -
                                                </Typography>
                                                <SelectTimeField
                                                    dataE2e="schedule-end-time"
                                                    onChange={(value) => {
                                                        handleSelectTimefield(value, index, "end")
                                                    }}
                                                    defaultValue={time?.endTxt}
                                                    shouldDisableTime={() => {
                                                        return !time.endValid
                                                    }}
                                                />
                                                <IconButton
                                                    data-e2e={index > 0 ? "schedule-delete" : "schedule-add"}
                                                    className="plus"
                                                    onClick={() =>
                                                        onChangeSchedule(index > 0 ? "delete" : "add", index)
                                                    }
                                                >
                                                    <Icon name={index > 0 ? "delete-1" : "plus"} />
                                                </IconButton>
                                            </Box>

                                            {(!time.endValid || !time.startValid) && isFormSubmitted && (
                                                <Typography
                                                    data-testid="error"
                                                    component={"span"}
                                                    className="error time-error"
                                                >
                                                    {t(
                                                        `BUSINESSES.SCHEDULE.${
                                                            !time.start ? "HOUR_ERROR" : "HOUR_ERROR_ANTERIOR"
                                                        }`
                                                    )}
                                                </Typography>
                                            )}
                                        </Fragment>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    )}
                </Fragment>
            )}
        </Paper>
    )
}

export default ScheduleStandardItem
