import OverViewContainer from "@/components/company/overview-container"
import { CssTextField } from "@/components/base/CssTextField"
import { TitleWrapper } from "@/components/base/title-wrapper"
import useLogic from "@/hooks/companies/overview/useLogic"
import { Box, Paper, Typography } from "@mui/material"

export const CompanyOverview = ({ onClose, open }) => {
    const { company, address, t, handlePreviousButton } = useLogic()

    return (
        <OverViewContainer open={open} onClose={onClose}>
            <TitleWrapper
                handlePreviousButton={handlePreviousButton}
                title={company?.name}
                className="title-overview"
            />
            <form>
                {address && (
                    <Paper className="inputs-wrapper">
                        <Typography component="h3">{t("COMPANIES.ADDRESS")}</Typography>
                        <CssTextField
                            data-testid="label-address"
                            multiline
                            label={t("COMPANIES.ADDRESS")}
                            value={address}
                            variant="standard"
                            inputProps={{ readOnly: true }}
                        />
                    </Paper>
                )}
            </form>
        </OverViewContainer>
    )
}
