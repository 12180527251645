import TablePaginationActions from "@/components/base/table/PaginationActions"
import useFormater from "@/hooks/useFormater"
import { BusinessItemType } from "@/types/businesses"
import { List, TablePagination } from "@mui/material"
import { TFunction } from "i18next"
import { MouseEvent } from "react"
import useStyles from "./styles"
import useLogic from "@/hooks/businesses/list/useComponentLogic"
import BusinessListItem from "./item"

interface BusinessListProps {
    list: BusinessItemType[]
    t: TFunction<"translation", undefined>
    metadata: { total_count: number; page_size: number; page_number: number }
    handleChangePage: (_: MouseEvent<HTMLButtonElement> | null, newPage: number) => void
}

const BusinessList = ({ list, t, metadata, handleChangePage }: BusinessListProps) => {
    const { classes } = useStyles()
    const { parseAddress } = useFormater()
    const { handleItemClick, isGoogleConnected, isLocationNew } = useLogic({ list })
    return (
        <>
            <List className={classes.list}>
                {list.map((item, key) => (
                    <BusinessListItem
                        key={key}
                        item={item}
                        handleItemClick={handleItemClick}
                        isLocationNew={isLocationNew(item)}
                        address={parseAddress({
                            region: item.region,
                            countryCode: item.country,
                            address: item.address,
                            city: item.city,
                            zip: item.zip,
                        })}
                        isGoogleConnected={isGoogleConnected(item)}
                        t={t}
                    />
                ))}
            </List>
            <TablePagination
                className={classes.pagination}
                component={"div"}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} ${t("USERS.PAGING")} ${count}`
                }}
                count={metadata.total_count}
                rowsPerPage={10}
                page={metadata.page_number}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
                ActionsComponent={TablePaginationActions}
            />
        </>
    )
}

export default BusinessList
