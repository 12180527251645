import Icon from "@/components/base/Icon"
import { Button, Menu, MenuItem, Paper, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { menuSx } from "./schedule/action-btn/styles"

const EstablishmentHeader = ({
    anchorEl,
    faceboookMenuHandler,
    handleCloseFacebookMenu,
    openFacebookMenu,
    authUrl,
    handeleConnect,
    isLoggedFacebook,
    disconnectFacebook,
    isGoogleConnected,
    handleConnectGoogle,
}) => {
    const { t } = useTranslation()
    return (
        <Paper className="social-wrapper">
            <Button
                color={isLoggedFacebook ? "success" : "primary"}
                className="social-btn"
                variant="contained"
                onClick={(e) => (!isLoggedFacebook ? handeleConnect("facebook") : faceboookMenuHandler(e))}
                id="basic-button"
                aria-controls={openFacebookMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openFacebookMenu ? "true" : undefined}
            >
                <Icon name="unicolorFacebook" />
                <Typography component={"span"}>
                    {isLoggedFacebook ? t("BUSINESSES.CONNECTED") : t("BUSINESSES.CONNECT")}
                </Typography>
            </Button>
            <Button
                color={isGoogleConnected ? "success" : "primary"}
                className="social-btn"
                variant="contained"
                href={authUrl}
            >
                <Icon name="unicolorGoogle" />
                <Typography
                    component={"span"}
                    onClick={() => {
                        if (!isGoogleConnected) handleConnectGoogle()
                    }}
                >
                    {isGoogleConnected ? t("BUSINESSES.CONNECTED") : t("BUSINESSES.CONNECT")}
                </Typography>
            </Button>

            <Menu
                sx={menuSx}
                id="basic-menu"
                anchorEl={anchorEl}
                open={openFacebookMenu}
                onClose={handleCloseFacebookMenu}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    data-e2e="disconnect-facebook"
                    onClick={() => {
                        disconnectFacebook(), handleCloseFacebookMenu()
                    }}
                >
                    {t("BUSINESSES.DISCONNECT_FACEBOOK.MENU")}
                </MenuItem>
            </Menu>
        </Paper>
    )
}

export default EstablishmentHeader
