import Icon from "@/components/base/Icon"
import IconNames from "@/types/icons"
import { Box, Typography } from "@mui/material"

const LinkAppointment = ({ linksNetwork }) => {
    return (
        <>
            {linksNetwork && linksNetwork["appointment"] && linksNetwork["appointment"]?.value !== "" && (
                <Box className="line-wrapper">
                    <Icon name={linksNetwork["appointment"]?.icon as IconNames} />
                    <Typography data-testid="linkAppointment" component={"span"} className="link-url">
                        {linksNetwork["appointment"].value}
                    </Typography>
                </Box>
            )}
        </>
    )
}

export default LinkAppointment
