import { MAX_INPUT } from "@/data/limiter"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMemo } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import { FormValues } from "@/types/businesses"
import useLocation from "@/hooks/useLocation"

const useMainInfoForm = () => {
    const { t } = useTranslation()
    const { location } = useLocation()

    const schema = useMemo(() => {
        return z
            .object({
                address: z.string().trim().max(MAX_INPUT.TEXT).min(1, t("USERS.FIELD_REQUIRED")),
                zip: z.string().max(MAX_INPUT.TEXT).min(1, t("USERS.FIELD_REQUIRED")),
                city: z.string().trim().max(MAX_INPUT.TEXT).min(1, t("USERS.FIELD_REQUIRED")),
                region: z.string().max(MAX_INPUT.TEXT).optional(),
                phone: location?.websiteUrl ? z.string().optional() : z.string().nonempty(t("USERS.FIELD_REQUIRED")),
                country: z.string().max(MAX_INPUT.TEXT).min(1, t("USERS.FIELD_REQUIRED")),
                name: z.string().trim().min(1, t("USERS.FIELD_REQUIRED")),
                company: z.string().min(1, t("USERS.FIELD_REQUIRED")),
            })
            .refine(
                (values) => {
                    if (values.region === "" && values.country !== "FR") {
                        return false
                    }
                    return true
                },
                { path: ["region"], message: t("USERS.FIELD_REQUIRED") }
            )
    }, [location?.websiteUrl, t])

    const { control, register, trigger, setValue, handleSubmit, formState, setFocus, reset } = useForm<FormValues>({
        defaultValues: {
            address: null,
            zip: "",
            city: "",
            region: "",
            phone: "",
            country: "CA",
            name: "",
            company: "",
            countryPhone: "CA",
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const formValues = useWatch({ control })

    return {
        formValues,
        formState,
        control,
        register,
        setFocus,
        setValue,
        trigger,
        reset,
        handleSubmit,
    }
}

export default useMainInfoForm
