import Icon from "@/components/base/Icon"
import useFormater from "@/hooks/useFormater"
import { CookiesService } from "@/services"
import { urlify } from "@/utils"
import { Box, Divider, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export default function EmailPreview({ html, contacts, feedback = true, business }) {
    const { t } = useTranslation()
    const { parseVariables, parseAddress, translateString } = useFormater()
    const emailSpotconnect = CookiesService.get("emailSpotconnect")

    const text = useMemo(() => {
        return urlify(parseVariables(html, contacts, business))
    }, [contacts, parseVariables, urlify])

    return (
        <Box className="box-message">
            <Stack gap={2}>
                <Stack>
                    <Typography component="span" color={"action.active"}>
                        {t("CAMPAIGN.FROM")}:
                    </Typography>
                    <Typography component="span"> {emailSpotconnect}</Typography>
                </Stack>
                <Stack>
                    <Typography component="span" color={"action.active"}>
                        {t("CAMPAIGN.SUBJECT")}:
                    </Typography>
                    <Typography component="span" sx={{ lineBreak: "anywhere" }}>
                        {contacts?.subject}
                    </Typography>
                </Stack>
                <Stack className="preview-message">
                    <Typography
                        sx={{ wordBreak: "normal", whiteSpace: "pre-line" }}
                        fontSize={"16px"}
                        dangerouslySetInnerHTML={{ __html: text }}
                    ></Typography>
                </Stack>
                <Stack>
                    {feedback && (
                        <Stack
                            sx={{
                                maxWidth: "400px",
                                textAlign: "center",
                                margin: "auto",
                                alignItems: "center",
                                gap: 2,
                                width: "100%",
                            }}
                        >
                            <Typography sx={{ maxWidth: "220px" }} variant="h6">
                                {contacts?.lang && translateString("CAMPAIGN.EXPERIENCE", contacts?.lang)}
                            </Typography>
                            <Stack flexDirection="row" gap={2}>
                                <Stack className="btn-review good">
                                    <Icon name="successHand" />
                                    <Typography className="text-review good">
                                        {contacts?.lang && translateString("CAMPAIGN.SATISFIED", contacts?.lang)}
                                    </Typography>
                                </Stack>
                                <Stack className="btn-review bad">
                                    <Icon name="warningHand" sx={{ color: "#FFC431", width: 42, height: 42 }} />
                                    <Typography className="text-review bad">
                                        {contacts?.lang && translateString("CAMPAIGN.NOT_SATISFIED", contacts?.lang)}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
                <Divider className="separate" orientation="horizontal" flexItem />
                <Stack gap={1}>
                    {contacts?.logo && (
                        <img
                            src={contacts?.logo}
                            alt="Logo"
                            style={{
                                width: "72px",
                                height: "72px",
                                borderRadius: "8px",
                            }}
                        />
                    )}
                    <Stack gap={0.5}>
                        <Typography variant={"h6"}>{contacts?.name}</Typography>
                        <Stack>
                            <Typography component={"span"} color="action.active">
                                {parseAddress({
                                    region: contacts?.region,
                                    countryCode: contacts?.country,
                                    address: contacts?.address,
                                    city: contacts?.city,
                                    zip: contacts?.zip,
                                })}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}
