import env from "@/config/env"
import { CookiesService } from "@/services"
import { encodeBase64, objectToQueryString } from "@/utils"

interface AuthInput {
    ag_uid: string
    callbackPath?: string
    updateAccessToken?: boolean
    type?: string
    gmb_access_id?: string
}

const generateUrl = ({
    ag_uid = "",
    callbackPath = "/businesses",
    updateAccessToken = false,
    type = "",
    gmb_access_id = "",
}: AuthInput) => {
    const idc = ag_uid
    const id = CookiesService.get("resellerUid")
    const uri = `${window.location.origin}${callbackPath}`
    const messagingData = updateAccessToken ? "&updateAccessToken=true" : ""
    const typeData = type != "" ? `&${type}` : ""
    const gmbId = gmb_access_id != "" ? `&gmb_access_id=${gmb_access_id}` : ""
    const param = `id=${id}&idc=${idc}&cb=${uri}${messagingData}${typeData}${gmbId}`
    const dataParam = encodeBase64(param)
    const url = env.AUTH_URL
    return `${url}?inf=` + dataParam
}

const openGoogleAccount = ({
    companyId,
    user_id,
    gmb_access_id = "",
    yext_id = "",
    locationName = "",
}: {
    companyId: string
    user_id: string
    gmb_access_id?: string
    yext_id?: string
    locationName?: string
}) => {
    const b64 = encodeBase64(
        `companyId=${companyId}&user_id=${user_id}&cb=${window.location.origin}/businesses&auth_type=google${
            gmb_access_id ? "&gmb_access_id=" + gmb_access_id : ""
        }${yext_id ? "&yext_id=" + yext_id : ""}${locationName ? "&locationName=" + locationName : ""}`
    )
    const query = objectToQueryString({
        client_id: env.GBP_CLIENT_ID,
        redirect_uri: `${env.AUTH_URL}`,
        response_type: "code",
        scope: env.GBP_SCOPE,
        prompt: "consent",
        include_granted_scopes: true,
        enable_granular_consent: true,
        access_type: "offline",
        gsiwebsdk: "3",
        flowName: "GeneralOAuthFlow",
        state: `${b64}`,
    })
    window.location.href = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?" + query
}

export { generateUrl, openGoogleAccount }
