import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useDescriptionStore from "@/store/overview/long-description"
import UiPageSessionStore from "@/store/ui-page-session"
import { DescriptionValue } from "@/types/businesses"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"

const useLogic = () => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const { location } = useLocation()
    const { description: descriptionLong, update: updateDescription } = useDescriptionStore()
    const [loading, setLoading] = useState(false)
    const { notif } = useNotification()
    const { updatePreviousItem, previousItem, updateState: setUiPageSession } = UiPageSessionStore()
    const schema = useMemo(() => {
        return z.object({
            description: z.string().trim().optional(),
        })
    }, [])
    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        console.log(errors)
    }
    const { control, handleSubmit, trigger, setValue, watch, getValues } = useForm<DescriptionValue>({
        defaultValues: {
            description: "",
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const description = watch("description") ?? ""

    const setValues = useCallback(
        (description) => {
            setValue("description", description?.short || "")
            trigger(["description"])
        },
        [setValue, trigger]
    )

    const onSubmit: SubmitHandler<any> = async (data: DescriptionValue) => {
        setLoading(true)
        const body = { ...location, description: descriptionLong }
        body["description"].short = data.description

        const response = await ResellerApi.updateLocation({ ...body })
        if (response?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
            updateDescription({ description: response.description })
            updatePreviousItem({ description: null })
            setUiPageSession({ refreshItems: true })
            navigateTo("detail")
        }
        setLoading(false)
    }

    const handleCancelButton = useCallback(() => {
        navigateTo("detail")
        updatePreviousItem({ description: descriptionLong })
    }, [descriptionLong])

    const handlePreviousButton = useCallback(() => {
        navigateTo("detail")
        updatePreviousItem({ description: { short: getValues().description } })
    }, [getValues])

    useEffect(() => {
        if (location?.id) {
            if (previousItem?.description) {
                setValue("description", previousItem.description?.short || "")
            } else {
                setValues(descriptionLong)
            }
        }
    }, [descriptionLong, previousItem, location?.id])

    return {
        handlePreviousButton,
        handleSubmit,
        onSubmit,
        onErrors,
        t,
        watch,
        control,
        handleCancelButton,
        loading,
        setValues,
        getValues,
        description,
    }
}

export default useLogic
