import ListContainer from "@/components/base/page-container/list-container"
import TitleWrapper from "@/components/base/page-container/list-container/title-wrapper"
import ListTable from "@/components/list-table"
import { DeleteModal } from "@/components/modal-delete"

import useLogic from "@/hooks/users/list/useLogic"
import { Paper } from "@mui/material"

const UserList = () => {
    const {
        handleChangePage,
        handleCloseModal,
        handleDelete,
        handleDeleteUser,
        handleEdit,
        items,
        setStateUiPageSession,
        t,
        metadata,
        userToDelete,
        loading,
    } = useLogic()

    return (
        <>
            <ListContainer>
                <TitleWrapper
                    buttonText={t("USERS.CREATE_BUTTON")}
                    onClickButton={() => {
                        setStateUiPageSession({ showPage: "create" })
                    }}
                    pageIcon="user"
                    title={t("USERS.TITLE")}
                    showActionButton
                />

                {items && items.length > 0 && (
                    <Paper>
                        <ListTable
                            handleChangePage={handleChangePage}
                            metadata={metadata}
                            rows={items}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            generateLabels={(row) => [`${row.firstname} ${row.lastname}`]}
                            toolTipDeleteText={t("USERS.DELETE.TRASH")}
                            toolTipEditText={t("USERS.EDIT")}
                            toolTipRowText={t("USERS.VIEW_TOOLTIP")}
                        />
                    </Paper>
                )}
            </ListContainer>
            <DeleteModal
                open={!!userToDelete}
                onClose={handleCloseModal}
                title={t("USERS.DELETE.TITLE")}
                description={t("USERS.DELETE.ASK")}
                loading={loading}
                onDelete={() => {
                    handleDeleteUser()
                }}
            />
        </>
    )
}

export default UserList
