import useAttributesStore, { AttributeType } from "@/store/overview/attributes"
import UiStore from "@/store/ui"
import { MouseEventHandler, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
export interface LogicProps {
    itemKey: number
}

const useLogic = ({ itemKey }: LogicProps) => {
    const [expanded, setExpanded] = useState(false)
    const { t } = useTranslation()
    const { preferredLanguage } = UiStore()
    const { listAttributes, update } = useAttributesStore()
    const handleExpandClick: MouseEventHandler = useCallback(
        (e) => {
            e.stopPropagation()
            setExpanded(!expanded)
        },
        [expanded, setExpanded]
    )

    const getSelected = useCallback(
        (attributes: AttributeType[]) =>
            attributes
                .filter(
                    (attribute) =>
                        !!attribute.selected && attribute.selected.display != t("BUSINESSES.EDIT_ATTRIBUTES.UNDEFINED")
                )
                .map((attribute) => attribute.selected)
                .flat(),
        [preferredLanguage]
    )

    const onRadioChange = useCallback(
        (value: string, attributeKey: number) => {
            const newList = listAttributes.map((item, key) => {
                const currentAttribute = item.attributes[attributeKey]
                if (key === itemKey) {
                    currentAttribute.options.map((att, index) => {
                        currentAttribute.options[index].checked = false
                    })
                    currentAttribute.options[value].checked = true
                    currentAttribute.selected = currentAttribute.options[value]
                }
                return item
            })
            update({ listAttributes: newList })
        },
        [listAttributes]
    )

    const onCheckBoxChange = useCallback(
        (value: string, attributeKey: number, checked: boolean) => {
            const newList = listAttributes.map((item, key) => {
                const currentAttribute = item.attributes[attributeKey]
                if (key === itemKey) {
                    if (!currentAttribute.selected) {
                        currentAttribute.selected = []
                    }
                    if (!currentAttribute.selected.includes(currentAttribute.options[value]) && checked) {
                        currentAttribute.options[value].checked = true
                        currentAttribute.selected.push(currentAttribute.options[value])
                    } else if (currentAttribute.selected.includes(currentAttribute.options[value]) && !checked) {
                        currentAttribute.options[value].checked = false
                        currentAttribute.selected = currentAttribute.selected.filter(function (item) {
                            return item !== currentAttribute.options[value]
                        })
                    }
                }
                return item
            })
            update({ listAttributes: newList })
        },
        [listAttributes]
    )

    return {
        handleExpandClick,
        getSelected,
        onRadioChange,
        onCheckBoxChange,
        expanded,
    }
}

export default useLogic
