import { Stack } from "@mui/material"
import { SkeletonBusinessItemHeader } from "./header-btn"
import { SkeletonBusinessItemMainInfo } from "./main-info"

export const SkeletonBusinessItem = () => {
    return (
        <Stack gap={3}>
            <SkeletonBusinessItemHeader />
            <Stack gap={3} flexDirection="row" flexWrap={"wrap"}>
                <SkeletonBusinessItemMainInfo />
                <SkeletonBusinessItemMainInfo />
            </Stack>
        </Stack>
    )
}
