import { DayItem, TimeValidation } from "@/types/businesses"
import cloneDeep from "lodash.clonedeep"

const validateTimes = (day: DayItem, showAa: boolean): DayItem => {
    const field = cloneDeep(day)
    if (!field.isAvailableAllDay) {
        field.times = field.times.map((time: TimeValidation, index, arr) => {
            if (arr[index - 1]) {
                if (arr[index - 1]?.isAvailableAllDay) {
                    time.startValid = true
                } else {
                    time.startValid = !!arr[index - 1]["end"]?.isBefore(time.start)
                }
            } else {
                time.startValid = time?.start?.isValid()
            }
            time.endValid = time?.start?.isBefore(time.end) || time?.start?.isSame(time.end)
            if (time.end?.isBefore(time.start)) {
                time.endValid = true
                time.tomorrow = true
            } else {
                time.tomorrow = false
            }
            if (time.startValid) {
                time.startTxt = showAa ? time?.start?.format("h:mm A") : time?.start?.format("HH:mm")
            } else {
                time.startTxt = ""
            }
            if (time.endValid) {
                time.endTxt = showAa ? time?.end?.format("h:mm A") : time?.end?.format("HH:mm")
            } else {
                time.endTxt = ""
            }
            return time
        })
    }
    return cloneDeep(field)
}

export default validateTimes
