import { EntitiesApi, UserApi } from "@/api"
import { MAX_INPUT } from "@/data/limiter"
import useNotification from "@/hooks/useNotification"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { OptionType } from "@/types/option"
import { FormValues } from "@/types/user"
import { decode64AndParse } from "@/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

const useLogic = ({ isUpdate, callApi, onCancelForm }) => {
    const [mailError, setMailError] = useState("")
    const [roleSelect, setRoleSelect] = useState("")
    // const [adminDefaultValue, setAdminDefaultValue] = useState()
    const { updateState: setUiPageSession, selectedItem, previousItem, updatePreviousItem } = UiPageSessionStore()
    const { notif } = useNotification()
    const [companies, setCompanies] = useState<OptionType[]>([])
    const [businesses, setBusinesses] = useState<OptionType[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const { preferredLanguage } = UiStore()
    const { profile, role: roleBase64 } = UserSessionStore()
    const { t } = useTranslation()
    const MESSAGE_TEXT = useMemo(
        () => ({
            SAME_EMAIL: t("USERS.SAME_EMAIL"),
            INVALID_REQUEST: t("SYSTEM_ERROR.INVALID_REQUEST"),
            USER_CREATE_SUCCESS: t("USERS.CREATE.SUCCESS"),
            USER_UPDATE_SUCCESS: t("USERS.UPDATE.SUCCESS"),
            FIELD_REQUIRED: t("USERS.FIELD_REQUIRED"),
            CANCEL: t("USERS.CANCEL"),
            BACK: t("USERS.BACK_TOOLTIP"),
            USER_CREATE_SAVE: t("USERS.CREATE.SAVE"),
            USER_UPDATE: t("USERS.SAVE"),
            USER_EMAIL: t("USERS.E-MAIL"),
            USER_LAST_NAME: t("USERS.LAST_NAME"),
            USER_FIRST_NAME: t("USERS.FIRST_NAME"),
            USER_CREATE_TITLE: t("USERS.CREATE.TITLE"),
            INVALID_FORMAT_EMAIL: t("USERS.INVALID_FORMAT_EMAIL"),
            MAIN_INFO: t("USERS.MAIN_INFO"),
            ROLE_TITLE: t("USERS.ROLE_TITLE_CREATE"),
            ROLE: t("USERS.ROLE"),
            COMPANY_ROLE: t("USERS.COMPANY_ROLE"),
            BUSINESS_ROLE: t("USERS.BUSINESS_ROLE"),
            ADMIN_COMPANY_ROLE: t("USERS.ADMIN_COMPANY_ROLE"),
            ADMIN_BUSINESS_ROLE: t("USERS.ADMIN_BUSINESS_ROLE"),
        }),
        [t]
    )

    const roles = useMemo<OptionType[]>(() => {
        if (profile?.uid && roleBase64) {
            const roleList: any = decode64AndParse(roleBase64)
            if (
                roleList &&
                roleList.includes("ROLE_ADMIN_COMPANY") &&
                !roleList.includes("ROLE_ADMIN_LOCATION") &&
                !roleList.includes("ROLE_ADMIN_ESTABLISHMENT")
            ) {
                const twoRolesOptions: any = [
                    {
                        label: MESSAGE_TEXT.ADMIN_COMPANY_ROLE,
                        value: "admin_company",
                    },
                    {
                        label: MESSAGE_TEXT.ADMIN_BUSINESS_ROLE,
                        value: "admin_location",
                    },
                ]
                return twoRolesOptions
            } else if (
                roleList &&
                (roleList.includes("ROLE_ADMIN_LOCATION") || roleList.includes("ROLE_ADMIN_ESTABLISHMENT"))
            ) {
                const oneRolesOptions: any = [
                    {
                        label: MESSAGE_TEXT.ADMIN_BUSINESS_ROLE,
                        value: "admin_location",
                    },
                ]

                return oneRolesOptions
            }
        }
        return []
    }, [profile?.uid, roleBase64])

    const listItemsAutocomplete = useMemo(() => {
        if (roleSelect === "admin_company") return companies
        else return businesses
    }, [businesses, companies, roleSelect])

    const isAutoEdit = useMemo(
        () => profile?.uid === selectedItem?.uid && isUpdate,
        [profile?.uid, selectedItem, isUpdate]
    )

    const schema = useMemo(() => {
        return z.object({
            firstname: z.string().trim().max(MAX_INPUT.TEXT).min(1, MESSAGE_TEXT.FIELD_REQUIRED),
            lastname: z.string().trim().max(MAX_INPUT.TEXT).min(1, MESSAGE_TEXT.FIELD_REQUIRED),
            email: z
                .string()
                .trim()
                .min(1, MESSAGE_TEXT.FIELD_REQUIRED)
                .max(MAX_INPUT.TEXT)
                .email({ message: t("USERS.INVALID_FORMAT_EMAIL") }),
            role: z.string().min(1, MESSAGE_TEXT.FIELD_REQUIRED),
            roleByAdminType: z.union([
                z.array(z.string()).nonempty(MESSAGE_TEXT.FIELD_REQUIRED),
                z.string().min(1, MESSAGE_TEXT.FIELD_REQUIRED),
            ]),
        })
    }, [MESSAGE_TEXT, MAX_INPUT.TEXT])

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        control,
        getValues,
        trigger,
        setValue,
        setError: setFormError,
        reset,
    } = useForm<FormValues>({
        defaultValues: {
            email: "",
            firstname: "",
            lastname: "",
            role: "",
            roleByAdminType: [],
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
        shouldUnregister: true,
    })

    const refreshListEntities = useCallback(
        async (entity_type) => {
            try {
                const user_uid: any = profile.uid
                const response = await EntitiesApi.fetchEntities({ user_uid, entity_type })
                const entity = entity_type === "company" ? "companies" : "locations"
                if (response[entity]) {
                    const entitiesOptions = response[entity].map((item) => {
                        return {
                            label: item.name,
                            value: item.uid,
                        }
                    })

                    if (entitiesOptions.length === 1) {
                        setValue("roleByAdminType", [entitiesOptions[0].value])
                    }

                    if (entity_type === "company") {
                        setCompanies(entitiesOptions)
                    } else {
                        setBusinesses(entitiesOptions)
                    }
                    return entitiesOptions
                } else {
                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                }
            } catch (error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
            return null
        },
        [profile?.uid, roleSelect]
    )

    const watchForm = useWatch({
        control,
        name: ["firstname", "lastname", "email", "role", "roleByAdminType"],
    })

    const role = useMemo(() => watchForm?.[3], [watchForm])

    const createOrUpdateUser = useCallback(
        async (data: FormValues) => {
            let companyTab = []
            let establishmentTab = []
            if (data.role === "admin_company") {
                companyTab = Array.isArray(data.roleByAdminType) ? data.roleByAdminType : [data.roleByAdminType]
            } else {
                establishmentTab = Array.isArray(data.roleByAdminType) ? data.roleByAdminType : [data.roleByAdminType]
            }
            const userObject: any = {
                uid: selectedItem?.uid,
                user_uid: profile?.uid,
                email: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                lang: preferredLanguage,
                roles: [data.role],
            }
            if (establishmentTab.length > 0) {
                userObject["locations"] = establishmentTab
            }
            if (companyTab.length > 0) {
                userObject["companies"] = companyTab
            }

            const response = await callApi(userObject)

            if (response?.error) {
                if (response.error === "USER_ALREADY_EXIST") {
                    setFormError("email", { message: "" }, { shouldFocus: true })
                    setMailError(MESSAGE_TEXT.SAME_EMAIL)
                } else {
                    notif({ message: MESSAGE_TEXT.INVALID_REQUEST, type: "ERROR" })
                }
            } else {
                setUiPageSession({ showPage: "list", refreshItems: true, previousItem: null })
                notif({
                    message: isUpdate ? MESSAGE_TEXT.USER_UPDATE_SUCCESS : MESSAGE_TEXT.USER_CREATE_SUCCESS,
                    type: "SUCCESS",
                })
            }
        },
        [profile, preferredLanguage, setFormError, MESSAGE_TEXT, notif, setValue, isUpdate]
    )

    useEffect(() => {
        refreshListEntities("company")
        refreshListEntities("location")
    }, [refreshListEntities])

    const asyncLoad = useCallback(async () => {
        if (previousItem && previousItem?.fromEdit && previousItem?.email && selectedItem?.uid === previousItem?.uid) {
            setValues(previousItem)
        } else {
            try {
                const [response] = await Promise.all([
                    UserApi.fetchUserInfo({
                        id: selectedItem?.uid,
                        user_uid: profile.uid,
                    }),
                ])
                const data = { ...response }
                data.role = response.roles[0] === "ROLE_ADMIN_COMPANY" ? "admin_company" : "admin_location"
                if (data.role === "admin_company") {
                    data.roleByAdminType = data.companies
                } else {
                    data.roleByAdminType = data.locations
                }
                setValues(data)
                updatePreviousItem({ ...data, fromEdit: isUpdate })
            } catch (error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        }
    }, [profile?.uid, selectedItem?.uid, previousItem])

    useEffect(() => {
        if (isUpdate && selectedItem?.uid) {
            asyncLoad()
        }
    }, [asyncLoad, isUpdate, selectedItem?.uid])

    const onSubmit: SubmitHandler<any> = async (data: FormValues) => {
        setMailError("")
        const emailValidationSchema = z.object({
            email: z.string().email(),
        })
        try {
            emailValidationSchema.parse({
                email: getValues().email,
            })
            setLoading(true)
            await createOrUpdateUser(data)
        } catch (error) {
            if (error instanceof z.ZodError) {
                setMailError(MESSAGE_TEXT.INVALID_FORMAT_EMAIL)
            }
        }
        setLoading(false)
    }

    const resetForm = () => {
        reset({
            email: "",
            firstname: "",
            lastname: "",
            role: "",
            roleByAdminType: "",
        })
    }

    const setValues = useCallback(
        (data) => {
            setValue("firstname", data.firstname)
            setValue("lastname", data.lastname)
            setValue("email", data.email)
            setValue("role", data.role)
            setValue("roleByAdminType", data.roleByAdminType)
            trigger(["role", "roleByAdminType"])
        },
        [setValue, trigger]
    )

    const handeCancelButton = () => {
        resetForm()
        onCancelForm()
    }

    useEffect(() => {
        return () => {
            const data: any = getValues()
            if (data.role === "admin_company") {
                data.companies = data.roleByAdminType
            } else {
                data.locations = data.roleByAdminType
            }

            updatePreviousItem({
                ...data,
                uid: selectedItem?.uid,
                fromEdit: isUpdate,
            })
        }
    }, [isUpdate, selectedItem?.uid])

    useEffect(() => {
        if (!isUpdate) {
            const asyncLoad = async () => {
                if (previousItem && !previousItem?.fromEdit) {
                    const data = { ...previousItem }
                    if (data.role === "admin_company") {
                        data.companies = data.roleByAdminType
                    } else {
                        data.locations = data.roleByAdminType
                    }
                    setValues(data)
                }
            }
            asyncLoad()
        }
    }, [isUpdate, previousItem, selectedItem?.uid])

    useEffect(() => {
        setMailError("")
    }, [watchForm?.[2]])

    useEffect(() => {
        if (role) {
            setRoleSelect(role)
            if (!isUpdate) {
                if (role === "admin_company" && companies?.length === 1) {
                    setValue("roleByAdminType", [companies[0].value])
                } else if (role === "admin_location" && businesses?.length === 1) {
                    setValue("roleByAdminType", [businesses[0].value])
                }
                trigger("roleByAdminType")
            }
            trigger("role")
        }
    }, [role, companies, businesses, isUpdate]) // role

    useEffect(() => {
        if (roles.length === 1) {
            setValue("role", roles[0].value)
        }
    }, [setValue, roles])

    return {
        handleSubmit,
        onSubmit,
        register,
        control,
        MESSAGE_TEXT,
        mailError,
        setMailError,
        roles,
        isAutoEdit,
        getValues,
        roleSelect,
        listItemsAutocomplete,
        setValue,
        trigger,
        t,
        resetForm,
        loading,
        profile,
        errors,
        isValid,
        handeCancelButton,
    }
}

export default useLogic
