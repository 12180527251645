import { BlocNumber } from "@/components/analytics/bloc-number"
import { FilterAnalytics } from "@/components/analytics/filter"
import useStyles from "@/components/analytics/styles"
import Icon from "@/components/base/Icon"
import { ChartView } from "@/components/base/chart"
import TitleWrapper from "@/components/base/page-container/list-container/title-wrapper"
import useLogic from "@/hooks/presence-local/useLogic"
import { convertBigNumberSpace, convertPourcentageString, convertStyleString } from "@/utils"
import { Container, Paper, Stack, Typography } from "@mui/material"
import { SkeletonStat } from "@/components/skeleton/statistic"

export const LocalPresence = ({ mockData = null }) => {
    const { classes } = useStyles()
    const {
        kpi,
        graph,
        graphs,
        t,
        handleSelectionChange,
        handleSelectionPeriodChange,
        loading,
        selectedBusinesses,
        selectedPeriod,
    } = useLogic()

    const testKpi = () => {
        if (kpi?.period.views_total == 0 && kpi?.period.actions_website == 0 && kpi?.period.actions_phone == 0) {
            return false
        }
        return true
    }

    return (
        <Container className={classes.container}>
            <TitleWrapper
                pageIcon="barChart"
                title={t("LOCAL_PRESENCE.TITLE_LOCAL")}
                withSearch={{
                    checkboxList: [],
                    inputLabel: "",
                    popoverTitle: "",
                }}
            />
            {loading && <SkeletonStat type="local_presence" />}
            {mockData === null && !loading && (
                <FilterAnalytics
                    selectedPeriod={selectedPeriod}
                    selectedBusinesses={selectedBusinesses}
                    handleSelectionChange={handleSelectionChange}
                    handleSelectionPeriodChange={handleSelectionPeriodChange}
                />
            )}

            {!loading && (kpi || mockData) && (
                <Stack className={classes.views}>
                    <BlocNumber
                        label={t("LOCAL_PRESENCE.VIEWS")}
                        tooltip={t("LOCAL_PRESENCE.TOTAL_VARIATION")}
                        data-testid="viewsTotal"
                        number={convertBigNumberSpace(
                            mockData?.period
                                ? mockData.period?.views_total
                                : testKpi()
                                ? kpi?.period?.views_total
                                : undefined,
                            t("LOCAL_PRESENCE.NOT_AVAILABLE")
                        )}
                        noData={
                            (!kpi?.period?.views_total && kpi?.period?.views_total != 0) ||
                            (!testKpi() && kpi?.period?.views_total == 0)
                        }
                        pourcentage={convertPourcentageString(kpi?.previous_period?.views_total_variation, testKpi())}
                        icon={"eye"}
                        color={"#2D97D3"}
                        type={
                            kpi?.previous_period?.views_total_variation === 0
                                ? "no-data"
                                : convertStyleString(kpi?.previous_period?.views_total_variation)
                        }
                    />
                    <BlocNumber
                        label={t("LOCAL_PRESENCE.CLICKS")}
                        tooltip={t("LOCAL_PRESENCE.WEBSITE_VARIATION")}
                        number={convertBigNumberSpace(
                            testKpi() ? kpi?.period?.actions_website : undefined,
                            t("LOCAL_PRESENCE.NOT_AVAILABLE")
                        )}
                        noData={
                            (!kpi?.period?.actions_website && kpi?.period?.actions_website != 0) ||
                            (!testKpi() && kpi?.period?.actions_website == 0)
                        }
                        pourcentage={convertPourcentageString(
                            kpi?.previous_period?.actions_website_variation,
                            testKpi()
                        )}
                        icon={"globe"}
                        color={"#296ED7"}
                        type={
                            kpi?.previous_period?.actions_website_variation === 0
                                ? "no-data"
                                : convertStyleString(kpi?.previous_period?.actions_website_variation)
                        }
                    />
                    <BlocNumber
                        label={t("LOCAL_PRESENCE.CALLS")}
                        tooltip={t("LOCAL_PRESENCE.PHONE_VARIATION")}
                        number={convertBigNumberSpace(
                            testKpi() ? kpi?.period?.actions_phone : undefined,
                            t("LOCAL_PRESENCE.NOT_AVAILABLE")
                        )}
                        noData={
                            (!kpi?.period?.actions_phone && kpi?.period?.actions_phone != 0) ||
                            (!testKpi() && kpi?.period?.actions_phone == 0)
                        }
                        pourcentage={convertPourcentageString(kpi?.previous_period?.actions_phone_variation, testKpi())}
                        icon={"phoneIncoming"}
                        color={"#2A44CB"}
                        type={
                            kpi?.previous_period?.actions_phone_variation === 0
                                ? "no-data"
                                : convertStyleString(kpi?.previous_period?.actions_phone_variation)
                        }
                    />
                </Stack>
            )}
            {graph && (
                <>
                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="eye" sx={{ color: "#309EDB" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("LOCAL_PRESENCE.VIEWS")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs.views} />
                    </Paper>
                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="globe" sx={{ color: "#3175DB" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("LOCAL_PRESENCE.CLICKS")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs.clicks} />
                    </Paper>

                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="phoneIncoming" sx={{ color: "#2E49DB" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("LOCAL_PRESENCE.CALLS")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs.calls} />
                    </Paper>
                </>
            )}
        </Container>
    )
}
