import { StatisticsApi } from "@/api"
import { AnalyticsData } from "@/types/statistic"

import { FetchKpiInput } from "@/api/statistics"
import dayjs from "dayjs"
import { t } from "i18next"
import { ChartService } from ".."

interface PropsSerie {
    name?: string
    data: number[]
    type?: string
}

class StatisticLocalPresenceService {
    fetchKpi = async (filterParams: FetchKpiInput) => {
        return await StatisticsApi.fetchKpiLocal(filterParams)
    }

    resolveGraphsViews = (graph: AnalyticsData, type: string) => {
        const categories = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = type === "month" ? "DD MMM" : "MMM YY"

        const views_mobile_search = {
            name: t("LOCAL_PRESENCE.views_mobile_search".toUpperCase()),
            data: [],
            type: "column",
            color: "#309DDB",
        }
        const views_desktop_search = {
            name: t("LOCAL_PRESENCE.views_desktop_search".toUpperCase()),
            data: [],
            type: "column",
            color: "#1A75A8",
        }
        const views_desktop_maps = {
            name: t("LOCAL_PRESENCE.views_desktop_maps".toUpperCase()),
            data: [],
            type: "column",
            color: "#87C6EA",
        }
        const views_mobile_maps = {
            name: t("LOCAL_PRESENCE.views_mobile_maps".toUpperCase()),
            data: [],
            type: "column",
            color: "#B9DEF3",
        }

        const total = {
            name: t("LOCAL_PRESENCE.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
            markers: {
                size: 6, // Set size to display markers
                lineType: "dotted", // Set lineType to "dotted" for dotted line markers
            },
        }

        const pourcentages = []
        const totalCurrent = []

        let maxPeriod = 0
        period.forEach((element) => {
            categories.push(dayjs(element.date).format(format))
            views_desktop_search.data.push(element.views_desktop_search)
            views_mobile_search.data.push(element.views_mobile_search)
            views_desktop_maps.data.push(element.views_desktop_maps)
            views_mobile_maps.data.push(element.views_mobile_maps)
            totalCurrent.push(element.views_total)
        })

        previousPeriod.forEach((element) => {
            let valueTotal = element.views_total
            if (element.views_total === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            } else if (element.views_total !== 0) {
                maxPeriod = element.views_total
            }

            total.data.push(valueTotal)
            pourcentages.push({
                views_desktop_search: maxPeriod == 0 ? null : element.views_desktop_search_variation,
                views_mobile_search: maxPeriod == 0 ? null : element.views_mobile_search_variation,
                views_desktop_maps: maxPeriod == 0 ? null : element.views_desktop_maps_variation,
                views_mobile_maps: maxPeriod == 0 ? null : element.views_mobile_maps_variation,
                total: maxPeriod == 0 ? null : element.views_total_variation,
            })
        })

        const maxYaxis = Math.max(...totalCurrent, ...total.data)
        const series: PropsSerie[] = [views_desktop_search, views_mobile_search, views_desktop_maps, views_mobile_maps]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: [0, 0, 0, 0, 4],
            curve: "straight",
            dashArray: [0, 0, 0, 0, 8],
        }

        const graphs = { series, categories, pourcentages, totalCurrent, stroke, maxYaxis }

        const yFormatters = [
            ChartService.createFormatter("views_desktop_search", graphs),
            ChartService.createFormatter("views_mobile_search", graphs),
            ChartService.createFormatter("views_desktop_maps", graphs),
            ChartService.createFormatter("views_mobile_maps", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return { ...graphs, yFormatters }
    }

    resolveGraphsClicks = (graph: AnalyticsData, type: string) => {
        const categories = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = type === "month" ? "DD MMM" : "MMM YY"

        const actions_conversations = {
            name: t("LOCAL_PRESENCE.actions_conversations".toUpperCase()),
            data: [],
            type: "column",
            color: "#0D50B3",
        }
        const actions_driving_directions = {
            name: t("LOCAL_PRESENCE.actions_driving_directions".toUpperCase()),
            data: [],
            type: "column",
            color: "#3075DB",
        }
        const actions_phone = {
            name: t("LOCAL_PRESENCE.actions_phone".toUpperCase()),
            data: [],
            type: "column",
            color: "#87AFEA",
        }
        const actions_website = {
            name: t("LOCAL_PRESENCE.actions_website".toUpperCase()),
            data: [],
            type: "column",
            color: "#B9D1F3",
        }
        const total = {
            name: t("LOCAL_PRESENCE.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
        }

        const pourcentages = []
        const totalCurrent = []
        let maxPeriod = 0

        period.forEach((element) => {
            categories.push(dayjs(element.date).format(format))
            actions_conversations.data.push(element.actions_conversations)
            actions_driving_directions.data.push(element.actions_driving_directions)
            actions_phone.data.push(element.actions_phone)
            actions_website.data.push(element.actions_website)
            totalCurrent.push(element.actions_total)
        })

        previousPeriod.forEach((element) => {
            let valueTotal = element.actions_total
            if (element.actions_total === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            }
            if (element.actions_total !== 0) {
                maxPeriod = element.actions_total
            }

            total.data.push(valueTotal)
            pourcentages.push({
                actions_conversations: maxPeriod == 0 ? null : element.actions_conversations_variation,
                actions_driving_directions: maxPeriod == 0 ? null : element.actions_driving_directions_variation,
                actions_phone: maxPeriod == 0 ? null : element.actions_phone_variation,
                actions_website: maxPeriod == 0 ? null : element.actions_website_variation,
                total: maxPeriod == 0 ? null : element.actions_total_variation,
            })
        })

        const maxYaxis = Math.max(...totalCurrent, ...total.data)
        const series: PropsSerie[] = [actions_conversations, actions_driving_directions, actions_phone, actions_website]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: [0, 0, 0, 0, 4],
            curve: "straight",
            dashArray: [0, 0, 0, 0, 8],
        }

        const graphs = { series, categories, pourcentages, totalCurrent, stroke, maxYaxis }

        const yFormatters = [
            ChartService.createFormatter("actions_conversations", graphs),
            ChartService.createFormatter("actions_driving_directions", graphs),
            ChartService.createFormatter("actions_phone", graphs),
            ChartService.createFormatter("actions_website", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return { ...graphs, yFormatters }
    }

    resolveGraphsCalls = (graph: AnalyticsData, type: string) => {
        const categories = []
        const period = graph.period
        const previousPeriod = graph?.previous_period ?? []
        const format = type === "month" ? "DD MMM" : "MMM YY"

        const calls = {
            name: t("LOCAL_PRESENCE.actions_phone".toUpperCase()),
            data: [],
            type: "column",
            color: "#2E49DB",
        }

        const total = {
            name: t("LOCAL_PRESENCE.TOTAL".toUpperCase()),
            data: [],
            type: "line",
            color: "#68738D",
        }

        const pourcentages = []
        const totalCurrent = []
        let maxPeriod = 0

        period.forEach((element) => {
            categories.push(dayjs(element.date).format(format))
            calls.data.push(element.actions_phone)

            totalCurrent.push(element.actions_phone)
        })

        previousPeriod.forEach((element) => {
            let valueTotal = element.actions_phone
            if (element.actions_phone === maxPeriod && maxPeriod === 0) {
                valueTotal = null
            } else if (element.actions_phone !== 0) {
                maxPeriod = element.actions_phone
            }

            total.data.push(valueTotal)
            pourcentages.push({
                actions_phone: maxPeriod == 0 ? null : element.actions_phone_variation,
                total: maxPeriod == 0 ? null : element.actions_phone_variation,
            })
        })

        const maxYaxis = Math.max(...totalCurrent, ...total.data)
        const series: PropsSerie[] = [calls]

        if (previousPeriod?.length > 0) {
            series.push(total)
        }

        const stroke = {
            width: [0, 4],
            curve: "straight",
            dashArray: [0, 8],
        }

        const graphs = { series, categories, pourcentages, totalCurrent, stroke, maxYaxis }

        const yFormatters = [
            ChartService.createFormatter("actions_phone", graphs),
            ChartService.createFormatter("total", graphs),
        ]

        return { ...graphs, yFormatters, tooltip: { enabledOnSeries: [0] } }
    }
}
export default new StatisticLocalPresenceService()
