import { create } from "zustand"

export interface ListAttributes {
    title: string
    attributes: AttributeType[]
}

export interface AttributeType {
    id?: string
    title: string
    options: any
    selected?: any
    type: string
}

export interface AttributChecked {
    display: string
    value: string
    checked: boolean
}

export interface IAttributesStore {
    listAttributes: ListAttributes[]
}

const initalState: IAttributesStore = {
    listAttributes: [],
}

export interface IAttributesSetter {
    update?: (update: Partial<IAttributesStore>) => void
    flush?: () => void
}

const useAttributesStore = create<IAttributesStore & IAttributesSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default useAttributesStore
