import { SxProps } from "@mui/material"
import { CountryCode } from "libphonenumber-js"
import { MuiTelInput } from "mui-tel-input"
import { RefObject } from "react"
import { Control, Controller } from "react-hook-form"
export interface BaseInputPhoneProps {
    label: string
    /**
     * react hook control
     */
    control: Control<any>
    /**
     * input value from useWatch
     */
    value?: string
    /**
     * restrict the list of countries
     */
    onlyCountries?: CountryCode[]
    excludedCountries?: CountryCode[]
    /**
     * lang in which the country name is displayed
     */
    lang?: string
    name: string
    inputRef?: RefObject<Element>
    defaultCountry?: CountryCode

    required?: boolean
    error?: boolean
    errorLabel?: string
    onChange?: (info: IPhoneInfo) => void
    disabled?: boolean
}

interface IPhoneInfo {
    countryCallingCode: string
    countryCode: string
    nationalNumber: string
    numberType: string
    numberValue: string
    reason: string
}

export const sx: SxProps = {
    borderRadius: "8px",
    "& .MuiButtonBase-root": {
        padding: 0,
    },
    "& .MuiInputBase-root": {
        paddingTop: "3px",
    },
    "& .MuiFilledInput-input": {
        paddingTop: "22px",
    },
    "& img": {
        width: 24,
        height: 18,
        objectFit: "contain",
    },
    "& fieldset": {
        border: "none",
    },
    "body & .MuiFilledInput-root": {
        backgroundColor: "rgba(172, 182, 229, 0.16)",
        "&::after, &:hover:before, &::before": {
            borderBottom: "1px solid rgba(91, 125, 216, 0.12)",
        },
        "&.Mui-error": {
            "&::after, &:hover:before, &::before": {
                borderBottom: "1px solid",
                borderBottomColor: "error.main",
            },
        },
    },
    "& label, & label.Mui-focused": {
        color: "action.active",
    },
    "& label.Mui-error, & label.Mui-focused.Mui-error": {
        color: "error.main",
    },
    "& .MuiFilledInput-root, & .MuiFilledInput-root::after, & .MuiFilledInput-root.Mui-focused::after": {
        borderRadius: "8px",
        overflow: "hidden",
    },
    "& .MuiFormLabel-asterisk": {
        color: "primary.main",
        "&.Mui-error": {
            color: "error.main",
        },
    },
}

const BaseInputPhone = ({
    label,
    onlyCountries = [],
    inputRef,
    excludedCountries = [],
    value = null,
    lang,
    control,
    name,
    required = false,
    error = false,
    errorLabel,
    defaultCountry = "CA",
    disabled = false,
    onChange,
}: BaseInputPhoneProps) => {
    return (
        <>
            {control ? (
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { ref, ...field } }) => (
                        <MuiTelInput
                            {...field}
                            sx={sx}
                            label={label}
                            required={required}
                            inputRef={inputRef ?? ref}
                            data-testid="input-phone"
                            defaultCountry={defaultCountry}
                            onlyCountries={onlyCountries}
                            forceCallingCode
                            disabled={disabled}
                            InputProps={{
                                sx: {
                                    "& .MuiInputAdornment-root": {
                                        marginRight: 0.5,
                                        "&.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)": {
                                            marginTop: "13px !important",
                                        },
                                    },
                                    "& p.MuiTypography-root": {
                                        marginTop: "2px",
                                        marginLeft: 1,
                                        paddingRight: 0,
                                        borderRight: "none",
                                    },
                                },
                            }}
                            variant="filled"
                            MenuProps={{
                                slotProps: {
                                    paper: {
                                        sx: {
                                            padding: 0,
                                        },
                                    },
                                    root: {
                                        sx: {
                                            "& .MuiBackdrop-root": {
                                                opacity: "0 !important",
                                            },
                                        },
                                    },
                                },
                            }}
                            langOfCountryName={lang}
                            helperText={error ? errorLabel : ""}
                            error={error}
                            onChange={(value: string, info: IPhoneInfo) => {
                                field.onChange(value)
                                if (typeof onChange === "function") {
                                    onChange(info)
                                }
                            }}
                        />
                    )}
                />
            ) : (
                <MuiTelInput
                    sx={sx}
                    label={label}
                    required={required}
                    inputRef={inputRef}
                    data-testid="input-phone"
                    defaultCountry={defaultCountry}
                    onlyCountries={onlyCountries}
                    value={value}
                    forceCallingCode
                    InputProps={{
                        sx: {
                            "& .MuiInputAdornment-root": {
                                marginRight: 0.5,
                                "&.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)": {
                                    marginTop: "13px !important",
                                },
                            },
                            "& p.MuiTypography-root": {
                                marginTop: "2px",
                                marginLeft: 1,
                                paddingRight: 0,
                                borderRight: "none",
                            },
                        },
                    }}
                    variant="filled"
                    MenuProps={{
                        slotProps: {
                            paper: {
                                sx: {
                                    padding: 0,
                                },
                            },
                            root: {
                                sx: {
                                    "& .MuiBackdrop-root": {
                                        opacity: "0 !important",
                                    },
                                },
                            },
                        },
                    }}
                    langOfCountryName={lang}
                    helperText={error ? errorLabel : ""}
                    error={error}
                    onChange={(value: string, info: any) => {
                        if (typeof onChange === "function") {
                            onChange(info)
                        }
                    }}
                />
            )}
        </>
    )
}

export default BaseInputPhone
