import { EntitiesApi, ListingApi, PlatformApi } from "@/api"
import { PlatformInfo } from "@/api/platform"

export type PlatformLocationMap = {
    synchronised: number
    inProgress: number
    name: string
    logo: string
    id: string
}

type SummaryCount = {
    synchronised: number
    inProgress: number
    listings: number
}

export type ListingData = {
    platformMap: { [id: string]: PlatformLocationMap }
    locationMap: { [id: string]: PlatformLocationMap }
    summaryCount: SummaryCount
}

type LocationMapResponse = {
    ids: string[]
    count: number
}

type LocationMapInput = {
    user_uid: string
    entity_type: string
}

class ListingService {
    getLocationMap = async ({ user_uid, entity_type }: LocationMapInput): Promise<LocationMapResponse> => {
        const entities = await EntitiesApi.fetchEntitiesWithFields({
            user_uid,
            entity_type,
            fields: "id",
        })
        const ids = entities?.locations?.map((response) => response.uid) || []
        return {
            ids,
            count: ids.length,
        }
    }

    getPlatformInfo = async (ids: string[]) => {
        const platforms = await PlatformApi.fetchAll(ids)
        const platformMapInfoById = {}
        for (const data of platforms.data || []) {
            platformMapInfoById[data.id] = { ...data }
        }
        return platformMapInfoById
    }

    getListingsMap = async (locationsId: string[]): Promise<ListingData> => {
        const listings = await ListingApi.fetchListings({ locationsId })
        const platformIds = listings
            .map(({ platforms }) => {
                return platforms.map((platform) => platform.platformId)
            })
            .flat()
        const platformInfosById = await this.getPlatformInfo(platformIds)
        const summaryCount: SummaryCount = {
            synchronised: 0,
            inProgress: 0,
            listings: 0,
        }
        const platformMap: { [id: string]: PlatformLocationMap } = {}
        const locationMap: { [id: string]: PlatformLocationMap } = {}
        for (const { platforms, location_id, location_logo, location_name } of listings) {
            locationMap[location_id] ??= {
                synchronised: 0,
                inProgress: 0,
                name: location_name,
                logo: location_logo,
                id: location_id,
            }
            for (const platform of platforms) {
                const platformInfo = (platformInfosById[platform.platformId] || {}) as PlatformInfo
                platformMap[platform.platformId] ??= {
                    synchronised: 0,
                    inProgress: 0,
                    name: platformInfo.name,
                    logo: platformInfo.logoUrl,
                    id: platform.platformId,
                }

                // STATUS == "LIVE" Synchronisé
                if (platform.status === "LIVE") {
                    platformMap[platform.platformId].synchronised++
                    summaryCount.synchronised++
                    locationMap[location_id].synchronised++
                }
                // STATUS !== "LIVE" && STATUS !== "UNAVAILABLE" Encours
                else if (platform.status !== "UNAVAILABLE") {
                    platformMap[platform.platformId].inProgress++
                    summaryCount.inProgress++
                    locationMap[location_id].inProgress++
                }
                // Listings = Synchronisé + Encours
                summaryCount.listings++
            }
        }

        return {
            platformMap,
            locationMap,
            summaryCount,
        }
    }
}

export default new ListingService()
