import { CampaignApi } from "@/api"
import { SendCampaignProps } from "@/api/campaign"
import { socket } from "@/config/socket"
import { MAX_INPUT } from "@/data/limiter"
import usePhoneInputLogic from "@/hooks/phone-input/useLogic"
import { CookiesService, EditModelService } from "@/services"
import CampaignStore from "@/store/campaign"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { convertHtml, isValidEmail, removeDuplicateUrl } from "@/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import { isValidNumberForRegion } from "libphonenumber-js"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import { read, utils } from "xlsx"
import { z } from "zod"
import useFormater from "../useFormater"
import useNotification from "../useNotification"
import useUiPageSession from "../useUiPageSession"
const useLogic = () => {
    useUiPageSession({
        title: "CAMPAIGN.TITLE",
        page: "campaign",
    })
    const { t } = useTranslation()
    const {
        update,
        locations,
        defaultFlag,
        isLoading,
        dataSent,
        feedback,
        openUpload,
        file,
        contacts,
        credits,
        message,
        rawText,
        editorText,
        noCredit,
        creditAsked,
        loadingBusiness,
    } = CampaignStore()
    const { parseVariables, internationalPhone } = useFormater()
    const { notif } = useNotification()
    const { updatePreviousItem, previousItem } = UiPageSessionStore()
    const { preferredLanguage } = UiStore()
    const [hasModel, setHasModel] = useState<boolean>(undefined)
    const [sendSuccess, setSendSuccess] = useState<any>({ success: false, content: 0 })
    const { isPhoneValid } = usePhoneInputLogic()
    const [listReviews, setListReviews] = useState([])

    const showUpload = useCallback(
        () =>
            update({
                openUpload: true,
            }),
        []
    )
    const closeUpload = useCallback(
        () =>
            update({
                openUpload: false,
            }),
        []
    )

    const updateBusiness = useCallback(
        (business: any[]) =>
            update({
                locations: business,
            }),
        []
    )
    const updateFile = useCallback((newFile: File) => update({ file: newFile }), [])

    const steps = [t("CAMPAIGN.RECIPIENTS"), t("CAMPAIGN.MESSAGE"), t("CAMPAIGN.PREVIEW")]

    const MESSAGE_TEXT = useMemo(() => {
        return {
            FIELD_REQUIRED: t("USERS.FIELD_REQUIRED"),
            INVALID_FORMAT_EMAIL: t("USERS.INVALID_FORMAT_EMAIL"),
        }
    }, [t])
    const { profile } = UserSessionStore()
    const schema = z
        .object({
            business: z.string().nonempty(MESSAGE_TEXT.FIELD_REQUIRED),
            type: z.string(),
            firstname: z.string().optional(),
            lastname: z.string().optional(),
            email: z.string().optional(),
            phone: z.string().optional(),
            confirmation: z.boolean(),
            subject: z.string().optional(),
            message: z.string().optional(),
            step: z.number(),
            feedback: z.boolean(),
            country: z.string().nullable().optional(),
            lang: z.string(),
        })
        .superRefine(({ type, phone, email, step }, refinementContext) => {
            if (contacts.length === 0) {
                if (type === "sms" && !phone) {
                    return refinementContext.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: MESSAGE_TEXT.FIELD_REQUIRED,
                        path: ["phone"],
                    })
                }
                if (type === "email" && !email) {
                    return refinementContext.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: MESSAGE_TEXT.FIELD_REQUIRED,
                        path: ["email"],
                    })
                }
            }
        })

    type SchemaValuesType = z.infer<typeof schema>
    const defaultValues = useMemo(() => {
        let data = {
            business: "",
            type: "sms",
            message: "",
            step: 0,
            feedback: true,
            country: "CA",
            firstname: "",
            lastname: "",
            lang: "en",
        }

        if (previousItem?.campaign) {
            data = previousItem?.campaign
        }

        return data
    }, [previousItem, preferredLanguage])

    const { handleSubmit, formState, control, setValue, register, watch, reset } = useForm<SchemaValuesType>({
        defaultValues,
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const formValue = useWatch({ control })

    const atValues = useMemo(() => {
        return [
            { id: "firstname", value: t("CAMPAIGN.FIRSTNAME") },
            { id: "lastname", value: t("CAMPAIGN.LASTNAME") },
            { id: "business", value: t("CAMPAIGN.BUSINESS") },
            { id: "url", value: "url" },
        ]
    }, [])

    const step2isValid = useMemo(() => {
        if (formValue?.step != 1) return true
        else return formValue?.step === 1 && formValue?.message && formValue?.message !== "<p><br></p>"
    }, [formValue?.step, formValue?.message])

    const step1isValid = useMemo(() => {
        if (formValue?.step != 0) return true
    }, [formValue?.step])

    const phoneIsValid = useMemo(() => {
        if (formValue?.type !== "sms") return true
        if (contacts?.length !== 0) return true
        else return !!formValue?.phone && !/^\+\d{1,4}$/.test(formValue?.phone)
    }, [formValue, contacts])

    const isThereNewContact = useMemo(() => {
        if (contacts?.length === 0) return false
        return (
            contacts?.length > 0 &&
            (!!formValue?.firstname ||
                !!formValue?.lastname ||
                !!formValue?.email ||
                (!!formValue?.phone && !/^\+\d{1,4}$/.test(formValue?.phone)))
        )
    }, [contacts, formValue])

    const currentLocation = useMemo(
        () => (locations || []).find((item) => item.uid === formValue?.business),
        [formValue?.business, locations]
    )

    const updateFeedback = useCallback(() => {
        setValue("feedback", !formValue?.feedback)
    }, [formValue])

    const infos = useMemo(() => {
        if (contacts?.length > 0) {
            return {
                lang: formValue?.lang,
                subject: formValue?.subject,
                ...contacts[0],
            }
        }
        return formValue
    }, [contacts, formValue])

    const typeLabel = useMemo(() => {
        return formValue?.type === "sms" ? t("CAMPAIGN.PHONE_NUMBER") : t("LABEL.EMAIL")
    }, [formValue])

    const handleMessage = useCallback(
        (value: { html: string; delta: any; rawText: string; editorText: string }) => {
            setValue("message", value.html)
        },
        [formValue, contacts, currentLocation]
    )

    const formatFileData = useCallback(
        (fileJson: any) => {
            const valeursUniques = {}
            const filteredJson = fileJson
                .map((json) => Object.values(json))
                ?.filter((contact: string[]) => {
                    if (!contact[2]) return false
                    if (formValue?.type === "sms") {
                        const formatedPhone = internationalPhone(
                            contact[2]?.toString() || "",
                            currentLocation?.country || "CA"
                        )
                        return isValidNumberForRegion(formatedPhone, currentLocation?.country || "CA")
                    } else {
                        return isValidEmail(contact[2]?.toString())
                    }
                })
                ?.map((contact: string[]) => {
                    const formatedPhone = internationalPhone(
                        contact[2]?.toString() || "",
                        currentLocation?.country || "CA"
                    )
                    return {
                        uid: uuidv4(),
                        [t("CAMPAIGN.FIRSTNAME")]: contact[0],
                        [t("CAMPAIGN.LASTNAME")]: contact[1],
                        [typeLabel]: formValue?.type === "sms" && contact[2] ? formatedPhone : contact[2],
                    }
                })

            return filteredJson?.filter((objet) => {
                if (Object.prototype.hasOwnProperty.call(valeursUniques, objet[typeLabel])) {
                    return false
                }
                valeursUniques[objet[typeLabel]] = true
                return true
            })
        },
        [currentLocation, formValue?.type, typeLabel]
    )

    const readFile = useCallback(
        async (file: File) => {
            update({
                contacts: [],
            })
            const workbook = read(await file.arrayBuffer())
            const fileJson = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { defval: false })
            if (fileJson.length) {
                const finalJson = formatFileData(fileJson)
                update({
                    contacts: finalJson,
                    pagination: {
                        total_count: finalJson.length,
                        page_number: 0,
                        page_size: 10,
                    },
                })
            }
        },
        [update, internationalPhone, formatFileData, currentLocation, formValue?.type]
    )

    const updateContacts = useCallback(
        (data: any) =>
            update({
                contacts: data,
            }),
        [update]
    )
    const remainingCredtis = useMemo(() => {
        if (!credits.length) return 0
        const type = formValue?.type === "sms" ? "sms_rb" : "email_rb"
        const credit = credits?.filter((item) => item.type === type)

        return credit.length && credit[0]?.remaining
    }, [formValue, credits])

    const hasCredit = useMemo(() => {
        return remainingCredtis > 0
    }, [remainingCredtis])

    const handleNext = () => {
        setValue("step", formValue?.step + 1)
    }

    const fetchCredit = useCallback(
        async (company_uid: string) => {
            update({
                creditFetched: false,
                credits: [],
            })
            const response = await CampaignApi.getCredit({ company_uid })
            if (response) {
                update({
                    credits: response,
                    creditFetched: true,
                })
            }
        },
        [update, formValue]
    )

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        console.log(errors)
    }

    const handleBack = useCallback(() => {
        updatePreviousItem({
            campaign: formValue,
            // message,
        })
        setValue("step", formValue?.step - 1)
    }, [formValue])

    const handleCancel = () => {
        handleBack()
    }

    const onSubmit = useCallback(
        (values: any) => {
            switch (formValue?.step) {
                case 0:
                    if (remainingCredtis < contacts.length) {
                        update({
                            noCredit: true,
                        })
                        return
                    } else {
                        update({
                            businessSelect: { business: formValue.business, lang: preferredLanguage },
                        })
                        handleNext()
                    }
                    break
                case 1:
                    if (
                        (formValue?.type == "sms" && !formValue.message.includes('data-id="url"')) ||
                        (formValue?.type == "email" &&
                            !formValue.message.includes('data-id="url"') &&
                            !formValue?.feedback)
                    ) {
                        const HtmlLink =
                            '<p><span class="mention" data-index="2" data-denotation-char="@" data-id="url" data-value="url"><span contenteditable="false">@url</span></span> </p>'
                        setValue("message", formValue.message + HtmlLink)
                    }
                    handleNext()
                    break
                default:
                    send()
                    break
            }
        },
        [formValue, contacts, remainingCredtis, preferredLanguage]
    )

    const closeSuccessMessage = () => {
        update({
            activeStep: 0,
            dataSent: false,
            // message: "",
            credits: [],
            contacts: [],
        })
        reset()
        setValue("lang", preferredLanguage !== "en" ? "fr-FR" : "en")
    }

    const closeCreditModal = useCallback(() => {
        update({
            noCredit: false,
            creditAsked: false,
        })
    }, [update])

    const sendRequest = useCallback(async () => {
        update({
            noCredit: true,
            creditAsked: true,
        })
        if (currentLocation?.companyId && profile?.uid && formValue?.type) {
            const body = {
                company_uid: currentLocation?.companyId,
                user_uid: profile?.uid,
                type: formValue?.type,
                lang: preferredLanguage.toLowerCase(),
            }
            await CampaignApi.requestCredit({ body })
        }
    }, [currentLocation, formValue?.type, profile, preferredLanguage])

    const sendSocketOne = useCallback(
        (checkContent) => {
            let count = 0
            socket.emit("connectAvis", {
                companyId: currentLocation.companyId,
                gmbId: currentLocation.gmbAccessId,
                userId: profile.uid,
                uid: currentLocation.uid,
            })
            socket.on("avisState", (res) => {
                if (res && checkContent) {
                    const contactCheck = checkContent.filter((item) => item.to === res.to)
                    if (contactCheck && count < checkContent.length) {
                        const index = checkContent.indexOf(contactCheck[0])
                        checkContent[index].status = res.status
                        count++
                    }
                    if (count === checkContent.length) {
                        let success = true
                        checkContent.map((contact) => {
                            if (contact.status === false) {
                                success = false
                            }
                        })
                        if (success) {
                            update({
                                isLoading: false,
                                dataSent: true,
                            })
                        } else {
                            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                        }

                        update({
                            isLoading: false,
                        })
                    }
                } else {
                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                    update({
                        isLoading: false,
                    })
                }
            })
        },
        [currentLocation, formValue]
    )

    const isDisabled = useMemo(() => {
        let isFormInvalid = !formValue?.business || !formValue.confirmation
        if (!isFormInvalid) {
            if (formValue?.type === "sms") {
                if (formValue?.step === 1) {
                    isFormInvalid = editorText?.length > MAX_INPUT.SMS
                } else {
                    isFormInvalid = !isPhoneValid(formValue?.phone ?? "")
                }
            } else if (formValue?.step === 1) {
                if (formValue?.type === "email") {
                    isFormInvalid = !formValue?.subject
                } else {
                    isFormInvalid = !formValue?.message
                }
            } else {
                isFormInvalid =
                    contacts.length === 0 &&
                    (!formValue?.email || formValue?.email === undefined || !isValidEmail(formValue?.email))
            }
        }
        return isFormInvalid
    }, [formValue, editorText])

    const send = useCallback(async () => {
        update({
            isLoading: true,
            dataSent: false,
        })
        const finalText = contacts.length
            ? parseVariables(formValue?.message, contacts[0], currentLocation?.name)
            : parseVariables(
                  formValue?.message,
                  {
                      firstname: formValue?.firstname,
                      lastname: formValue?.lastname,
                      info: formValue?.type === "sms" ? formValue?.phone : formValue?.email,
                  },
                  currentLocation?.name
              )

        const messages = contacts.length
            ? contacts.map((contact) => {
                  return {
                      to: contact[typeLabel],
                      body: parseVariables(
                          formValue?.type === "sms"
                              ? convertHtml(removeDuplicateUrl(formValue?.message))
                              : formValue?.message,
                          contact,
                          currentLocation.name
                      ),
                  }
              })
            : [
                  {
                      to: formValue?.type === "sms" ? formValue?.phone : formValue?.email,
                      body: formValue?.type === "sms" ? convertHtml(removeDuplicateUrl(finalText)) : finalText,
                  },
              ]

        const checkContent = contacts.length
            ? contacts.map((contact) => {
                  return {
                      to: contact[typeLabel],
                      status: null,
                  }
              })
            : [
                  {
                      to: formValue?.type === "sms" ? formValue?.phone : formValue?.email,
                      status: null,
                  },
              ]
        const body: SendCampaignProps = {
            type: formValue?.type,
            companyId: currentLocation?.companyId,
            location: {
                id: currentLocation?.uid,
                name: currentLocation?.name,
                logo: currentLocation?.logo,
                zip: currentLocation?.zip,
                province_code: currentLocation?.region,
                country: currentLocation?.country,
                city: currentLocation?.city,
            },
            feedback: formValue?.feedback,
            content: messages,
        }

        if (currentLocation?.locationState?.newReviewUri) {
            body["longLink"] = currentLocation?.locationState?.newReviewUri
        }

        if (formValue?.type === "sms") {
            body["smsSender"] = CookiesService.get("smsSender")
        } else {
            body["lang"] = formValue?.lang?.toLowerCase()
            body["subject"] = formValue?.subject
            body["from"] = CookiesService.get("emailSpotconnect")
            body["location"]["address"] = currentLocation?.address
        }
        sendSocketOne(checkContent)
        const response = await CampaignApi.send({ body })

        if (response?.data === "success" && checkContent?.length > 0) {
            setSendSuccess({ success: true, content: checkContent?.length })
        } else {
            setSendSuccess({ success: false, content: 0 })
            update({
                isLoading: false,
            })
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
    }, [contacts, currentLocation, formValue, preferredLanguage, message, typeLabel])

    useEffect(() => {
        if (sendSuccess?.success && !dataSent && isLoading) {
            setTimeout((dataSent, isLoading) => {
                if (!dataSent && isLoading) {
                    update({
                        isLoading: false,
                    })
                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                }
            }, 120000 * sendSuccess.content)
        }
    }, [sendSuccess, dataSent, isLoading])

    useEffect(() => {
        if (file) {
            readFile(file)
        }
    }, [file])

    useEffect(() => {
        if (formValue?.business && locations.length) {
            fetchCredit(currentLocation?.companyId)
            setValue("phone", "")
            update({
                defaultFlag: currentLocation?.country || "CA",
            })
        } else {
            update({
                credits: [],
                creditFetched: false,
            })
        }
    }, [formValue?.business, currentLocation, locations])

    const hasNewUrl = useMemo(() => {
        return (
            currentLocation?.locationState?.newReviewUri !== "" &&
            currentLocation?.locationState?.newReviewUri !== undefined
        )
    }, [currentLocation])

    useEffect(() => {
        if (formValue?.type) {
            update({
                contacts: [],
                file: null,
                pagination: {
                    total_count: 0,
                    page_number: 0,
                    page_size: 0,
                },
            })
            setValue("confirmation", false)
        }
    }, [formValue?.type])

    useEffect(() => {
        return () => {
            reset()
            update({
                contacts: [],
                file: null,
                pagination: {
                    total_count: 0,
                    page_number: 0,
                    page_size: 0,
                    rows: [],
                },
                dataSent: false,
            })
            setValue("confirmation", false)
        }
    }, [])

    useEffect(() => {
        if (preferredLanguage) {
            setValue("lang", preferredLanguage !== "en" ? "fr-FR" : "en")
        }
    }, [preferredLanguage])

    useEffect(() => {
        if (!noCredit) {
            update({
                creditAsked: false,
            })
        }
    }, [noCredit])

    const business = watch("business")

    const fetchReviews = useCallback(async () => {
        if (business) {
            const response = await EditModelService.fetchReviews(business)
            if (response) {
                setHasModel(response.has_model)
                setListReviews(response.data)
            }
        }
    }, [t, business])

    useEffect(() => {
        if (business && step1isValid == undefined) {
            fetchReviews()
        }
    }, [business, step1isValid])

    return {
        t,
        control,
        openUpload,
        file,
        updateFile,
        closeUpload,
        setValue,
        handleSubmit,
        showUpload,
        formState,
        register,
        watch,
        message,
        rawText,
        handleMessage,
        atValues,
        credits,
        parseVariables,
        send,
        updateBusiness,
        defaultFlag,
        feedback,
        updateFeedback,
        onErrors,
        dataSent,
        isLoading,
        closeSuccessMessage,
        steps,
        isDisabled,
        step2isValid,
        handleBack,
        handleCancel,
        phoneIsValid,
        onSubmit,
        hasNewUrl,
        currentLocation,
        updateContacts,
        infos,
        isThereNewContact,
        noCredit,
        creditAsked,
        closeCreditModal,
        sendRequest,
        hasCredit,
        listReviews,
        fetchReviews,
        hasModel,
        formValue,
        loadingBusiness,
    }
}

export default useLogic
