import { List, Stack } from "@mui/material"
import { useMemo } from "react"
import { SkeletonHeaderBtn } from "../header-btn-left-right"
import { SkeletonNumberItemStat } from "./bloc-number-stat"
import { SkeletonChart } from "./chart"
import { useStyles } from "./styles"
import { v4 as uuidv4 } from "uuid"

export const SkeletonStat = ({ type }: { type: string }) => {
    const { classes } = useStyles()
    const colors = useMemo(() => {
        if (type === "local_presence") {
            return ["#2D97D3", "#296ED7", "#2B44CB"]
        }
        if (type === "reputation") {
            return ["#FBC02C", "#F9A824", "#F57F17"]
        }
        return []
    }, [type])
    return (
        <Stack gap={2} className={classes.statistic}>
            <SkeletonHeaderBtn />
            <List className="bloc-number-stat">
                {[...Array(3)].map((val, key: number) => (
                    <SkeletonNumberItemStat key={uuidv4()} color={colors[key]} />
                ))}
            </List>
            {[...Array(3)].map((key) => (
                <SkeletonChart key={uuidv4()} />
            ))}
        </Stack>
    )
}
